import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdPeople } from "react-icons/io"
import toast from 'react-hot-toast'

import useModalStore from '../../stores/ModalStore'
import SelectAdvanced from '../../components/SelectAdvanced/SelectAdvanced'
import Spinner from '../../components/Spinner/Spinner'
import { useCoins } from '../../hooks/useCoins'
import { useCreateUserList } from '../../hooks/useUserLists'

import './CreateUserListModal.css'

interface CreateUserListPayload {
    name: string,
    description: string,
    preferred_currency: string,
    preferred_amount: string,
    user_emails: string[]
}

interface CreateUserListModalProps {
    nUsers: number;
    preferredAmount: string;
    preferredCurrency: string;
    users: string[];
}

const CreateUserListModal = ({ nUsers, preferredAmount, preferredCurrency, users }: CreateUserListModalProps) => {
    const { t } = useTranslation('common')

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }))

    const { data: coinsData } = useCoins()
    const { mutateAsync: createUserList } = useCreateUserList()

    const [isFormValid, setIsFormValid] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [formData, setFormData] = useState<CreateUserListPayload>({
        name: "",
        description: "",
        preferred_currency: preferredCurrency,
        preferred_amount: preferredAmount,
        user_emails: users,
    })

    const coinsItems = () => {
        let items: {
            [key: string]: {
                name: string,
                logo?: string,
                decimals?: number,
            }
        } = {}
    
        Object.keys(coinsData ? coinsData.coins : {}).forEach(key => {
            items[key] = {
                name: coinsData.coins[key].name,
                logo: coinsData.coins[key].logo,
                decimals: coinsData.coins[key].decimals,
            }
        })
    
        return items
    }

    const handleFormDataChange = (key: string, value: string) => {
        setFormData(prev => ({ ...prev, [key]: value }))
    }

    const handleCoinChange = (value: string) => {
        handleFormDataChange('preferred_currency', value)
    }

    const handleSubmit = async () => {
        setError("")
        setIsLoading(true)
        try {
            const result = await createUserList(formData)
            if (result.status === 201) {
                toast.success(t("payrolls_user_list_created"))
                setError("")
                setIsLoading(false)
                closeModal()
            } else {
                setError(t("payrolls_errors.error_creating_user_list"))
            }
        } catch (error) {
            setError(t("payrolls_errors.error_creating_user_list"))
            console.error("Error creating user list:", error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (formData.name && formData.preferred_amount) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formData])

    useEffect(() => {
        if (error) toast.error(error)
    }, [error])

    return (
        <div className="create-user-list-cont">
            <div className="create-user-list-title">{t('create_user_list_modal.title')}</div>
            <div className="create-user-list-main-box">
                <div className="create-user-list-input-box">
                    <div className="create-user-list-input-label">{t('create_user_list_modal.name_label')}</div>
                    <input
                        className="create-user-list-input"
                        type="text"
                        placeholder={t('create_user_list_modal.name_placeholder')}
                        value={formData.name}
                        onChange={(e) => handleFormDataChange('name', e.target.value)}
                    />
                </div>
                <div className="create-user-list-input-box">
                    <div className="create-user-list-input-label">{t('create_user_list_modal.description_label')}</div>
                    <input
                        className="create-user-list-input"
                        type="text"
                        placeholder={t('create_user_list_modal.description_placeholder')}
                        value={formData.description}
                        onChange={(e) => handleFormDataChange('description', e.target.value)}
                    />
                </div>
                <div className="create-user-list-input-box">
                    <div className="create-user-list-input-label">{t('create_user_list_modal.preferred_amount_label')}</div>
                    <div className="create-user-list-amount-input-box">
                        <input
                            className="create-user-list-input"
                            type="text"
                            placeholder={t('create_user_list_modal.preferred_amount_placeholder')}
                            value={formData.preferred_amount}
                            onChange={(e) => handleFormDataChange('preferred_amount', e.target.value)}
                        />
                        <SelectAdvanced
                            value={formData.preferred_currency}
                            items={coinsItems()}
                            image={true}
                            onChange={handleCoinChange}
                        />
                    </div>
                </div>
            </div>
            <div className="create-user-list-n-users">
                <IoMdPeople />  
                <span>{nUsers}</span>   
                <span>{t('create_user_list_modal.num_users')}</span>
            </div>

            {isLoading ? (
                <div className="create-user-list-spinner">
                    <Spinner/>
                </div>
            ) : (
                <div className="create-user-list-input-buttons">
                <button 
                    className={`create-user-list-button ${isFormValid ? '' : 'create-user-list-button-disabled'}` }
                    onClick={handleSubmit}
                >
                    {t('create_user_list_modal.submit')}
                </button>
                <button 
                    className="create-user-list-button" 
                    onClick={closeModal}
                >
                    {t('create_user_list_modal.cancel')}
                    </button>
            </div>
            )}
        </div>
    )
}

export default CreateUserListModal