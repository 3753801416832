import { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import InputAdvanced from "../Input/InputAdvanced";
import InputTextAdvanced from "../InputText/InputTextAdvanced";
import { usePostSupportTickets } from "../../hooks/useTickets";
import UploadFiles from "../UploadFiles/UploadFiles";
import useModalStore from "../../stores/ModalStore"
import GenericModal from "../../modals/GenericModal/GenericModal"
import "./SupportTicketCreate.css"
import Spinner from "../Spinner/Spinner";

interface TicketProps {
    subject: string,
    description: string,
    files: File[],
}

const CreateSupportTicket = () => {
    const { t } = useTranslation('common')
    const { mutateAsync: submitSupportTicket, isPending } = usePostSupportTickets();
    const [formData, setFormData] = useState<TicketProps>({ subject: '', description: '', files: [] });
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [ key, setKey] = useState(0)

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
            // closeModal: state.closeModal,
        }
    })

    const handleFilesChange = (files: File[]) => {
        setUploadedFiles(files);
      };
    
    const handleInputChange = (field: { [key: string]: any }, value: string) => {
        setFormData(prevData => ({
            ...prevData,
            [field.name]: value
        }));
        return value;
    };

    const handleSubmitTicket = async () => {

        try { 
            let formDataToSend = new FormData();
            formDataToSend.append("subject", formData.subject);
            formDataToSend.append("description", formData.description);
            uploadedFiles.forEach((file) => {
                formDataToSend.append("files", file); 
            });
            await submitSupportTicket(formDataToSend);
            // toast.success("support_ticket_creation_success")
            setModalContent(
                <GenericModal 
                    data={{
                        title: t('support_ticket_created_modal.title'),
                        info: t('support_ticket_created_modal.info'),
                        action: t('close')
                    }} 
                />
            )
            setKey(prevKey => prevKey + 1);
        } catch (error: any){
            console.error("Error al hacer la solicitud a la API:", error);
            toast.error("support_ticket_creation_error")
        }
    }

    return (
        <div className="create-support-ticket-form">
            <div>
                <InputAdvanced key={key} field={{name: "subject", description: "" }} onInputChange={handleInputChange}/>
            </div>
            <div>
                <InputTextAdvanced key={key} field={{name: "description", description: "description", height: "150px", label: true}} onInputChange={handleInputChange}/>
            </div>
            <UploadFiles key={key} onFilesChange={handleFilesChange}/>
            <div>
                <button disabled={isPending} className="create-support-ticket-button" type="button" onClick={handleSubmitTicket}>
                        { isPending ? <Spinner sizeMultiplier={0.3}/> : t("sent")}
                        {/* <Spinner sizeMultiplier={0.3}/> */}
                </button>
            </div>

        </div>
    )
}

export default CreateSupportTicket
