import { useState } from "react";
import { useTranslation } from "react-i18next";
import SupportTicketCreate from "../../components/SupportTicketCreate/SupportTicketCreate";
import SupportTicketHistory from "../../components/SupportTicketsHistory/SupportTicketHistory";
import SupportFAQ from "../../components/SupportFAQ/SupportFAQ";
import { IoReturnUpBack } from "react-icons/io5";
import "./Support.css"

const Support = () => {
    const { t } = useTranslation('common')
    const [ formOpen, setFormOpen ] = useState(false)
    
    return (
        <div className="support-main-cont">
            <div className="support-section">
                <div className="support-title">
                    <h1>{t("faq")}</h1>
                </div>
                <div className="support-content">
                    {/* <SupportTicketCreate /> */}
                    <SupportFAQ />
                </div>
            </div>
            <div className="support-section">
                <div className="support-title">
                    <h1>{t("support")}</h1>
                </div>
                { !formOpen ? 
                    <div className="support-content">
                        <div className="support-content-open-ticket-form" onClick={ () => setFormOpen(true) }>
                            {t("need_help")}
                        </div> 
                        <SupportTicketHistory />

                    </div>
                    : 
                    <div className="support-content">
                        <div className="support-content-create-ticket-header">
                            <div className="support-ticket-create-close" onClick={() => setFormOpen(false)}>
                                <IoReturnUpBack color="#8ffdfc" size={32}/>
                            </div>
                            <div className="support-ticket-create-title">
                                {t("create_support_ticket")}
                            </div>
                        </div>
                        <SupportTicketCreate /> 
                    </div>
                }
            </div>
        </div>
    )
}

export default Support
