import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSupportTickets } from "../../hooks/useTickets"
import SupportTicketConversation from "../../components/SupportTicketConversation/SupportTicketConversation";
import { prettyDatetime } from "../../utils/functions";
import Skeleton from "../Skeleton/Skeleton";

import "./SupportTicketHistory.css"

interface Ticket {
    id: string,
    subject: string,
    description: string,
    status: string,
    created_at: string,
    updated_at: string,
}

const SupportTicketHistory = () => {
    const { t } = useTranslation('common');
    const { data: supportTickets, isLoading } = useSupportTickets()
    const [currentConversation, setCurrentConversation] = useState<Ticket>()

    const handleConversationChange = (ticket : Ticket) => {
        setCurrentConversation(ticket)
    } 

    const closeConversation = () =>{
        setCurrentConversation(undefined)
    }

    if (currentConversation)
        return <SupportTicketConversation  ticket={currentConversation} closeConversation={closeConversation}/>


    return (
    <div className="support-tickets-section">
        {/* TODO PAGINATION */}
        <div className="tickets-list">
            {/* {supportTickets?.length > 0 ? ( */}
            { isLoading ? 
                    Array.from({ length: 8 }).map((_, index) => (
                    <div key={index} className="movement-card skeleton-card placeholder">
                        <div className="ticket-info-loading">
                            <Skeleton className='ticket-name' minHeight={'16px'} maxWidth={'70%'} animated={isLoading} />
                            <Skeleton className='ticket-date' minHeight={'16px'} maxWidth={'70%'} animated={isLoading} />
                        </div>
                        <Skeleton className='ticket-details' minHeight={'16px'} maxWidth={'70%'} animated={isLoading} />
                        <Skeleton className='ticket-status' minHeight={'16px'} maxWidth={'70%'} animated={isLoading} />
                        <Skeleton className='ticket-update' minHeight={'16px'} maxWidth={'70%'} animated={isLoading} />
                    </div> 
                    ))
                :
                supportTickets.map((item: any) => (
                    <div
                        key={item.id}
                        className="ticket-card"
                        onClick={() => handleConversationChange(item)}
                    >
                        <div className="ticket-info">
                            <div className="ticket-name">#{item.id}</div>
                            <div className="ticket-date">
                                {new Date(item.created_at).getDate()} {t(`months.${new Date(item.created_at).getMonth() + 1}`)} {new Date(item.created_at).getFullYear() === new Date().getFullYear() ? '' : new Date(item.created_at).getFullYear()}
                            </div>
                        </div>
                        <div className="ticket-details">
                            <div className="ticket-subject">
                                {item.subject}
                            </div>
                        </div>
                        <div className={`ticket-status status-${item.status}`}>
                            {t(`ticket_${item.status}`)}
                        </div>
                        <div className="ticket-update">
                            { prettyDatetime(item.updated_at) }
                        </div>
                    </div>
                ))
            }
        </div>
    </div>

    )
}

export default SupportTicketHistory
