import axiosInstance from "../api/axiosInstance";
import { useQuery } from "@tanstack/react-query";

interface WalletHistoryParams {
    limit?: number;
    offset?: number;
    ordering?: string;
    operation_type?: number;  
    currency?: string;        
  }

  const fetchHistory = (params: WalletHistoryParams) => {
    return axiosInstance.get('/wallet-history/', { params });
  };
  

export const useHistory = (params: WalletHistoryParams = { limit: 10, offset: 0 }) => {
  return useQuery({
    queryKey: ['walletHistory', params],
    queryFn: () =>
      fetchHistory(params).then((res) => ({
        ...res.data,
        results: res.data?.results && res.data.results.map((item: any) => ({
          ...item,
          display: true
        }))
      })),
    refetchInterval: 5000,
  });
};
