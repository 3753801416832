import { useState } from "react"
import coinlogos from "../../utils/coinlogos"
import { useParams, useNavigate } from "react-router-dom"
import { FaChevronDown } from "react-icons/fa6"
import { useDetectClickOutside } from 'react-detect-click-outside'
import "./CoinSelector.css"

interface CoinSelectorProps {
    order: number,
    selectedCoin: string,
    filteredCoins: string[]
}

const CoinSelector = ({ order, filteredCoins, selectedCoin }: CoinSelectorProps) => {

    const { coin1, coin2 } = useParams()

    const nav = useNavigate()
    
    const [dropdownOpen, setDropdownOpen] = useState(false)
    
    const handleToggleSelector = () => {
        if(filteredCoins.length > 0) {
            setDropdownOpen((prev) => !prev)
        }
    }
    
    const closeDropdown = () => {
        setDropdownOpen(false)
    }

    const handleChangeCoin = (coin: string) => {
        closeDropdown()
        if(coin === coin2) {
            nav(`/converter/${coin}/${coin1}`)
        }
        else if(coin === coin1) {
            nav(`/converter/${coin2}/${coin}`)
        }
        else {
            if(order === 1) {
                nav(`/converter/${coin}/${coin2}`)
            }
            else {
                nav(`/converter/${coin1}/${coin}`)

            }
        }
    }

    const dropdownref = useDetectClickOutside({ onTriggered: closeDropdown })



    const coinsDropdownElems = filteredCoins.length > 0 ? filteredCoins.map((coin, i) => {
        return (
            <div 
                key={coin} 
                onClick={() => {handleChangeCoin(coin)}} 
                className={`coin-selector-dropdown-item ${filteredCoins.length - 1 === i ? "" : "coin-selector-dropdown-item-last"}`}
            >
                <img className="coin-selector-logo" src={coinlogos[coin as keyof typeof coinlogos]}/>
                {coin}
            </div>
        )
    }) : []
    return (
        <div 
            ref={dropdownref} 
            className={`coin-selector-cont ${dropdownOpen ? "coin-selector-cont-open" : ""}`}
            onClick={handleToggleSelector}
        >
            <img className="coin-selector-logo" src={coinlogos[selectedCoin as keyof typeof coinlogos]}/>
            <span>{selectedCoin}</span>
            {filteredCoins.length > 0 && <FaChevronDown className="coin-selector-chevron" />}
            {dropdownOpen && <div onClick={(e) => e.stopPropagation()} className="coin-selector-dropdown-cont">
                {coinsDropdownElems}
            </div>}
        </div>
    )

}

export default CoinSelector