import { useTranslation } from "react-i18next";
import { HiCheckCircle, HiClock, HiMinusCircle } from "react-icons/hi";
import { useDeleteCardRequest } from "../../hooks/useCards";
import GenericModal from "../../modals/GenericModal/GenericModal";
import useModalStore from "../../stores/ModalStore";
import localConfig from "../../local_config";
import "./CardRequest.css";

type StatusType = "created" | "approved" | "delivered" | "completed" | "declined" | "canceled";
interface CardRequestProps {
    cardRequest: {
        id: string,
        user_address: {
            street: string;
            city: string;
            country: string;
        };
        card_type: string;
        type: string;
        status: StatusType;
        created: string;
    };
}

const CardRequest = ({ cardRequest }: CardRequestProps) => {

    const { t } = useTranslation("common");
    const { mutateAsync: deleteCardRequest } = useDeleteCardRequest();

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        }
    });

    const iconsStatus = {
        'created': <HiClock />,
        'approved': <HiCheckCircle />,
        'delivered': <HiCheckCircle />,
        'completed': <HiCheckCircle />,
        'declined': <HiMinusCircle />,
        'canceled': <HiMinusCircle />,
    }

    const handleDeleteCardRequest = async () => {
        try {
            await deleteCardRequest(cardRequest.id);
        } catch (e) {
            console.log(e);
        }
    }
   
    return (
        <div className="card-request-main-cont">
            <div className="card-request-row card-request-row-wrap">
                <div className="card-request-title-cont">
                    <div className="card-request-title">{t("card_request_form.request_sent")}</div>
                    <div className="card-request-subtitle">{t("card_request_form.request_sent_text")}</div>
                </div>
                <div className={`card-request-status card-request-status-${cardRequest.status}`}><span className="card-request-icon">{iconsStatus[cardRequest.status]}</span> <span className="card-request-icon-text">{t(`card_request_form.${cardRequest.status}`)}</span></div>
            </div>
            <div className="card-request-row">
                <div className="card-request-type-cont">
                    <div className="card-request-type">{cardRequest.card_type === "virtual"  ? t('card_request_form.virtual_card') : t(`card_request_form.${cardRequest.type}`)}</div>
                    {
                    cardRequest.card_type === "virtual" ? 
                        <div className="card-request-address">{t("card_request_form.virtual_card_description")}</div>
                    :
                    (cardRequest.type === "delivery" ? 
                        <div className="card-request-address">{cardRequest.user_address.street}, {cardRequest.user_address.city}, {cardRequest.user_address.country}</div>
                        :
                        // TODO change this so that it displays colombia address
                        <div className="card-request-address">{localConfig.countryCode === "PE" ? t("nemo_office_address") : t("card_request_form.disclaimer")}</div>
                    )}
                </div>
                <div className="card-request-date">{cardRequest.created}</div>
            </div>
            <div className="card-request-cancel-button" onClick={() => 
                setModalContent(
                    <GenericModal
                        data={{
                            title: t('card_request_cancel.title'), 
                            description: t('card_request_cancel.description'), 
                            action: t('card_request_cancel.action')
                        }} 
                        onConfirm={handleDeleteCardRequest}
                    />
                )}
            >{t("card_request_form.cancel_request")}</div>
        </div>
    );
};

export default CardRequest;
