// useKycAccessToken.ts
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../api/axiosInstance";

// Define the expected response type
interface AccessTokenResponse {
  token: string;
}

// Define error type from axios
interface AxiosError extends Error {
  response?: {
    data?: {
      message?: string;
    };
  };
}

const fetchAccessToken = async (): Promise<string> => {
  const response = await axiosInstance.post<AccessTokenResponse>("kyc_get_access_token/");
  return response.data.token;
};

export const useKycAccessToken = (): UseQueryResult<string, AxiosError> => {
  return useQuery({
    queryKey: ['kyc-access-token'],
    queryFn: fetchAccessToken,
    enabled: false,
    retry: false,
    staleTime: 0,
  });
};