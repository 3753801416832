import { useState, useEffect } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import Spinner from "../Spinner/Spinner";
import "./HiddenValueToggle.css";

interface HiddenValueToggleProps {
    value: string;
    label: string;
    isLoading?: boolean;
    disabled?: boolean;
}

const HiddenValueToggle = ({ value, label, isLoading = false, disabled = false }: HiddenValueToggleProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [displayValue, setDisplayValue] = useState("");

    const handleToggleVisibility = () => {
        if (disabled) return
        setIsVisible((prev) => !prev)
    }

    useEffect(() => {
        // To never show the value when changed
        setIsVisible(false)
        setDisplayValue(value)
    }, [value])

    return (
        <div className={`hidden-value-cont ${disabled ? "hidden-disabled" : ""}`} onClick={handleToggleVisibility} >
            {isVisible ? (
            <>
                <LuEyeOff className="toggle-icon" />
                <span className="hidden-label">{isLoading ? <Spinner /> : displayValue}</span>
            </>
            ) : (
            <>
                <LuEye className="toggle-icon" />
                <span className={`hidden-label ${disabled ? "hidden-label-disabled" : ""}`}>{label}</span>
            </>
            )}
        </div>
    );
};

export default HiddenValueToggle;
