import { useState } from "react";
import { FaRegCopy, FaCheck } from "react-icons/fa6";
import { useTranslation } from "react-i18next"
import toast from "react-hot-toast";
import "./ClickToCopy.css";

interface ClickToCopyProps {
    value: string;
    label?: string;
}

const ClickToCopy = ({ value, label }: ClickToCopyProps) => {
    const { t } = useTranslation('common')
    
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = async (text: string): Promise<boolean> => {

        // Try using the modern Clipboard API
        if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
            try {
                await navigator.clipboard.writeText(text);
                return true;
            } catch (err) {
                console.error("Navigator clipboard failed, falling back to execCommand.", err);
            }
        }

        // Fallback to using a temporary textarea and document.execCommand
        try {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            const successful = document.execCommand("copy");
            document.body.removeChild(textArea);
            return successful;
        } catch (err) {
            console.error("Fallback copy method failed.", err);
            return false;
        }
    };

  const handleCopyValue = async () => {
        const success = await copyToClipboard(value);
        if (success) {
            setIsCopied(true);
            toast.success(t("copied_to_clipboard"));
            setTimeout(() => {
                setIsCopied(false);
            }, 4000);
        } else {
            toast.error(t("error_copy_clipboard"));
        }
    };

    return (
        <div className="copy-value-cont" onClick={handleCopyValue} >
            {isCopied ? (
                <FaCheck className="copy-icon-copied" />
            ) : (
                <FaRegCopy className="copy-icon" />
            )}
            <span className="copy-label">{label || value}</span>
        </div>
    );
};

export default ClickToCopy;
