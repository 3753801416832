import axiosInstance from "../api/axiosInstance"
import { useMutation } from "@tanstack/react-query"

// Checking single users is necessary for P2P
// Checking many users is necessary for mass transfers, hence not all users are allowed to use it

interface CheckManyUsersPayload {
    emails: string[]
}

interface CheckManyUsersResponse {
    results: {
        is_valid: boolean;
        email: string;
    }[]
}

const postCheckSingleUser = async (username: string) => {
    return axiosInstance.post("/check_username/", {"username": username})
}

const postCheckManyUsers = async (userList: CheckManyUsersPayload): Promise<CheckManyUsersResponse> => {
    const { data } = await axiosInstance.post("/check_emails/", userList)
    return data
};

export const useCheckSingleUser = () => {
    return useMutation({
        mutationFn: (username: string) => postCheckSingleUser(username),
    })
}

export const useCheckManyUsers = () => {
    return useMutation({
        mutationFn: (payload: CheckManyUsersPayload) => postCheckManyUsers(payload),
    })
}