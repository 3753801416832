import { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { useCheckOtp, useSecret, useSet2Fa, useDisable2Fa } from "../../hooks/use2Fa";
import useModalStore from '../../stores/ModalStore';
import EnableTwoFaModal from '../../modals/EnableTwoFaModal/EnableTwoFaModal';
import { useProfile } from '../../hooks/useProfile';
import { useTranslation } from 'react-i18next';
import qrImg from "../../assets/logos/logo.svg";
import "./TwoFa.css";


const TwoFa = () => {
    
    const { t } = useTranslation('common')

    const handleCheckOtpSuccess = (payload: any) => {
        if(payload?.data?.Status) {
            setOtp("")
            return
        }
        setOtp("")
        toast.error(t('otp_failed'))
    }

    const handleCheckOtpError = (error: any) => {
        console.log(error)
        toast.error(t('error'))
    }

    const handleSet2FaSuccess = (payload: any) => {
        if(payload?.data["2fa_enabled"]) {
            resetSecret()
            setOtp("")
            setTfaNexted(false)
            toast.success(t('tfa_setup_successful'))
            return
        }
        toast.error(t('error'))
    }

    const handleSet2FaError = (error: any) => {
        console.log(error)
        setOtp("")
        toast.error(t('error'))
    }

    const handleDisable2FaSuccess = (payload: any) => {
        if(!payload?.data["2fa_enabled"]) {
            setDisable2Fa(false)
            setOtp("")
            toast.success(t('tfa_disable_successful'))
            return
        }
        toast.error(t('error'))
    }

    const handleDisable2FaError = (error: any) => {
        console.log(error)
        setOtp("")
        toast.error(t('otp_failed'))
    }

    const { data: secret, mutate: handleGetSecret, reset: resetSecret, isPending: isSecretPending } = useSecret()
    const { data: otpSuccess, mutate: handleSubmitOtp, isPending: isOtpCheckPending } = useCheckOtp(handleCheckOtpSuccess, handleCheckOtpError)
    const { mutate: handleSet2Fa, isPending: isSet2FaPending } = useSet2Fa(handleSet2FaSuccess, handleSet2FaError)
    const { mutate: handleDisable2Fa, isPending: isDisable2FaPending } = useDisable2Fa(handleDisable2FaSuccess, handleDisable2FaError)
    const { data: profileData } = useProfile()

    const [tfaNexted, setTfaNexted] = useState(false)
    const [disable2Fa, setDisable2Fa] = useState(false)
    const [otp, setOtp] = useState("")

    useEffect(() => {
        if(otpSuccess?.data.Status && secret) {
            handleSet2Fa({secretcode: secret?.data})
            
        }
    }, [otpSuccess])

    useEffect(() => {
        if (secret?.data && !tfaNexted) {
            setModalContent(
                <EnableTwoFaModal 
                    profileData={profileData} 
                    secret={secret} 
                    qrImg={qrImg} 
                    nextTfa={nextTfa} 
                    // This will be called when overlay click
                    handleClose={handleTwoFaModalClose} 
                />
        )
        }
    }, [secret, tfaNexted])

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        }
    });

    const handleTwoFaModalClose = () => {
        resetSecret()
    }
    
    const nextTfa = () => {
        setTfaNexted(true)
    }

    const backTfa = () => {
        setTfaNexted(false)
    }

    const handleSubmitTwoFaForm = (e: any) => {
        e.preventDefault(); 
        disable2Fa ? handleDisable2Fa({secretcode: otp}) : handleSubmitOtp({secret: secret?.data, code: otp})
    }

    return (
        <div className='tfa-main-cont'>
            {(!disable2Fa && !tfaNexted) && 
            <div className='tfa-no-process-cont'>
                <span>{profileData?.is_2fa_on ? t('two_fa_on') : t('two_fa_off')}</span>
                {profileData?.is_2fa_on ? 
                    <button onClick={() => {setDisable2Fa(true)}} className='tfa-option-button'>
                        {t('disable')}
                    </button> 
                    : 
                    <button className='tfa-option-button' disabled={isSecretPending || secret?.data} onClick={() => {handleGetSecret()}}>
                        {t('enable')
                    }</button>
                }
            </div>}
            {((secret?.data && tfaNexted) || disable2Fa) && 
            <form 
                onSubmit={(e) => handleSubmitTwoFaForm(e)} 
                className='tfa-enable-main-cont'
            >
                <span className='tfa-opt-instructions' >{t('put_authenticator_otp')}</span>
                <div className="tfa-enable-cont">
                    <input required className='tfa-opt-input' placeholder={t('tfa_otp_input_placeholder')} value={otp} onChange={(e) => {setOtp(e.target.value)}}/>
                    <div className='tfa-button-options-cont'>
                        <button type='button' className='tfa-option-button tfa-cancel-button' onClick={() => { disable2Fa ? setDisable2Fa(false) : backTfa() }}>{t('back')}</button>
                        <button type='submit' className='tfa-option-button' disabled={isOtpCheckPending || isSet2FaPending || isDisable2FaPending} >{t('submit')}</button>
                    </div>
                </div>
            </form>}

        </div>
    )
}

export default TwoFa