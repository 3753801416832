import localConfig from "../../local_config"
import qrImg from "../../assets/logos/logo.svg"
import { useTranslation } from "react-i18next"
import { QRCode } from 'react-qrcode-logo'
import ClickToCopy from "../ClickToCopy/ClickToCopy"

import "./ReferralCode.css"

const ReferralCode = ( {referralCode} : { referralCode : string} ) => {

    const { t } = useTranslation('common')

    const registerLink = localConfig.domain + "/register" + "?referralCode=" + referralCode
    return (
        <div className="referral-code-main-section">
                <div className="referral-code-title-section">
                    {t("referral_section.title")}
                </div>
                <div className="referral-code-description-section">
                    {t("referral_section.description")}
                </div>

            <div className="referral-code-qr-section">
                <QRCode 
                    value={registerLink}
                    size={200}
                    // NOTE This colors are hardcoded
                    bgColor="#0F131A"
                    fgColor="#8FFDFC"
                    logoImage={qrImg}
                    logoWidth={70} 
                    logoPadding={5}
                    logoPaddingStyle="circle"
                    removeQrCodeBehindLogo={true}
                    ecLevel="Q"
                />
            </div>
            <ClickToCopy value={registerLink} label={t("referral_section.copy_code")}/>
        </div>
    )
}
export default ReferralCode
