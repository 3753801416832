import { useTranslation } from "react-i18next"
import NemoCard from "../../components/NemoCard/NemoCard"
import NemoCardLanding from "../../components/NemoCardLanding/NemoCardLanding";
import CardRequest from "../../components/CardRequest/CardRequest";
import { CardStatuses } from "../../utils/cards";
import InOutHistory from "../../components/InOutHistory/InOutHistory";
import { useCards, useCardRequest } from "../../hooks/useCards"
import "./Cards.css";
import Spinner from "../../components/Spinner/Spinner";

// NOTE redirect if cards are disabled
export const CARDS_ENABLED = true;
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Cards = () => {

    // NOTE redirect if cards are disabled
    const nav = useNavigate();
    useEffect(() => {
        if (!CARDS_ENABLED) {
            nav("/");
        }
    });

    const { t } = useTranslation('common');
    
    const { data: cardData, isLoading: isLoadingCardData } = useCards();
    const { data: cardRequest, isLoading: isLoadingCardRequest } = useCardRequest();

    return (
            <div className="cards-main-cont">
                <div className="cards-cards-cont">
                    <div className="cards-title">
                        <h1>{t("cards_header")}</h1>
                    </div>
                    <div className="cards-box">
                        <div className="cards-list">
                        {
                            cardData?.length > 0 ? (
                                // NOTE we are always assuming cardData[0] is the current card
                                <>
                                    <div className="nemo-card-border">
                                        <NemoCard card={cardData[0]} />
                                    </div>
                                    {(cardData[0]?.status === CardStatuses.BLOCKED || cardData[0]?.status === CardStatuses.ASSIGNED) && 
                                    <div>
                                        {cardRequest ? 
                                        <CardRequest cardRequest={cardRequest} />
                                        : !isLoadingCardRequest ?
                                        <NemoCardLanding status={cardData[0]?.status} />
                                        : <Spinner />}
                                    </div>}
                                </>
                            ) : !isLoadingCardData ? (
                                <div>
                                    {cardRequest ? 
                                    <CardRequest cardRequest={cardRequest} />
                                    : !isLoadingCardRequest ?
                                    <NemoCardLanding />
                                    : <Spinner />}
                                </div>
                            ) : <Spinner />
                        }
                        </div>
                    </div>
                </div>
                <div className="transaction-history">
                    <InOutHistory filterDefault='card' />
                </div>
            </div>
    )       
}

export default Cards