import Spinner from "../Spinner/Spinner";
import "./CardStepper.css"
import { useTranslation } from 'react-i18next';

const CardStepper = () => {
const { t } = useTranslation('common');

    return (
        <div className="cardstepper-main-cont">
            <div className="cardstepper-tittle">
                <div className="card-stepper">
                    <div className="card-stepper-stepper">
                        <div className="card-stepper-step-completed"></div>
                        <div className="card-stepper-step-completed"></div>
                        <div className="card-stepper-step-completed"></div>
                        <div className="card-step-active"></div>
                    </div>
                    <h2 className="card-stepper-title">{t("validating_kyc_data_title")}</h2>
                    <p className="card-stepper-message">{t("validating_kyc_data_text")}</p>
                    <Spinner />
                </div>
            </div>
        </div>

    )
}

export default CardStepper