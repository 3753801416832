import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Scanner } from "@yudiel/react-qr-scanner";
import useModalStore from "../../stores/ModalStore";
import "./QrCodeScannerModal.css";

interface QrCodeScannerModalProps {
    setDecodedResult: (result: string) => void;
    handleClose: () => void;
}

const QrCodeScannerModal = ({ setDecodedResult, handleClose }: QrCodeScannerModalProps) => {

    // Set decodedResult to be the exact string obtained from the QR

    const { t } = useTranslation("common")

    const [error, setError] = useState("")

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }))

    const handleCloseModal = () => {
        closeModal()
        handleClose()
    }

    useEffect(() => {
        setDecodedResult("")
    }, [setDecodedResult])


    const handleDecode = (decodedText: any) => {
        const decodedTextString = decodedText?.length > 0 ? decodedText[0].rawValue : null
        if (decodedTextString) {
            setDecodedResult(decodedTextString)
            handleCloseModal()
        }
    }

    const handleScannerError = (err: unknown) => {
        if (err) {
            console.error("Scan error:", err)
            setError(t("scan_qr_modal.error_scanner"))
        }
    }

    return (
        <div className="qr-code-scanner-modal-cont">
            <div className="qr-code-scanner-title">{t("scan_qr_modal.title")}</div>
            {error && <div className="qr-code-scanner-error">{error}</div>}
            <Scanner
                onScan={handleDecode}
                onError={handleScannerError}
                classNames={{ video: "qr-camera-video", container: "qr-camera-container" }}
                styles={{ container: { width: "100%",  height: "100%",  aspectRatio: 1 }, video: { width: "100%", height: "100%" } }}
                constraints={{ facingMode: "environment" }}
                scanDelay={300}
            />
            <div onClick={handleCloseModal} className="qr-code-scanner-button">
                {t("scan_qr_modal.close")}
            </div>
        </div>
    );
};

export default QrCodeScannerModal;
