import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useModalStore from "../../stores/ModalStore";
import confetti from "canvas-confetti";
import { numberWithCommas } from "../../utils/functions";
import "./PeerToPeerSuccessModal.css";

interface PeerToPeerSuccessModalProps {
    id: string,
    to_user: { first_name: string, last_name: string, username: string },
    date: string,
    currency: string,
    amount: string,
    decimals?: number
}

const PeerToPeerSuccessModal = ({ id, to_user, date, currency, amount, decimals = 2 }: PeerToPeerSuccessModalProps) => {

    const { t } = useTranslation("common")

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }))

    useEffect(() => {
        confetti({
            particleCount: 100,
            gravity: 1.8,
            spread: 160,
            // NOTE This colors are hardcoded as the logo colors
            colors: ["#8FFDFC", "#1DBCC4", "#023356"],
            origin: { x: 0.5, y: 0.6 },
        })
    }, [])
     
    return (
        <div className="peertopeer-modal-container">
            <div className="peertopeer-modal-content">
                <h3 className="peertopeer-modal-title">{t("peertopeer_modal.title")}</h3>

                <div className="peertopeer-modal-amount-container">
                    <div className="peertopeer-modal-amount">{numberWithCommas(parseFloat(amount), decimals)}</div> 
                    <div className="peertopeer-modal-currency">{currency}</div>
                </div>

                <div className="peertopeer-modal-name-container">
                    <div className="peertopeer-modal-name">{to_user?.first_name} {to_user?.last_name}</div>
                    <div className="peertopeer-modal-date">{new Date(date).getDate()} {t(`months.${new Date(date).getMonth() + 1}`)} {new Date(date).getFullYear()} {new Date(date).getHours()}:{new Date(date).getMinutes()}:{new Date(date).getSeconds()} </div>
                </div>

                <div>
                    <div className="peertopeer-modal-username">{t("peertopeer_modal.user")}: {to_user?.username}</div>
                    <div className="peertopeer-modal-id">{t("peertopeer_modal.id")}: {id}</div>
                </div>

                <button
                    type="button"
                    className="peertopeer-modal-button"
                    onClick={closeModal}
                >
                    {t("peertopeer_modal.close")}
                </button>
            </div>
        </div>
    );
};

export default PeerToPeerSuccessModal;
