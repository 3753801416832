import colombiacircle from "../assets/flags/colombiacircle.svg"
import usacircle from "../assets/flags/usacircle.svg"
import appstorees from "../assets/badges/appstorewhitees.svg"
import appstoreen from "../assets/badges/appstorewhiteen.svg"
import googleplayes from "../assets/badges/gplayes.png"
import googleplayen from "../assets/badges/gplayen.png"
import localConfig from "../local_config"

const common = {
    es: {
        rates_are_referential: "*Las tarifas mostradas son solo referenciales",
        input_number_to_see_rates:"Ingresa un monto para ver la tasa de cambio",
        asset: "Activo",
        validating_kyc_data_title: "Estamos validando tu información",
        validating_kyc_data_text: `En unos momentos podrás entrar al mundo ${localConfig.brand}`,
        not_available: "No disponible",
        login_or_register_to_enter_raffle: "Ingresa o crea una cuenta para participar",
        success_registration_raffle: "Ya estas participando!",
        we_are: "Estamos en",
        maintenance: "mantenimiento",
        icon: colombiacircle,
        apple_download_badge: appstorees,
        google_download_badge: googleplayes,
        continue: "Continuar",
        description: "Descripción",
        login: "Iniciar sesión",
        logout: "Cerrar sesión",
        email: "Correo electrónico",
        password: "Contraseña",
        email_placeholder: "Ingrese su correo electrónico",
        password_placeholder: "Ingrese su contraseña",
        dont_have_account: "¿No tienes una cuenta?",
        forgot_password: "¿Olvidaste tu contraseña?",
        invalid_login: "Credenciales inválidas",
        reset_psw: "Debido a la actualización, es necesario restablecer su contraseña",
        confirm_password: "Confirmar contraseña",
        confirm_password_placeholder:"Ingrese su contraseña nuevamente",
        register: "Crear cuenta",
        register_success: "Creación de cuenta exitosa",
        register_fail: "Creación de cuenta fallida",
        already_have_account: "¿Ya tienes una cuenta?",
        password_mismatch: "Las contraseñas no coinciden",
        password_too_short: "Al menos 8 caracteres",
        password_needs_uppercase: "Al menos una letra mayúscula",
        password_needs_lowercase: "Al menos una letra minúscula",
        password_needs_number: "Al menos un número",
        password_needs_special: "Al menos un carácter especial",
        hinting_regex_error: "La contraseña no cumple con los requerimientos mínimos",
        complete_the_captcha: "Complete el CAPTCHA",
        register_failed: "Creación de cuenta fallida, intente nuevamente",
        balance_navbar: "Balance total",
        terms_text1: "He leído y estoy de acuerdo con los",
        terms_text2: "de Nemo Latam.",
        terms_and_conditions: "Términos y Condiciones",
        privacy_policy: "Política de Privacidad",
        data_processing_policy: "Política de Tratamiento de Datos",
        declaration_text1: "He leído y confirmo la",
        goods_declaration: "Declaración Jurada de Origen de Fondos.",
        authorization_text1: "He leído y confirmo la",
        data_authorization: "Autorización para el tratamiento",
        authorization_text2: "de mis datos personales.",
        confirm_email: "Confirmar correo",
        email_code: "Código de confirmación",
        email_code_text: "Se te ha enviado un correo electrónico con un código, por favor ingréselo a continuación.",
        confirm: "Confirmar",
        cancel: "Cancelar",
        code_placeholder: "Ingrese su código de confirmación",
        resent_code: "Código reenviado",
        email_confirmed: "Correo confirmado exitosamente",
        verify_email: "Verificar correo",
        back_to_login: "Volver a inicio de sesión",
        resend_code: "Reenviar codigo",
        in: "en",
        name: "Nombre",
        seconds: "segundos",
        click_to: "Clic para",
        click_here: "Clic aquí",
        incorrect_code: "Código erroneo",
        reset_password: "Restablecer contraseña",
        email_sent: "Correo enviado",
        error: "Error",
        unexpected_error: "Ocurrió un error inesperado",
        invalid_confirmation_code: "Código de confirmación inválido",
        captcha_failed: "Captcha fallido",
        reset_continue_text: "Te hemos enviado un correo con instrucciones sobre cómo proceder con el reinicio de tu contraseña.",
        password_reset_success: "Contraseña actualizada existosamente",
        new_password: "Contraseña nueva",
        confirm_new_password: "Confirmar contraseña nueva",
        new_password_placeholder: "Ingrese su nueva contraseña",
        new_confirm_password_placeholder: "Confirme su contraseña nueva",
        reset_code_expired: "Enlace de reinicio expirado, solicitelo nuevamente",
        reset_password_request: "Restablece tu contraseña",
        reset_password_request_button: "Solicitar reinicio de contraseña",
        password_reset_success_text: "Reinicio de contraseña exitoso. Ahora puedes iniciar sesión normalmente.",
        wallet: "Activos",
        converter: "Convertir",
        deposit: "Depositar",
        peertopeer: "Peer2peer",
        payrolls: "Transferencias",
        withdrawal: "Retirar",
        deposit_noun: "Depósito",
        unknown: "Desconocido",
        withdrawal_noun: "Retiro",
        cards: "Tarjetas",
        profile: "Perfil",
        modal_deposit: {
            title: "Solicitud de depósito",
            info: "Has creado una solicitud de deposito, por favor sigue los pasos en la nueva pestaña para terminar este proceso",
            description: "En caso no se abrió ninguna, haga click en el enlace de abajo",
            link: "Click aquí",
            button: "Ir a billetera"  
        },
        modal_withdrawal: {
            title: "Solicitud de Retiro",
            info: "Has creado una solicitud de retiro, por favor sigue los pasos en la nueva pestaña para completar este proceso.",
            info_card: "Hemos registrado tu solicitud de retiro. Ten en cuenta que la confirmación puede tardar hasta 24 horas.",
            info_crypto: "Has creado una solicitud de retiro. Por favor, espera hasta la confirmación para ver tu saldo actualizado.",
            description: "Si no se abrió ninguna pestaña, haz clic en el enlace de abajo.",
            link: "Haz clic aquí",
            button: "Ir a la billetera"
        },
        payment_account_modal: {
            title: "Crea una cuenta de pago",
        },
        payment_account_info_modal: {
            title: "Información de la cuenta de pago",
        },
        ligo_card_actions: {
            active: "Para activar tu tarjeta LIGO, primero debes activarla a través de LIGO.",
            blocked: "Para bloquear tu tarjeta LIGO, primero contacta al soporte de LIGO."
        },
        card_inswitch_request: {
            success: "Tarjeta virtual creada",
            error: "Error solicitando tarjeta virtual"
        },
        card_request: {
            success: "Solicitud de tarjeta creada",
            error: "Error solicitando tarjeta",
        },
        card_action_modal: {
            activate: {
                title: "Activa Tu Tarjeta Nemo",
                text: "Es necesario activar tu tarjeta antes de usarla.",
                success: "Tarjeta activada con éxito.",
                inswitch_error: "No se pudo activar la tarjeta.",
                ligo_error: "Para activar tu tarjeta LIGO, primero debes activarla a través de LIGO.",
            },
            blocked: {
                title: "Bloquea Tu Tarjeta Nemo",
                text: "Todas las transacciones de esta tarjeta serán congeladas.",
                success: "Tarjeta congelada exitosamente.",
                error: "Ocurrió un error inesperado.",
                ligo_error: "Para bloquear tu tarjeta LIGO, primero contacta al soporte de LIGO."
            },
            details: {
                title: "Detalles de la Tarjeta Nemo",
                text: "",
                success: "",
                error: ""
            },
            fund: {
                title: "Añade Fondos a Tu Tarjeta Nemo",
                text: "Para añadir fondos, realiza un retiro y selecciona tu cuenta de pago USD de la tarjeta Nemo.",
                success: "",
                error: ""
            }
        },
        notFoundPage: {
            title: "Página no encontrada" ,
            subtitle: "La página solicitada no se ha encontrado."
        },
        create_wallet: "Crear billetera",
        create_wallet_title: "Crea tu nueva dirección para  ",
        create_wallet_sub_title: "Crear dirección para la red",
        missing_form_fields : "Falta completar los campos",
        take_me_nemo: "Llévame a NEMO",
        balance: "Balance",
        show_balance: "Mostrar saldo",
        no_balance: "Sin saldo",
        assets: "Activos",
        total_balance: "Balance total",
        equivalent_balance: "Balance equivalente",
        available_balance: "Balance disponible",
        balance_after_withdrawal: "Balance después del retiro",
        small_balances: "Balances pequeños",
        long_coin_names: {
                COP: "Peso colombiano",
                USDT: "USD Tether",
                ETH: "Ethereum",
                BTC: "Bitcoin",
                PEN: "Sol Peruano",
                USD: "Dólar Estadounidense",
        },
        two_step_verification: "Verificación de dos pasos",
        first_name: "Nombre",
        last_name: "Apellidos",
        full_name: "Nombre completo",
        profile_header: "Mi perfil",
        cards_header: "Tu tarjeta",
        peertopeer_header: "Peer to peer",
        peertopeer_title: "Intercambia activos con otros usuarios",
        peertopeer_send: "Enviar",
        peertopeer_back: "Atrás",
        peertopeer_publicity_title: "Intercambia activos",
        peertopeer_publicity_description: "Realiza intercambios de activos de forma segura, rápida y transparente. ¡Sin tarifas!",
        peertopeer_username_placeholder: "Ingrese nombre de usuario",
        peertopeer_minimum_amount: "Mínimo",
        peertopeer_maximum_amount: "Máximo",
        peertopeer_send_to: "Enviando a",
        peertopeer_destination: "Destinatario",
        peertopeer_insert_username: "Ingresa el nombre de usuario",
        peertopeer_username_success: "Usuario válido",
        peertopeer_success: "Transferencia completada",
        peertopeer_errors: {
            invalid_value: "Por favor ingrese solo números",
            invalid_qr: "El código QR es inválido",
            too_much_decimals: "No puedes exceder el máximo de decimales",
            value_lower_than_min: "El valor ingresado es menor que el mínimo",
            value_higher_than_max: "El valor ingresado excede el máximo",
            invalid_username: "El usuario no existe o no es válido",
            cannot_send_to_self: "No puedes enviarte activos a ti mismo",
            transfer_error: "Hubo un error al hacer la transferencia",
            not_enough_balance: "No tienes suficiente saldo para realizar la transferencia",
            too_many_digits: "No puedes exceder el máximo de dígitos",
        },
        peertopeer_modal: {
            title: "Transferencia completada",
            user: "usuario",
            id: "id",
            close: "Volver"
        },
        country: "País",
        phone: "Teléfono",
        coin_price: "Precio moneda",
        cost_price: "Precio costo",
        amount: "Cantidad",
        limits: "Límites",
        limit_overflow: "El monto debe estar entre los límites",
        limit_overflow_disclaimer: "El monto debe estar entre los límites, de lo contrario no será acreditado",
        min_amount: "Cantidad Mínima",
        max_amount: "Cantidad Máxima",
        recieve_amount: "Cantidad a recibir",
        fee: "Comisión",
        min_fee: "Comisión mínima",
        actions: "Acciones",
        h_change: "Cambio 24h",
        coin: "Moneda",
        search_coin: "Buscar moneda",
        card: "Tarjeta",
        bank: "Banco",
        cash: "Efectivo",
        date: "Fecha",
        select_coin: "Selecciona una moneda",
        select_blockchain: "Selecciona una red",
        blockchain: "Red",
        blockchain_currency: "Red",
        select_method: "Selecciona un método",
        select_option: "Selecciona una opción",
        no_option: "No hay opción disponible",
        select_payment_account: "Selecciona una cuenta de pago",
        bank_name: "Nombre del banco",
        account_type: "Tipo de cuenta",
        account_type_checking : "Cuenta Corriente",
        account_type_savings : "Cuenta Ahorro",
        account_number: "Número de cuenta",
        phone_number: "Número de célular",
        firstname: "Nombre",
        lastname: "Apellido",
        document_number: "Número de documento",
        document_type: "Tipo de documento",
        nationalId: "Cédula de Ciudadanía",
        foreignerId: "Cédula de Extranjería",
        passport: "Pasaporte",
        chanceryId: "Tarjeta Diplomática",
        ruc: "RUC",
        display_name: "Alias de la cuenta",
        display_name_description: "Ingrese el nombre con el que desee identificar esta cuenta",
        address: "Dirección",
        city: "Ciudad",
        installments: "Cuotas",
        send: "Enviar",
        insert: "Ingrese su ",
        success_copy_address: "Dirección copiada exitosamente",
        error_copy_address: "Hubo un error al realizar la copia de la dirección",
        copied_to_clipboard: "Copiado al portapapeles",
        error_copy_clipboard: "Error al copiar al portapapeles",
        payment_account_created: "Nueva cuenta de pago creada",
        balance_history: "Balance / Historial",
        selling: "Das",
        getting: "Recibes",
        convert: "Convertir",
        exceeds_balance_more_than_max: "La cantidad seleccionada excede el saldo actual o el tamaño máximo de la orden para el activo a dar",
        less_than_min: "La cantidad seleccionada es menor que el mínimo aceptable para el activo a dar",
        selected_amount_not_available: "La cantidad seleccionada no está disponible, por favor contacte a soporte",
        movements:"Movimientos",
        order_executed: "Orden ejecutada exitosamente",
        traded: "Se intercambió",
        for: "por",
        invalid_address: "Cuenta de pago inválida",
        payouts_freezed: "Los pagos están actualmente congelados debido a un restablecimiento de contraseña.",
        next:"Siguiente",
        previous:"Anterior",
        nomovements:"Todavía no tienes historial de transacciones",
        nolists: "Todavía no tienes listas de usuarios",
        nomasstransfers: "Todavía no tienes transferencias masivas",
        status_mapping: {
            pending: "Pendiente",
            to_be_sent: "Para enviar",
            done: "Hecho",  
            canceled: "Cancelado",
            cancelled: "Cancelado",
            failed: "Fallido",
            processing: "Processing",
            completed: "Completado",
            unknown: "Desconocido",
            wait_confirmation: "Confirmando",
            reverted: "Revertido",
            verifying: "Verificando",
          },
        statuses: "Estado",
        available: "Disponible",
        trade_history: "Historial",
        gave_got: "Diste | Recibiste",
        gave: "Diste",
        got: "Recibiste",
        price: "Precio",
        no_balance_conv: "Balance moneda base es 0",
        fee_apply_t: "Comisión aplicada de",
        fee_equivalent_t: "equivalente a",
        hello_nickname: "Bienvenido,",
        birthday: "Fecha de nacimiento",
        old_password: "Contraseña actual",
        old_password_placeholder: "Ingrese su contraseña actual",
        change_password_label: "Contraseña",
        change_password: "Cambiar contraseña",
        change_password_button: "Cambiar",
        enable_two_fa: "Habilitar 2FA",
        addresses_label: "Mis domicilios",
        add_address_button: "Agregar domicilio",
        request_virtual_card: "Solicitar tarjeta virtual",
        request_card: "Solicitar tarjeta",
        wrong_old_password: "Contraseña actual incorrecta",
        security: "Seguridad",
        addresses: "Domicilios",
        two_fa: "Autenticación de dos factores",
        enable: "Habilitar",
        disable: "Deshabilitar",
        two_fa_off: "2FA deshabilitado",
        two_fa_on: "2FA habilitado",
        tfa_explanation: "Escanear código QR con algún app autenticador",
        scan_qr: "Escanear",
        stop_scan_qr: "Cancelar",
        scan_qr_modal: {
            title: "Escanear código QR",
            error_camera: "Hubo un error, por favor verifica los permisos de tu cámara",
            error_scanner: "Error de javascript",
            close: "Cancelar"
        },
        back: "Volver",
        submit: "Enviar",
        ready: "Listo",
        put_authenticator_otp: "Ingrese el OTP del autenticador",
        tfa_otp_input_placeholder: "XXXXXX",
        otp_failed: "Código erroneo",
        tfa_setup_successful: "Autenticación de 2 factores exitosa",
        tfa_disable_successful: "Autenticación de 2 factores removida exitosamente",
        card_coin: "Activo para retiros",
        card_balance: "Balance",
        card_options_title: "Acciones",
        card_asset_info_modal: {
            // NOTE USDT HERE IS HARDCODED
            title: 'Activo para retiros',
            text: 'El activo seleccionado se usará para los retiros o compras con tu tarjeta Nemo, que está en USD. Actualmente, solo se admite USDT, pero pronto estarán disponibles más activos. La conversión se maneja internamente al precio de mercado.',
        },
        card_pan: "Número de Tarjeta",
        card_expiry: "Fecha de expiración",
        card_cvv: "CVV",
        card_data_disclaimer: "Por motivos de seguridad, los datos de la tarjeta (número y CVV) se generan de forma dinámica y pueden cambiar cada vez que visualices los detalles.",
        inswitch: "Tarjeta Virtual", // Card vendor name
        ligo: "Tarjeta Ligo", // Card vendor name
        activate_card: "Activar",
        block_card: "Bloquear",
        show_details: "Mostrar Datos",
        add_funds: "Agregar Fondos",
        inswitch_disclaimer: `El titular de la cuenta bancaria debe ser el mismo titular de la cuenta de ${localConfig.brand.toUpperCase()}. Es responsabilidad del usuario que el nombre y número de identificación de la cuenta bancaria coincidan con los datos registrados en su cuenta de ${localConfig.brand.toUpperCase()}.`,
        website_under_maintenance: "Plataforma en mantenimiento, estaremos de vuelta en unos momentos.",
        nemocard: "Nemo Card",
        active: "Activa",
        inactive: "Inactiva",
        blocked: "Bloqueada",
        suspended: "Suspendida",
        assigned: "Asignada",
        unassigned: "No Asignada",
        error_refresh_page: "Intenta nuevamente en unos minutos",
        delete: "Eliminar",
        bank_transfer: "Transferencia bancaria",
        account_credit: "Abono a cuenta",
        cci_number: "CCI",
        title: {
            default: "Compra y Vende Criptomonedas | NEMO Latam",
            "": "Billetera y Balance",
            converter: "Convierte Monedas",
            peertopeer: "Intercambio de Activos",
            payrolls: "Paga empleados en cripto o fiat",
            deposit: "Deposita Cripto y FIAT",
            withdraw: "Retira Cripto y FIAT",
            profile: "Perfil y Configuración",
            cards: "Tarjetas Nemo",
            kyc: "Verifica tu identidad",
            login: "Inicia sesión para comenzar",
            register: "Crea una cuenta",
            "confirm-register": "Verifica tu correo electrónico",
            "reset-password": "Restablece tu contraseña",
            support: "Soporte",
            maintenance: "Página en mantenimiento",
            "*": "No encontrado",
        },
        converter_errors: {
            invalid_value: "Por favor ingrese solo números",
            value_lower_than_min: "El valor ingresado es menor que el mínimo",
            value_higher_than_max: "El valor ingresado excede el tamaño máximo de la orden para el activo a dar",
            value_higher_than_balance: "El valor ingresado excede el saldo disponible",
        },
        try_later_or_contact_support: "Ocurrió un error, intente de nuevo después o contacte con soporte",
        account_block: "Cuenta bloqueada, por favor contacte a soporte",
        other: "Otro",
        withdraw: "Retirar",
        masked_pan: "Número de tarjeta",
        type: "Tipo",
        physical: "Física",
        brand: "Marca",
        mastercard: "Mastercard",
        visa: "Visa",
        nemo_card_request: "Solicita tu Tarjeta Nemo y Simplifica tus Finanzas",
        nemo_card_request_info: "Con la Tarjeta Nemo, gestiona y accede a tus fondos de forma rápida, segura y sin complicaciones. Aprovecha al máximo tus recursos en cualquier momento y lugar.",
        nemo_card_question_1: "¿Cómo obtenerla?",
        nemo_card_answer_1_a: "Método de entrega: Recógela personalmente.",
        nemo_card_answer_1_b: "Dirección de entrega: Av. Santa Cruz 198, San Isidro, Lima",
        nemo_card_answer_1_c: "Tarjeta virtual: Pídela a travez de la plataforma.",
        nemo_card_answer_1_d: "Tarjeta física: ¡Proximamente!, pronto podrás solicitar este servicio.",
        nemo_card_question_2: "Características principales:",
        nemo_card_answer_2_a: "Marca: Visa, aceptada en millones de establecimientos en todo el mundo.",
        nemo_card_answer_2_b: "Beneficios: Agilidad, conveniencia y control total sobre tus fondos.",
        nemo_card_answer_2_c: "Marca: Mastercard, aceptada en millones de establecimientos en todo el mundo.",
        nemo_card_call_to_action: "¡Solicítala hoy mismo y lleva tus transacciones al siguiente nivel!",
        nemo_card_details_warning: "Por motivos de seguridad, los datos de la tarjeta (número y CVV) se generan de forma dinámica y pueden cambiar cada vez que visualices los detalles.",
        wallet_history_item: {
            Withdrawal: "Retiro",
            Deposit: "Depósito",
            original_label: "Original",
            fee_label: "Tarifa",
            total_label: "Total"
        },
        card_delivery: "Entrega de la tarjeta",
        delivery_method: "Método de entrega",
        pick_up:  "Recógela personalmente",
        delivery_address: "Dirección de entrega",
        nemo_address: "Av. Santa Cruz 198, San Isidro, Lima",
        nemo_office_address: localConfig.countryCode === "PE" ? "Av. Santa Cruz 198, San Isidro, Lima" : "Calle 6 Sur #43A-100, Medellín, Colombia",
        user_address: {
            title: "Agregar domicilio",
            search_address_label: "Buscar domicilio",
            search_address_placeholder: "Ingrese su domicilio",
            street_label: "Calle",
            street_placeholder: "Ingrese su calle",
            city_label: "Ciudad",
            city_placeholder: "Ingrese su ciudad",
            state_label: "Estado",
            state_placeholder: "Ingrese su estado",
            zip_code_label: "Código postal",
            zip_code_placeholder: "Ingrese su código postal",
            country_label: "País",
            country_placeholder: "Ingrese su país",
            submit: "Agregar domicilio",
            address_added: "Dirección agregada exitosamente",
            unexpected_error: "Ocurrió un error inesperado",
            too_many_addresses: "Has alcanzado el límite de direcciones permitidas",
        },
        card_request_form: {
            request_sent: "Solicitud enviada",
            request_sent_text: "¡Estas a pocos pasos de tener tu Nemo Card!",
            virtual_card: "Tarjeta virtual",
            virtual_card_description: "Tu tarjeta aparecerá aquí cuando sea aprobada",
            info: "Tu solicitud de tarjeta será revisada por nuestro equipo. Puedes elegir recogerla o que se te entregue en tu dirección",
            title: "Crear solicitud de tarjeta",
            select_address: "Seleccionar dirección de entrega",
            select_card_type: "Seleccionar tipo de tarjeta",
            select_type: "Seleccionar tipo de entrega",
            physical: "Física",
            virtual: "Virtual",
            pickup: "Recoger en oficina",
            delivery: "Entrega a domicilio",
            submit: "Enviar",
            success: "Solicitud de tarjeta creada exitosamente",
            error: "Ocurrió un error inesperado al crear la solicitud de tarjeta",
            created: "Esperando aprobación",
            cancel_request: "Cancelar solicitud",
            disclaimer: "Recibirás un correo con las indicaciones para recoger su tarjeta",
            declined: "Solicitud rechazada"
        },
        card_request_cancel: {
            title: "Cancelar solicitud de tarjeta",
            description: "Por favor confirma que deseas cancelar tu solicitud de tarjeta",
            action: "Confirmar"
        },
        months: {
            1: "Ene",
            2: "Feb",
            3: "Mar",
            4: "Abr",
            5: "May",
            6: "Jun",
            7: "Jul",
            8: "Ago",
            9: "Sep",
            10: "Oct",
            11: "Nov",
            12: "Dic"
        },
        support: "Soporte",
        tickets: "Tickets",
        subject: "Asunto",
        upload_image: "Subir imagen",
        optional: "Opcional",
        sent: "Enviar",
        support_ticket_creation_success: "Ticket creado correctamente",
        support_ticket_creation_error: "No se pudo crear el ticket",
        support_ticket_size_error: "No se pudo cargar la imagen",
        ticket_new: "Nuevo",
        ticket_open: "En espera",
        ticket_pending: "Responder",
        ticket_solved: "Cerrado",
        close: "Cerrar",
        support_ticket_created_modal: {
            title: "Ticket creado con éxito",
            info: "Tu ticket ha sido creado con éxito. En unos segundos, podrás verlo en la otra sección.",
        },
        faq: "Preguntas frecuentes",
        faq_search_error: "Búsqueda vacía",
        search: "Buscar",
        no_data_search: "No se encontraron resultados",
        no_tickets: "No se encontraron tickets",
        create_support_ticket: "Crea un ticket de ayuda",
        payrolls_main_title: "Enviar transferencia masiva",
        payrolls_user_lists: "Listas de destinatarios",
        payrolls_configure: "Configurar transferencia",
        payrolls_send: "Enviar transferencia",
        payrolls_save_list: "Guardar lista",
        payrolls_clean_list: "Vaciar lista",
        payrolls_recipients: "Destinatarios",
        payrolls_valid_username: "Usuario válido",
        payrolls_user_list_created: "Lista de usuarios creada",
        payrolls_transfer_success: "Transferencia pendiente de aprobación",
        payrolls_transfer_error: "Error al realizar la transferencia",
        payrolls_transfer_name: "Transferencia",
        payrolls_form: {
            amount_placeholder: "Ingrese cantidad a enviar",
            new_email_placeholder: "Agregar correo de destinatario",
            search_user: "Buscar usuario",
            sort_invalid: "Invalidos",
            sort_amount: "Monto",
            sort_alpha: "A-Z",
            delete_user_modal: {
                title: "Eliminar usuario",
                text: "Por favor confirma que deseas eliminar al usuario:",
                confirm: "Eliminar",
                cancel: "Cancelar"
            },
            delete_all_users_modal: {
                title: "Vaciar lista",
                text: "Por favor confirma que deseas vaciar la lista de usuarios",
                confirm: "Vaciar",
                cancel: "Cancel"
            },
            delete_list_modal: {
                title: "Eliminar lista de destinatarios",
                text: "Por favor confirma que deseas eliminar la lista de destinatarios",
                confirm: "Eliminar",
                cancel: "Cancelar",
                success: "Lista de destinatarios eliminada",
                error: "Error eliminando la lista de destinatarios"
            },
            history_filters: {
                amount: "Monto",
                date: "Fecha",
                min_amount: "Monto mínimo",
                max_amount: "Monto máximo",
                start_date: "Fecha de inicio",
                end_date: "Fecha de fin",
                date_range: "Rango de fechas",
                sort_by: "Ordenar por",
                currency: "Moneda",
                min: "Min",
                max: "Max",
                newest_first: "Más recientes",
                oldest_first: "Más antiguos",
                amount_high_to_low: "Monto alto a bajo",
                amount_low_to_high: "Monto bajo a alto",
                search_user: "Buscar usuario",
                user_email: "Ingrese el correo del usuario",
                clear_filters: "Limpiar filtros",
                download_excel: "Descargar excel"
            },
            added_users: "{{new}} usuarios agregados",
            added_repeated_users: "{{repeated}} usuarios repetidos, {{new}} usuarios agregados",
            added_invalid_users: "{{invalid}} usuarios no existen o no han completado KYC",
            added_user: "Usuario agregado: {{user}}",
            updated_user: "Usuario editado {{user}}",
            deleted_user: "Usuario eliminado: {{user}}",
            deleted_all_users: "Todos los usuarios eliminados",
            no_users: "Agrege destinatarios para verlos aquí"
        },
        payrolls_summary: {
            num_users: "Número de usuarios",
            total_amount: "Monto total",
            min_amount: "Monto mínimo",
            max_amount: "Monto máximo",
            average_amount: "Monto promedio",
            current_balance: "Balance actual",
            balance_after_transaction: "Balance después de la transacción",
        },
        payrolls_file_upload: {
            title: "Cargar usuarios desde archivo",
            description: "Por favor use la plantilla e ingrese los usuarios a transferir",
            drop_here: "Arrastre y suelte el archivo aquí",
            download_template: "Descargar plantilla",
            download_template_description: "Use esta plantilla para cargar los usuarios",
            upload_file: "Subir archivo",
            upload_file_description: "Archivos aceptados: .csv, .txt, .xlsx",
            success: "Archivo procesado, agregando usuarios",
            close: "Cerrar",
            errors: {
                invalid_file_type: "Tipo de archivo inválido",
                invalid_file: "Archivo inválido o vacío",
                parse_error: "Error procesando el archivo",
                check_error: "Error al verificar los usuarios"
            }
        },
        payrolls_edit_user_modal: {
            title: "Editar usuario",
            email_label: "Ingrese el usuario del destinatario",
            email_placeholder: "Ingrese correo electrónico",
            submit: "Guardar",
            delete: "Eliminar",
            cancel: "Cancelar"
        },
        payrolls_errors: {
            cannot_send_to_self: "No puedes enviarte a ti mismo",
            duplicate_user: "El usuario ya ha sido agregado",
            invalid_username: "El usuario no existe o no ha completado su KYC",
            error_creating_user_list: "Error creando la lista de usuarios",
        },
        mass_transfers_modal: {
            title: "Transferencias masivas",
            select_transfer: "Seleccione una transferencia para ver el detalle"
        },
        create_user_list_modal: {
            title: "Guardar lista de destinatarios",
            name_label: "Nombre de la lista",
            name_placeholder: "Ingrese el nombre de la lista",
            description_label: "Descripción",
            description_placeholder: "Ingrese una descripción",
            num_users: "Usuarios",
            preferred_amount_label: "Monto preferido",
            preferred_amount_placeholder: "Ingrese el monto preferido",
            preferred_currency_label: "Moneda preferida",
            preferred_currency_placeholder: "Seleccione la moneda",
            submit: "Crear",
            cancel: "Cancelar"
        },
        send_mass_transfer_modal: {
            title: "Enviar transferencia",
            name_label: "Nombre de la transferencia",
            name_placeholder: "Ingrese el nombre de la transferencia",
            submit: "Enviar transferencia",
            cancel: "Cancelar"
        },
        edit_user_list_modal: {
            title: "Editar lista de destinatarios",
            name_label: "Nombre de la lista",
            name_placeholder: "Ingrese el nombre de la lista",
            description_label: "Descripción",
            description_placeholder: "Ingrese una descripción",
            num_users: "Usuarios",
            new_email_placeholder: "Agregar correo de destinatario",
            invalid_username: "Usuario inválido",
            preferred_amount_label: "Monto preferido",
            preferred_amount_placeholder: "Ingrese el monto preferido",
            preferred_currency_label: "Moneda preferida",
            preferred_currency_placeholder: "Seleccione la moneda",
            success: "Lista de usuarios editada exitosamente",
            error_updating_user_list: "Error editando la lista de usuarios",
            submit: "Guardar",
            cancel: "Cancelar"
        },
        check_username_input: {
            placeholder: "Ingrese el usuario",
            valid_username: "Usuario válido",
            invalid_username: "Usuario inválido",
            cannot_send_to_self: "No puedes enviarte a ti mismo",
            repeated_username: "Usuario repetido"
        },
        creating_mass_transfer: "Creando transferencia masiva...",
        list_name: "Nombre de la lista",
        list_name_required: "El nombre de la lista es requerido",
        add_recipient: "Agregar destinatario",
        remove_recipient: "Eliminar destinatario",
        fill_all_required_fields: "Por favor complete todos los campos requeridos",
        mass_transfer_created_successfully: "Transferencia masiva creada exitosamente",
        error_creating_mass_transfer: "Error al crear la transferencia masiva",
        recent_mass_transfers: "Historial",
        loading: "Cargando...",
        no_transfers_yet: "Aún no hay transferencias masivas",
        recipients_count: "{{count}} destinatarios",
        status_options: {
            pending: "Pendiente",
            processing: "Procesando",
            completed: "Completada",
            failed: "Fallida"
        },
        user_list: "Lista de usuarios",
        create_new_list: "Crear nueva lista",
        select_existing_list: "Seleccionar lista existente",
        transfer_details: "Detalles de la transferencia",
        remove: "Eliminar",
        select_or_create_list: "Por favor seleccione o cree una lista de usuarios",
        fill_all_emails: "Por favor complete todos los correos electrónicos",
        amount_required: "El monto es requerido",
        no_user_lists: "Aún no hay listas de usuarios",
        user_list_modal: {
            title: "Agregar lista de destinatarios",
            name_label: "Nombre",
            description_label: "Descripción",
            search_placeholder: "Buscar usuario",
            no_users: "No se encontraron usuarios",
            total_users: "Total",
            preferred_amount: "Monto preferido",
            select_button: "Agregar",
            close_button: "Cerrar"
        },
        mass_transfer_item: {
            recipients_count: "{{count}} destinatarios",
            total_label: "Total",
            amount_label: "Monto",
            status: {
              pending: "Pendiente",
              processing: "Procesando",
              completed: "Completada",
              failed: "Fallida",
              cancelled: "Cancelada"
            }
        },
        need_help: "¿Necesitas ayuda?",
        refer: "Invita a un amigo",
        referral_section: {
            title: "Invita a tus amigos",
            description: "Comparte tu código de invitación con amigos para que se unan.",
            copy_code: "Copiar enlace de invitación"
        },
        conection_slow: "Conexión lenta, click aquí para intentar nuevamente o contactar soporte si el problema persiste",
    },
    en: {
        rates_are_referential: "*Shown rates are referential only",
        input_number_to_see_rates: "Enter amount to see rate",
        asset: "Asset",
        validating_kyc_data_title: "We are processing your info",
        validating_kyc_data_text: `In a few moments, you will be able to enter ${localConfig.brand}`,
        not_available: "Not available",
        login_or_register_to_enter_raffle: "Sign in or register to participate",
        success_registration_raffle: "Registration success",
        we_are: "We are in",
        maintenance: "maintenance",
        icon: usacircle,
        apple_download_badge: appstoreen,
        google_download_badge: googleplayen,
        continue: "Continue",
        description: "Description",
        login: "Log in",
        logout: "Log out",
        email: "Email Address",
        password: "Password",
        email_placeholder: "Enter your email address",
        password_placeholder: "Enter your password",
        dont_have_account: "Don't have an account?",
        forgot_password: "Forgot your password?",
        invalid_login: "Invalid credentials",
        reset_psw: "Due to the update, it is necessary to reset your password.",
        confirm_password: "Confirm password",
        confirm_password_placeholder:"Enter your password again",
        register: "Create account",
        register_success: "Account created successfully",
        register_fail: "Account creation failed",
        already_have_account: "Already have an account?",
        password_mismatch: "Passwords don't match",
        password_too_short: "At least 8 characters",
        password_needs_uppercase: "At least an uppercase letter",
        password_needs_lowercase: "At least a lowercase letter",
        password_needs_number: "At least 1 digit",
        password_needs_special: "At least one special character",
        hinting_regex_error: "Password does not meet minimum requirements",
        complete_the_captcha: "Complete the CAPTCHA",
        register_failed: "Account creation failed, please try again",
        balance_navbar: "Total balance",
        terms_text1: "I have read and agree to the",
        terms_text2: "of Nemo Latam.",
        terms_and_conditions: "Terms and Conditions",
        privacy_policy: "Privacy Policy",
        data_processing_policy: "Data Processing Policy",
        declaration_text1: "I have read and confirm the",
        goods_declaration: "Declaration of Source of Funds.",
        authorization_text1: "I have read and confirm the",
        data_authorization: "Authorization for the processing",
        authorization_text2: "of my personal data.",
        confirm_email: "Confirm email",
        email_code: "Confirmation code",
        email_code_text: "Email with a code has been sent to you, please enter it in the form below",
        confirm: "Confirm",
        cancel: "Cancel",
        code_placeholder: "Enter your email confirmation code",
        resent_code: "Code sent",
        email_confirmed: "Email confirmation successful",
        verify_email: "Verify email",
        back_to_login: "Back to login",
        resend_code: "Resend code",
        in: "in",
        name: "Name",
        seconds: "seconds",
        click_to: "Click to",
        click_here: "Click here",
        incorrect_code: "Incorrect code",
        reset_password: "Reset password",
        email_sent: "Email sent",
        error: "Error",
        unexpected_error: "An unexpected error occurred",
        invalid_confirmation_code: "Invalid confirmation code",
        captcha_failed: "Captcha failed",
        reset_continue_text: "We have sent you an email with instructions on how to proceed with your password reset.",
        password_reset_success: "Password reset successfully",
        new_password: "New password",
        confirm_new_password: "Confirm new password",
        new_password_placeholder: "Enter your new password",
        new_confirm_password_placeholder: "Confirm your new password",
        reset_code_expired: "Reset link expired, request a new one",
        reset_password_request: "Password reset request",
        reset_password_request_button: "Request password reset",
        password_reset_success_text: "Password reset successful. You may now log in normally.",
        wallet: "Wallet",
        converter: "Converter",
        deposit: "Deposit",
        peertopeer: "Peer2peer",
        payrolls: "Transfers",
        withdrawal: "Withdrawal",
        deposit_noun: "Deposit",
        unknown: "Unknown",
        withdrawal_noun: "Withdrawal",
        cards: "Cards",
        profile: "Profile",
        modal_deposit: {
            title: "Deposit Request",
            info: "You have created a deposit request, please follow the steps in the new tab to complete this process.",
            description: "If no tab was opened, click the link below.",
            link: "Click here",
            button: "Go to wallet"
        },
        modal_withdrawal: {
            title: "Withdrawal Request",
            info: "You have created a withdrawal request, please follow the steps in the new tab to complete this process.",
            info_card: "Your withdrawal request has been received. Please note that confirmation may take up to 24 hours.",
            info_crypto: "You have created a withdrawal request. Please wait until confirmation to see your balance updated",
            description: "If no tab was opened, click the link below.",
            link: "Click here",
            button: "Go to wallet"
        },
        payment_account_modal: {
            title: "Create payment account",
        },
        payment_account_info_modal: {
            title: "Payment account info",
        },
        active_card_modal: {
            title: "Activate Your Nemo Card",
            text: "To use your card, it is necessary to activate it. Please enter the tracking number of the card.",
            placeholder: "Insert the tracking number"
        },
        ligo_card_actions: {
            active: "To activate your LIGO card, you must first activate it through LIGO.",
            block: "To block your LIGO card, first please contact LIGO support."
        },
        card_inswitch_request: {
            success: "Virtual card created",
            error: "Error requesting card"
        },
        card_request: {
            success: "Card request created",
            error: "Error requesting card",
        },
        card_action_modal: {
            activate: {
                title: "Activate Your Nemo Card",
                text: "Your card must be activated before it can be used.",
                success: "Card activated successfully.",
                inswitch_error: "The card could not be activated.",
                ligo_active: "To activate your LIGO card, you must first activate it through LIGO.",
            },
            blocked: {
                title: "Block Your Nemo Card",
                text: "All transactions on this card will be block.",
                success: "Card successfully blocked.",
                inswitch_error: "An unexpected error occurred.",
                ligo_error: "To block your LIGO card, first please contact LIGO support."
            },
            details: {
                title: "Nemo Card Details",
                text: "",
                success: "",
                inswitch_error: "",
                ligo_error: ""
            },
            fund: {
                title: "Add Funds to Your Nemo Card",
                text: "To add funds, make a withdrawal and select your USD Nemo Card payment account.",
                success: "",
                inswitch_error: "",
                ligo_error: ""
            }
        },
        notFoundPage: {
            title: "Page not found" ,
            subtitle: "The page you requested could not be found."
        }, 
        create_wallet: "Create wallet",
        create_wallet_title: "Create new address for",
        create_wallet_sub_title: "Create address for the network",
        missing_form_fields : "Missing form fields",
        take_me_nemo: "Take me to NEMO",
        balance: "Balance",
        show_balance: "Show balance",
        no_balance: "No balance",
        assets: "Assets",
        total_balance: "Total balance",
        equivalent_balance: "Equivalent balance",
        available_balance: "Available Balance",
        balance_after_withdrawal: "Balance after withdrawal",
        small_balances: "Small balances",
        long_coin_names: {
                COP: "Colombian peso",
                USDT: "USD Tether",
                ETH: "Ethereum",
                BTC: "Bitcoin",
                PEN: "Peruvian Sol",
                USD: "US Dollar",
        },
        two_step_verification: "2-Step verification",
        first_name: "First name",
        last_name: "Last name",
        full_name: "Full name",
        profile_header: "My profile",
        cards_header: "Your Card",
        peertopeer_header: "Peer to peer",
        peertopeer_title: "Exchange assets with other users",
        peertopeer_send: "Send",
        peertopeer_back: "Back",
        peertopeer_publicity_title: "Peer-to-Peer Asset Exchange",
        peertopeer_publicity_description: "Execute asset exchanges securely, quickly, and transparently. With no fee!",
        peertopeer_username_placeholder: "Insert username",
        peertopeer_minimum_amount: "Min",
        peertopeer_maximum_amount: "Max",
        peertopeer_send_to: "Sending to",
        peertopeer_destination: "Destination",
        peertopeer_insert_username: "Enter the username",
        peertopeer_username_success: "Username is valid",
        peertopeer_success: "Transfer completed",
        peertopeer_errors: {
            invalid_value: "The entered value is not a number",
            invalid_qr: "QR code is invalid",
            too_much_decimals: "You cant exceed the maximum amount of decimals",
            value_lower_than_min: "The entered value is lower than the minimum",
            value_higher_than_max: "The entered value exceeds the maximum",
            invalid_username: "El usuario no existe o no es válido",
            cannot_send_to_self: "You can't send assets to yourself",
            transfer_error: "There was an error making the transfer",
            not_enough_balance: "You don't have enough balance to make the transfer",
            too_many_digits: "Can´t exceed 10 digits in the amount",
        },
        peertopeer_modal: {
            title: "Transfer complete",
            user: "user",
            id: "id",
            close: "Back"
        },
        country: "Country",
        coin_price: "Coin price",
        cost_price: "Cost price",
        amount: "Amount",
        limits: "Limits",
        limit_overflow: "Amount should between the limits",
        limit_overflow_disclaimer: "The amount must be within the limits; otherwise, it will not be credited.",
        min_amount: "Min amount",
        max_amount: "Max amount",
        recieve_amount: "Receive amount",
        fee: "Fee",
        min_fee: "Min fee",
        actions: "Actions",
        h_change: "24h change",
        coin: "Coin",
        search_coin: "Search coin",
        card: "Card",
        bank: "Bank",
        cash: "Cash",
        date: "Date",        
        select_coin: "Select a coin",
        select_blockchain: "Select a network",
        blockchain: "Blockchain",
        blockchain_currency: "Blockchain",
        select_method: "Select a method",
        select_option: "Select an option",
        no_option: "No option available",
        select_payment_account: "Select a payment account",
        bank_name: "Bank name",
        account_type: "Account type",
        account_type_checking : "Checking account",
        account_type_savings : "Savings account",
        account_number: "Account number", 
        phone_number: "Phone number",
        firstname: "First Name",
        lastname: "Surname",
        document_number: "Document Number",
        document_type: "Document Type",
        nationalId: "Citizenship ID",
        foreignerId: "Foreigner ID",
        passport: "Passport",
        chanceryId: "Diplomatic ID",    
        ruc: "RUC",
        display_name: "Display Name", 
        display_name_description: "Enter the name you want to identify this account with",
        address: "Address",
        city: "City",
        phone: "Phone",
        installments: "Installments",
        send: "Send",
        insert: "Insert your ",
        success_copy_address: "Address copied successfully",
        error_copy_address: "There was an error copying the address",
        copied_to_clipboard: "Copied to clipboard",
        error_copy_clipboard: "Error copying to clipboard",
        payment_account_created: "New payment account created",
        balance_history: "Balance / History",
        selling: "Give",
        getting: "Get",
        convert: "Convert",
        exceeds_balance_more_than_max: "Selected amount exceeds balance or max order size for base asset",
        less_than_min: "Selected amount is less than the order minimum for base asset",
        selected_amount_not_available: "Selected amount is not available, please contact support",
        order_executed: "Order executed successfully",
        traded: "Traded",
        for: "for",
        invalid_address: "Invalid payment account",
        payouts_freezed: "Payouts are currently frozen due to a password reset.",
        next: "Continue",
        previous:"Previous",
        movements:"Movements",
        all_coins:"All Coins",
        nomovements:"You have no transaction history",
        nolists: "You have no user lists",
        nomasstransfers: "You have no mass transfers",
        status_mapping:{
            pending: "Pending" ,
            to_be_sent: "To be sent",
            done: "Done",
            canceled: "Cancelled",
            cancelled: "Cancelled",
            failed: "Failed",
            processing: "Processing",
            completed: "Completed",
            unknown: "Unknown",
            wait_confirmation: "Confirming",
            reverted: "Reverted",
            verifying: "Verifying",
        },
        statuses: "Status",
        available: "Available",
        trade_history: "Trade history",
        gave_got: "Gave | Got",
        gave: "Gave",
        got: "Got",
        price: "Price",
        no_balance_conv: "Base asset balance is 0",
        fee_apply_t: "Applied fee of",
        fee_equivalent_t: "totaling",
        hello_nickname: "Welcome,",
        birthday: "Birthday",
        old_password: "Current password",
        old_password_placeholder: "Enter your current password",
        change_password_label: "Password",
        change_password: "Change password",
        change_password_button: "Change",
        enable_two_fa: "Enable 2FA",
        addresses_label: "My addresses",
        add_address_button: "Add home address",
        request_virtual_card: "Request virtual card",
        request_card: "Request card",
        wrong_old_password: "Wrong current password",
        security: "Security",
        addresses: "Home addresses",
        two_fa: "Two factor authentication",
        enable: "Enable",
        disable: "Disable",
        two_fa_off: "2FA disabled",
        two_fa_on: "2FA enabled",
        tfa_explanation: "Scan QR code using an authenticator app",
        scan_qr: "Scan",
        stop_scan_qr: "Cancel",
        scan_qr_modal: {
            title: "Scan QR code",
            error_camera: "There was an error, please check your camera permisions",
            error_scanner: "Javascript error",
            close: "Cancel"
        },
        back: "Back",
        submit: "Submit",
        ready: "Ready",
        put_authenticator_otp: "Enter authenticator OTP",
        tfa_otp_input_placeholder: "XXXXXX",
        otp_failed: "Wrong code",
        tfa_setup_successful: "2 factor authentication setup successful",
        tfa_disable_successful: "2 factor authentication removed successfully",
        card_coin: "Asset for withdrawals",
        card_balance: "Balance",
        card_options_title: "Actions",
        card_asset_info_modal: {
            // NOTE USDT HERE IS HARDCODED
            title: 'Asset for withdrawals',
            text: 'The selected asset will be used for withdrawals or purchases  with your Nemo Card, which is in USD. Currently, only USDT is supported, but more assets will be available soon. Conversion is handled internally at market price.',
        },
        card_pan: "Primary Account Númber",
        card_expiry: "Expiration date",
        card_cvv: "CVV",
        card_data_disclaimer: "For security reasons, the card details (number and CVV) are generated dynamically and may change each time you view the details.",
        inswitch: "Virtual Card", // Card vendor name
        ligo: "Ligo Card", // Card vendor name
        activate_card: "Activate",
        block_card: "Block",
        show_details: "Show Details",
        add_funds: "Add Funds",
        inswitch_disclaimer: `The bank account holder must be the same as the ${localConfig.brand.toUpperCase()} account holder. It is the user's responsibility to ensure that the name and bank account identification number match the data registered in their ${localConfig.brand.toUpperCase()} account.`,
        website_under_maintenance: "Exchange under maintenance, we will be back shortly.",
        nemocard: "Nemo Card",
        active: "Active",
        inactive: "Inactive",
        blocked: "Blocked",
        suspended: "Suspended",
        assigned: "Assigned",
        unassigned: "Unassigned",
        error_refresh_page: "Try again in a few minutes",
        delete: "Delete",
        bank_transfer: "Bank transfer",
        account_credit: "Credit account",
        cci_number: "CCI",
        title: {
            default: "Buy & Sell Crypto | NEMO Latam",
            "": "Wallet & Balance",
            converter: "Convert Coins",
            peertopeer: "Asset Exchange",
            payrolls: "Pay employees in crypto or fiat",
            deposit: "Deposit Crypto & FIAT",
            withdraw: "Withdraw Crypto & FIAT",
            profile: "Profile & Settings",
            cards: "Nemo Cards",
            kyc: "Verify your identity",
            login: "Log in to get started",
            register: "Create an account",
            "confirm-register": "Verify your email address",
            "reset-password": "Reset your password",
            support: "Support",
            maintenance: "Site under maintenance",
            "*": "Not Found",
        },
        converter_errors: {
            invalid_value: "The input value is not a number",
            value_lower_than_min: "The input value is lower than the minimum",
            value_higher_than_max: "The input value exceeds maximum order size",
            value_higher_than_balance: "The input value exceeds the current balance",

        },
        account_block: "Account blocked, please contact support",
        other: "Other",
        withdraw: "Withdraw",
        masked_pan: "Card number",
        type: "Type",
        physical: "Physical",
        brand: "Brand",
        mastercard: "Mastercard",
        visa: "Visa",
        nemo_card_request: "Request Your Nemo Card and Simplify Your Finances",
        nemo_card_request_info: "With the Nemo Card, manage and access your funds quickly, securely, and effortlessly. Make the most of your resources anytime, anywhere.",
        nemo_card_question_1: "How to get it?",
        nemo_card_answer_1_a: "Delivery method: Pick it up in person.",
        nemo_card_answer_1_b: "Pickup address: Av. Santa Cruz 198, San Isidro, Lima.",
        nemo_card_answer_1_c: "Virtual card: Request it through the platform.",
        nemo_card_answer_1_d: "Physical card: Comming soon!, this service is being launched soon.",
        nemo_card_question_2: "Key Features:",
        nemo_card_answer_2_a: "Brand: Visa, accepted at millions of locations worldwide.",
        nemo_card_answer_2_b: "Benefits: Speed, convenience, and full control over your funds.",
        nemo_card_answer_2_c: "Brand: Mastercard, accepted at millions of locations worldwide.",
        nemo_card_call_to_action: "Request yours today and take your transactions to the next level!",
        nemo_card_details_warning: "For security reasons, the card details (number and CVV) are generated dynamically and may change each time you view the details.",
        wallet_history_item: {
            Withdrawal: "Withdrawal",
            Deposit: "Deposit",
            original_label: "Original",
            fee_label: "Fee",
            total_label: "Total"
        },
        card_delivery: "Card delivery",
        delivery_method: "Delivery method",
        pick_up: "Pick it up personally",
        delivery_address: "Delivery Address",
        nemo_address: "Av. Santa Cruz 198, San Isidro, Lima",
        nemo_office_address: localConfig.countryCode === "PE" ? "Av. Santa Cruz 198, San Isidro, Lima" : "Calle 6 Sur #43A-100, Medellín, Colombia",
        user_address: {
            title: "Add home address",
            search_address_label: "Search address",
            search_address_placeholder: "Enter your address",
            street_label: "Street",
            street_placeholder: "Enter your street",
            city_label: "City",
            city_placeholder: "Enter your city",
            state_label: "State",
            state_placeholder: "Enter your state",
            zip_code_label: "Zip code",
            zip_code_placeholder: "Enter your zip code",
            country_label: "Country",
            country_placeholder: "Enter your country",
            submit: "Add address",
            address_added: "Address added successfully",
            unexpected_error: "An unexpected error occurred",
            too_many_addresses: "You have reached the maximum number of addresses",
        },
        card_request_form: {
            request_sent: "Request sent",
            virtual_card: "Virtual card",
            virtual_card_description: "Your card will appear here when approved",
            request_sent_text: "You are just a few steps away from having your Nemo Card!",
            info: "Your card request will be reviewed by our team. You can choose to pick it up or have it delivered to your address"
            ,
            title: "Create Card Request",
            select_address: "Select delivery address",
            select_card_type: "Select card type",
            select_type: "Select delivery type",
            physical: "Physical",
            virtual: "Virtual",
            pickup: "Pickup in office",
            delivery: "Delivery to address",
            submit: "Submit",
            success: "Card request created successfully",
            error: "An unexpected error occurred while creating the card request",
            created: "Waiting for approval",
            cancel_request: "Cancel request",
            disclaimer: "You will recieve an email with instructions on how to pick up your card",
            declined: "Request declined"
        },
        card_request_cancel: {
            title: "Cancel card request",
            description: "Please confirm you want to cancel your card request",
            action: "Confirm"
        },
        months: {
            1: "Jan",
            2: "Feb",
            3: "Mar",
            4: "Apr",
            5: "May",
            6: "Jun",
            7: "Jul",
            8: "Aug",
            9: "Sep",
            10: "Oct",
            11: "Nov",
            12: "Dec"
        },
        support: "Support",
        tickets: "Tickets",
        subject: "Subject",
        upload_image: "Upload image",
        optional: "Optional",
        sent: "Sent",
        support_ticket_creation_success: "Ticket created successfully",
        support_ticket_creation_error: "Failed to create the ticket",
        support_ticket_size_error: "Failed to load the image",
        ticket_new: "New",
        ticket_open: "Waiting",
        ticket_pending: "Reply Needed",
        ticket_solved: "Closed",
        close: "Close",
        support_ticket_created_modal: {
            title: "Ticket created successfully",
            info: "Your ticket has been created successfully. In a few seconds, you will be able to see it in the other section.",        
        },
        faq: "FAQ",
        faq_search_error: "Empty search",
        search: "Search",
        no_data_search: "No results found",
        no_tickets: "No tickets found",
        create_support_ticket: "Create a support ticket",
        payrolls_main_title: "Send mass transfer",
        payrolls_user_lists: "Recipient lists",
        payrolls_configure: "Configure transfer",
        payrolls_send: "Send transfer",
        payrolls_save_list: "Save list",
        payrolls_clean_list: "Clear list",
        payrolls_recipients: "Recipients",
        payrolls_valid_username: "Valid user",
        payrolls_user_list_created: "User list created",
        payrolls_transfer_success: "Transfer pending approval",
        payrolls_transfer_error: "Error making the transfer",
        payrolls_transfer_name: "Transfer",
        payrolls_form: {
            amount_placeholder: "Enter amount to send",
            new_email_placeholder: "Add recipient email",
            search_user: "Search user",
            sort_invalid: "Invalid",
            sort_amount: "Amount",
            sort_alpha: "A-Z",
            delete_user_modal: {
                title: "Delete user",
                text: "Please confirm you want to delete the user:",
                confirm: "Delete",
                cancel: "Cancel"
            },
            delete_all_users_modal: {
                title: "Clear list",
                text: "Please confirm you want to delete all users",
                confirm: "Clear",
                cancel: "Cancel"
            },
            delete_list_modal: {
                title: "Delete user list",
                text: "Please confirm you want to delete the user list",
                confirm: "Delete",
                cancel: "Cancel",
                success: "User list deleted",
                error: "Error deleting user list"
            },
            history_filters: {
                amount: "Size",
                date: "Date",
                min_amount: "Min size",
                max_amount: "Max size",
                start_date: "Start date",
                end_date: "End date",
                date_range: "Date range",
                sort_by: "Sort by",
                currency: "Currency",
                min: "Min",
                max: "Max",
                newest_first: "Newest first",
                oldest_first: "Oldest first",
                amount_high_to_low: "Amount high to low",
                amount_low_to_high: "Amount low to high",
                search_user: "Search user",
                user_email: "Enter the user email",
                clear_filters: "Clear filters",
                download_excel: "Download excel"
            },
            added_users: "Added {{new}} users",
            added_repeated_users: "{{repeated}} users are repeated, adding {{new}} users",
            added_invalid_users: "{{invalid}} users dont exist or haven't completed KYC",
            added_user: "Added user: {{user}}",
            updated_user: "Updated user {{user}}",
            deleted_user: "Deleted user: {{user}}",
            deleted_all_users: "Deleted all users",
            no_users: "Add recipients to see them here"
        },
        payrolls_summary: {
            num_users: "Number of users",
            total_amount: "Total amount",
            min_amount: "Minimum amount",
            max_amount: "Maximum amount",
            average_amount: "Average amount",
            current_balance: "Current balance",
            balance_after_transaction: "Balance after transaction",
        },
        payrolls_file_upload: {
            title: "Load users from file",
            description: "Please use the template and enter the users to transfer",
            drop_here: "Drop file here",
            download_template: "Download template",
            download_template_description: "Use this template to fill the users",
            upload_file: "Upload file",
            upload_file_description: "Accepted files: .csv, .txt, .xlsx",
            success: "File processed, adding users",
            close: "Close",
            errors: {
                invalid_file_type: "Invalid file type",
                invalid_file: "Invalid or empty file",
                parse_error: "Error processing the file",
                check_error: "Error checking the users"
            }
        },
        payrolls_edit_user_modal: {
            title: "Edit user",
            email_label: "Enter the recipient email",
            email_placeholder: "Enter email",
            submit: "Save",
            delete: "Delete",
            cancel: "Cancel"
        },
        payrolls_errors: {
            cannot_send_to_self: "You can't send a transfer to yourself",
            duplicate_user: "User is already in the list",
            invalid_username: "The user does not exist or is not KYC approved",
            error_creating_user_list: "Error creating user list",
        },
        mass_transfers_modal: {
            title: "Mass Transfers",
            select_transfer: "Select a transfer to see the detail"
        },
        create_user_list_modal: {
            title: "Save recipient list",
            name_label: "List name",
            name_placeholder: "Enter list name",
            description_label: "Description",
            description_placeholder: "Enter a description",
            num_users: "Users",
            preferred_amount_label: "Preferred amount",
            preferred_amount_placeholder: "Enter preferred amount",
            preferred_currency_label: "Preferred currency",
            preferred_currency_placeholder: "Select currency",
            submit: "Create",
            cancel: "Cancel"
        },
        send_mass_transfer_modal: {
            title: "Send mass transfer",
            name_label: "Display name",
            name_placeholder: "Enter display name",
            submit: "Send transfer",
            cancel: "Cancel"
        },
        edit_user_list_modal: {
            title: "Update recipient list",
            name_label: "List name",
            name_placeholder: "Enter list name",
            description_label: "Description",
            description_placeholder: "Enter a description",
            num_users: "Users",
            new_email_placeholder: "Add recipient email",
            invalid_username: "Invalid user",
            preferred_amount_label: "Preferred amount",
            preferred_amount_placeholder: "Enter preferred amount",
            preferred_currency_label: "Preferred currency",
            preferred_currency_placeholder: "Select currency",
            success: "User list updated",
            error_updating_user_list: "Error updating user list",
            submit: "Save",
            cancel: "Cancel"
        },
        check_username_input: {
            placeholder: "Enter username",
            valid_username: "Valid user",
            invalid_username: "Invalid user",
            cannot_send_to_self: "You can't send a transfer to yourself",
            repeated_username: "User is repeated"
        },
        creating_mass_transfer: "Creating mass transfer...",
        list_name: "List name",
        list_name_required: "List name is required",
        add_recipient: "Add recipient",
        remove_recipient: "Remove recipient",
        fill_all_required_fields: "Please fill all required fields",
        mass_transfer_created_successfully: "Mass transfer created successfully",
        error_creating_mass_transfer: "Error creating mass transfer",
        recent_mass_transfers: "History",
        loading: "Loading...",
        no_transfers_yet: "No transfers yet",
        recipients_count: "{{count}} recipients",
        status_options: {
            pending: "Pending",
            processing: "Processing",
            completed: "Completed",
            failed: "Failed"
        },
        user_list: "User list",
        create_new_list: "Create new list",
        select_existing_list: "Select existing list",
        transfer_details: "Transfer details",
        remove: "Remove",
        select_or_create_list: "Please select or create a user list",
        fill_all_emails: "Please fill all emails",
        amount_required: "Amount is required",
        no_user_lists: "No user lists yet",
        user_list_modal: {
            title: "Add recipient list",
            name_label: "Name",
            description_label: "Description",
            search_placeholder: "Search user",
            no_users: "No users found",
            total_users: "Total",
            preferred_amount: "Preferred amount",
            select_button: "Add",
            close_button: "Close"
        },
        mass_transfer_item: {
            recipients_count: "{{count}} recipients",
            total_label: "Total",
            amount_label: "Amount",
            status: {
              pending: "Pending",
              processing: "Processing",
              completed: "Completed",
              failed: "Failed",
              cancelled: "Cancelled"
            }
        },
        need_help: "Need help?",
        refer: "Invite a friend",
        referral_section: {
            title: "Invite Your Friends",
            description: "Share your invitation code with friends so they can join.",
            copy_code: "Copy referral link"
        },
        conection_slow: "Your connection seems slow, click here to try again or contact support if the problem persists"
    },
}

export default common
