import React, { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom";
import useAuthStore, { LoginCredentials } from "../../stores/AuthStore"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import toast from "react-hot-toast"
import PasswordEyeInput from "../../components/PasswordEyeInput/PasswordEyeInput";
import logo from "../../assets/logos/logo.svg"
import "./Login.css"

const Login = () => {

    const { t } = useTranslation('common')

    const nav = useNavigate()

    const { logIn, isLoading } = useAuthStore((state) => ({
        logIn: state.logIn,
        isLoading: state.isLoading
    }))

    const handleGo2Fa = () => {
        setGo2Fa(true)
        setFormData((prev) => {
            return {...prev, googlecode: ""}
        })
    }

    const [formData, setFormData] = useState<LoginCredentials>({username: "", password: ""})
    const [go2Fa, setGo2Fa] = useState(false)

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const res = await logIn(formData, handleGo2Fa)
        if(res === "email_not_verified") {
            nav(`/confirm-register/${formData.username}`)
        }
        else if(res === "otp_fail") {
            setFormData((prev) => {
                return {...prev, googlecode: ""}
            })
            toast.error(t('otp_failed'))
        }
        else if(res === "error") {
            setFormData((prev) => {
                return {...prev, googlecode: ""}
            })
            toast.error(t('error'))
        }
    }

    const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        setFormData((prev) => {
            return {...prev, [name]: value}
        })
    }

    const { isLogged } = useAuthStore((state) => ({
        isLogged: state.isLogged
    }))

    return (
        <>
            {!isLogged ? 
                <div className="login-main-cont">
{/*                     <div className="maintenance">
                        ⚠️
                        <div className="maintenance-message">
                            <div>{t("we_are")}</div>
                            <div>{t("maintenance")}</div>
                        </div>
                        ⚠️
                    </div> */}
                    <img className="not-logged-logo" src={logo} />
                    <h1 className="login-title">{t('login')}</h1>
                    <div className="login-hr"></div>
                    <form className="login-form" onSubmit={handleLogin}>
                        {go2Fa ?
                            <>
                                <span className='login-opt-instructions'>{t('put_authenticator_otp')}</span>
                                <input required value={formData?.googlecode} name="googlecode" className='login-otp-input' placeholder={t('tfa_otp_input_placeholder')} onChange={(e) => {handleChangeForm(e)}}/>
                            </>
                        :
                            <>
                                <div className="login-form-item-cont">
                                    <label className="login-input-label" htmlFor="email">{t('email')}</label>
                                    <input required={true} className="login-input" placeholder={t("email_placeholder")} name="username" onChange={(e) => {handleChangeForm(e)}} id="email" type="email" />
                                </div>
                                <div className="login-form-item-cont">
                                    <label className="login-input-label" htmlFor="password">{t('password')}</label>
                                    <PasswordEyeInput i_value={formData.password} i_placeholder='password_placeholder' i_name="password" notLogged={true} handleChangeForm={handleChangeForm} i_id="password-login" />
                                </div>
                            </>
                        }
                        <button disabled={isLoading} className="login-button">{t('login')}</button> 
                    </form>
                    <div className="login-links-cont">
                        <Link className="login-link" to="/register">{t('dont_have_account')}</Link>
                        <Link className="login-link" to="/reset-password">{t('reset_password')}</Link>
                    </div>
                </div>
                :
                <Navigate to="/" />
            }
        </>
    )
}

export default Login