const coincolors = {
    BTC: "#f7931a",
    USDT: "#26a17b",
    COP: "#ffda44",
    ETH: "#627eea",
    PEN: "#d80027",
    USD: "#5dda8f",
    TRX: "#fe070f",
}

export default coincolors