import { useTranslation } from "react-i18next"
import {
    HiCheckCircle,
    HiClock,
    HiMinusCircle,
    HiUsers,
} from "react-icons/hi"

import { useCoins } from "../../hooks/useCoins"
import coinlogos from "../../utils/coinlogos"
import { numberWithCommas, formatedDateTime } from "../../utils/functions"

import './MassTransferDetailsModal.css'

interface User {
    username: string
    email: string
    first_name: string
    last_name: string
    user_fee: number | null
    user_exchange_fee: number
}

interface MassTransferDetailsItem {
    id: number
    transfers_data: { [key: string]: any }[],
    currency: string
    status: string
    created_by: User
    created: number
    updated: number
    amount: number
    display_name: string
}

const MassTransferDetailsModal = ({ item }: { item: MassTransferDetailsItem }) => {
    const { t } = useTranslation("common")
    const { data: coinsData } = useCoins()

    const iconsStatus: Record<string, JSX.Element> = {
        'pending': <HiClock className="mass-transfer-status-icon" />,
        'processing': <HiClock className="mass-transfer-status-icon" />,
        'completed': <HiCheckCircle className="mass-transfer-status-icon" />,
        'failed': <HiMinusCircle className="mass-transfer-status-icon" />,
        'cancelled': <HiMinusCircle className="mass-transfer-status-icon" />
    }

    const displayName = item.display_name || `${t('payrolls_transfer_name')} #${item.id}`
    const decimals = coinsData?.coins[item.currency]?.decimals || 2
    const formattedTotal = numberWithCommas(`${item.amount}`, decimals)
    
    return (
        <div className="mass-transfer-container">
            {/* Header */}
            <div className="mass-transfer-top">
                <div className="mass-transfer-name">
                    {displayName}
                </div>
                <div className="mass-transfer-coin">
                    <img
                        className="mass-transfer-coin-logo"
                        src={coinlogos[item.currency as keyof typeof coinlogos]}
                        alt={item.currency}
                    />
                    <span className="mass-transfer-coin-symbol">
                        {item.currency}
                    </span>
                </div>
            </div>

            {/* Recipients Info */}
            <div className="mass-transfer-recipients">
                <div className="mass-transfer-recipients-header">
                    <HiUsers className="mass-transfer-recipients-icon" />
                    <span className="mass-transfer-recipients-count">
                        {t('mass_transfer_item.recipients_count', { count: Object.keys(item.transfers_data).length || 0 })}
                    </span>
                </div>
                <div className="mass-transfer-users-container">
                    {item?.transfers_data && Object.entries(item?.transfers_data).map(([key, value], index) => (
                        <div 
                            key={key} 
                            className={`mass-transfer-user-item ${index + 1 >= Object.keys(item.transfers_data).length ? 'last-item' : ''}`}
                        >
                            <div className="mass-transfer-user-username">
                                <div className="mass-transfer-user-index">{index + 1}.</div>
                                <div>{key}</div>
                            </div>
                            <div className="mass-transfer-user-amount">
                                {numberWithCommas(value, 2)} {item.currency}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Total Amount */}
            <div className="mass-transfer-amount-block">
                <span className="mass-transfer-amount-label">
                    {t("mass_transfer_item.total_label")}
                </span>
                <span className="mass-transfer-amount-value">
                    {formattedTotal} {item.currency}
                </span>
            </div>

            {/* Footer */}
            <div className="mass-transfer-bottom">
                <div className="mass-transfer-date-time">
                    {formatedDateTime(item.created, t, false, true)}
                </div>
                <div className={`mass-transfer-status status-${item.status}`}>
                    {iconsStatus[item.status]}
                    <span className="mass-transfer-status-text">
                        {t(`status_mapping.${item.status}`)}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default MassTransferDetailsModal
