import { formatDistanceToNow, parseISO } from "date-fns"
import { es, enUS  } from "date-fns/locale"
import type {
    TFunction,
    FlatNamespace,
    KeyPrefix,
} from 'i18next'
import toast from "react-hot-toast";
import i18n from "../i18n"

/**
 * Function to format a number with commas and decimals
 * @param x - The number to format
 * @param decimals - The number of decimals to show
 * @returns The formatted number
 */
export function numberWithCommas(x: any, decimals: number = 8, highlightEquivalence = true): string {
    if (!x || x === "null") {
        return "0";
    }
    const str = getRegularNumber(x);
    const parts = str.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let isEquivalent = false;
    if(parts.length > 1) {
        isEquivalent = decimalsCeros(parts[1]) < decimals ? false : true 
        parts[1] = parts[1].slice(0, decimals)
    } else {
        parts.push("0".repeat(decimals))
    }
    const result = decimals === 0 ? parts[0] : parts.join(".");
    return isEquivalent && highlightEquivalence ? `≈ ${result}` : result;
}

/**
 * Function to convert a number to a regular number string
 * @param n - The number to convert
 * @returns The regular number string
 */
export function getRegularNumber(n: number): string {
    let sign = n < 0 ? "-" : "";
    let toStr = n.toString();

    if (!/e/i.test(toStr)) {
        return n.toString();
    }

    let [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);

    const exponent = parseInt(pow, 10);

    return exponent < 0
        ? sign + "0." + "0".repeat(Math.max(Math.abs(exponent) - 1, 0)) + lead + decimal
        : sign + lead + (exponent >= decimal.length
            ? decimal + "0".repeat(Math.max(exponent - decimal.length, 0))
            : decimal.slice(0, exponent) + "." + decimal.slice(exponent));
}

/**
 * Function to truncate a number to a certain number of decimals
 * @param n - The number to truncate
 * @param decimals - The number of decimals to truncate to
 * @returns The truncated number
 */
export function truncateToDecimals(n: number, decimals: number) {
    return Math.trunc(n * 10**decimals) / 10**decimals
}

/**
 * Function to calculate the total balance of a user
 * @param coinsData - The data of the coins
 * @param balanceData - The data of the balance
 * @returns The total balance
 */
export function calculateTotalBalance(coinsData: any, balanceData: any) {
    let total_balance_usdt = 0 
    if(coinsData && balanceData) {
        Object.keys(coinsData.coins).forEach((coin) => {
            const c = coinsData.coins[coin]
            total_balance_usdt += (balanceData.balance[coin].actual + balanceData.balance[coin].orders) * c.price
        })
    }
    return total_balance_usdt
}

/**
 * Function to count the number of leading zeros in a string
 * @param str - The string to count the leading zeros in
 * @returns The number of leading zeros
 */
export function decimalsCeros(str : string) : number {
    const match = str.match(/^0*/);
    return match ? match[0].length : 0;
}

/**
 * Function to format a datetime string
 * @param str - The datetime string to format
 * @returns The formatted datetime string
 */
export function prettyDatetime(str: string) : string {
    const formattedDate = formatDistanceToNow(parseISO(str), { 
        addSuffix: true, 
        locale: i18n.language === 'es' ? es : enUS 
    });
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

/**
 * Function to get the first pair for an asset
 * @param pairsData - The data of the pairs
 * @param coin - The coin to get the first pair for
 * @returns The first pair for the asset
 */
export function getFirstPairForAsset(pairsData: any[], coin: string): { base: string; quote: string } | undefined {
    for (const pair of pairsData) {
      const baseCode = pair.base.code
      const quoteCode = pair.quote.code
      if (baseCode === coin) {
        return { base: coin, quote: pair.quote.code }
      } else if (quoteCode === coin) {
        return { base: coin, quote: pair.base.code }
      }
    }
    return undefined
}

/**
 * Function to format a datetime string
 * @param timestamp - The timestamp to format
 * @param alwaysShowYear - Whether to always show the year
 * @param showTime - Whether to show the time
 * @param t - The translation function
 * @returns The formatted datetime string
 */
export function formatedDateTime(
    timestamp: string | number | Date, 
    t: TFunction<FlatNamespace, KeyPrefix<FlatNamespace>>,
    alwaysShowYear: boolean = false, 
    showTime: boolean = false,  
) {
    const date = new Date(timestamp)
    const day = date.getDate()
    const month = t(`months.${date.getMonth() + 1}`)
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    // const seconds = date.getSeconds()

    return `${day} ${month} ${alwaysShowYear || year !== new Date().getFullYear() ? year : ''} ${showTime ? `${hours}:${minutes}` : ''}`
}

interface PageItem {
    id: number;
    display: boolean;
}

interface PageData {
    results: PageItem[];
}

interface UpdatePageDataResult {
    filledResults: PageItem[];
    pageResults: PageItem[] | null;
}

/**
 * Function to update the page data
 * @param pageData - The latest paginated data
 * @param previousPageResults - The previous page's results
 * @param itemsPerPage - The number of items displayed per page
 * @returns An object containing the updated page data
 */
export function updatePageData(
    pageData: PageData | null | undefined,
    previousPageResults: PageItem[] | null,
    itemsPerPage: number,
): UpdatePageDataResult {
    
    // Case 1: Data exists, fill with placeholders if needed
    if (pageData?.results) {
        const missingItems = itemsPerPage - pageData.results.length;
        const placeholders: PageItem[] = Array.from({ length: missingItems }, (_, index) => ({
            id: -(index + 1),
            display: false,
        }));

        return {
            filledResults: [...pageData.results, ...placeholders],
            pageResults: pageData.results
        };
    }

    // Case 2: Data is loading, use previous results if available
    if (previousPageResults?.length) {
        const placeholders: PageItem[] = Array.from({ length: itemsPerPage - previousPageResults.length }, (_, index) => ({
            id: -(index + 1),
            display: false,
        }));

        return {
            filledResults: [
                ...previousPageResults.map((_, index) => ({ id: index + 1, display: true })), 
                ...placeholders
            ],
            pageResults: null
        };
    }

    // Case 3: No previous data, show all placeholders
    return {
        filledResults: Array.from({ length: itemsPerPage }, (_, index) => ({
            id: index + 1,
            display: true,
        })),
        pageResults: null
    };
}
  export function copyToClipboard( value: string, success : string, error: string) {
    toast.dismiss()
    navigator.clipboard.writeText(value).then(() => {
        toast.success(success)
    }).catch((err) => {
        console.log(err)
        toast.error(error)
    })
}
