import { useTranslation } from "react-i18next"
import { useState, useRef, useEffect } from "react"
import "./InputAdvanced.css"

interface FieldProps {
    name: string,
    description: string,
    hide_label?: boolean,
}

interface InputAdvancedProps {
    field: FieldProps
    onInputChange: (data: object, value: string) => string
}

const InputAdvanced = ({ field, onInputChange} : InputAdvancedProps) => {
    const { t } = useTranslation('common')

    const inputRef = useRef<HTMLInputElement>(null);
    
    const [message, setMessage] = useState("")

    const isAddress = field.name === "address"
    
    useEffect(() => {
        if (!isAddress) return
        if (!window.google || !inputRef.current) return
        
        // Create the autocomplete instance
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
            types: ["address"],
        })

        // Attach listener for place_changed
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace()
            if (!place || !place.address_components) return            
            if (inputRef.current) {
                inputRef.current.value = place.formatted_address || ''
                const event = new Event('input', { bubbles: true })
                inputRef.current.dispatchEvent(event)                
                setMessage(onInputChange(field, inputRef.current.value))
            }
        })

        // Cleanup on unmount
        return () => {
            window.google.maps.event.clearInstanceListeners(autocomplete)
        }
    }, [isAddress, field, onInputChange])

    return (
        <div className="input-advanced-div">
            { !field.hide_label && <div className="input-advanced-label">{t(field.name) || field.description}</div>}
            <input 
                ref={isAddress ? inputRef : null}
                className="input-advanced-field"
                name={field.name}
                onChange={(e) => {
                    setMessage(onInputChange(field, e.target.value))
                }}
                placeholder={t(field.description)  || (t("insert") + t(field.name).toLowerCase())}
            />
            { message != field.name && <div className="input-advanced-field-message-disclaimer">{message}</div>}
        </div>
    )
}

export default InputAdvanced
