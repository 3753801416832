import { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useProfile } from "../../hooks/useProfile"
import { useCoins } from "../../hooks/useCoins"
import { useLimits } from "../../hooks/useLimits"
import { useBalance } from "../../hooks/useBalance"
import { useTransfer } from "../../hooks/useTransfers"
import useModalStore from "../../stores/ModalStore"
import { numberWithCommas } from "../../utils/functions"
import { HiMiniQrCode } from "react-icons/hi2"
import { GrLinkNext } from "react-icons/gr"
import { IoMdArrowRoundBack } from "react-icons/io"
import { IoIosSend } from "react-icons/io"
import { QRCode } from 'react-qrcode-logo'
import toast from "react-hot-toast"

import Spinner from "../../components/Spinner/Spinner"
import InOutHistory from "../../components/InOutHistory/InOutHistory"
import SelectAdvanced from "../../components/SelectAdvanced/SelectAdvanced"
import HiddenValueToggle from "../../components/HiddenValueToggle/HiddenValueToggle"
import ClickToCopy from "../../components/ClickToCopy/ClickToCopy"
import CheckUsernameInput from "../../components/CheckUsernameInput/CheckUsernameInput"
import QrCodeScannerModal from "../../modals/QrCodeScannerModal/QrCodeScannerModal"
import PeerToPeerSuccessModal from "../../modals/PeerToPeerSuccessModal/PeerToPeerSuccessModal"

import qrImg from "../../assets/logos/logo.svg"
import "./PeerToPeer.css"

const PeerToPeer = () => {
    const { t } = useTranslation('common')

    const amountInputRef = useRef<HTMLInputElement>(null)
    const amountWrapperRef = useRef<HTMLDivElement>(null)

    const { data: profileData } = useProfile()
    const { data: coinsData } = useCoins()
    const { data: limitsData } = useLimits()
    const { data: balanceData, isLoading: isLoadingBalance } = useBalance()
    const { mutateAsync: sendTransfer } = useTransfer()

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        }
    })

    const [selectedCoin, setSelectedCoin] = useState("USDT")
    const [selectedCoinBalance, setSelectedCoinBalance] = useState({ balance: 0, decimals: 2 })

    const [isScanning, setIsScanning] = useState(false)
    const [decodedResult, setDecodedResult] = useState("")

    const [numMenu, setNumMenu] = useState(0)
    const [isRecipientUsernameValid, setIsRecipientUsernameValid] = useState(false)
    const [error, setError] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const [exceedsMax, setExceedsMax] = useState(false)
    const [belowMin, setBelowMin] = useState(false)


    const [userQrData, setUserQrData] = useState<{ [key: string]: any }>({
        username: profileData?.user?.username || "",
        currency: selectedCoin || "USDT",
        amount: "0",
    })

    const [recipientData, setRecipientData] = useState<{ [key: string]: any }>({
        userData: {},
        username: "",
        currency: "",
        amount: "0",
    })

    const changeMenuDisabled = numMenu === 0 && (!recipientData.username || !isRecipientUsernameValid)
    const firstName = recipientData?.userData?.first_name || ""
    const lastName = recipientData?.userData?.last_name || ""
    const recipientDisplayName = (firstName.trim() || lastName.trim()) ? `${firstName} ${lastName}`.trim() : recipientData?.username || "Unknown"

    const coinsItems = () => {
        let items: {
            [key: string]: {
                name: string,
                logo?: string,
                decimals?: number,
            }
        } = {}

        Object.keys(coinsData ? coinsData.coins : {}).forEach(key => {
            items[key] = {
                name: coinsData.coins[key].name,
                logo: coinsData.coins[key].logo,
                decimals: coinsData.coins[key].decimals,
            }
        })

        return items
    }

    const maxDecimals = coinsData?.coins[selectedCoin]?.decimals || 2
    const minAmount = limitsData?.[selectedCoin]?.transfer?.min_limit || 1
    const maxAmount = limitsData?.[selectedCoin]?.transfer?.max_limit || 100
    const sendTransferEnabled = !changeMenuDisabled && !isLoading && parseFloat(recipientData.amount) >= parseFloat(minAmount.toString()) && parseFloat(recipientData.amount) <= parseFloat(maxAmount.toString()) && parseFloat(recipientData.amount) <= parseFloat(selectedCoinBalance?.balance?.toString())

    const handleQrDataChange = (key: any, value: string) => {
        setUserQrData((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    const handleRecipientDataChange = (key: any, value: string, inputData?: any) => {
        setRecipientData((prevState) => {
            const newState = {
                ...prevState,
                [key]: value,
            }
            
            // After state updates, handle cursor positioning for amount field
            if (key === "amount" && inputData && inputData.valueChanged) {
                setTimeout(() => {
                    if (amountInputRef.current) {
                        // Always move cursor to the end when typing/editing
                        const formattedValue = formatAmountWithPlaceholders(value)
                        amountInputRef.current.setSelectionRange(
                            formattedValue.length, 
                            formattedValue.length
                        )
                    }
                }, 0)
            }
            
            return newState
        })
    }

    // Handle amount change
    const handleSelectedAmountChange = (e: any) => {
        // Save cursor position and input value before the change
        const cursorPosition = e.target.selectionStart
        const currentInputValue = e.target.value
        const previousValue = recipientData?.amount || ""
        
        // Get the raw input value with commas removed
        let rawValue = currentInputValue.replace(/,/g, '')
        
        let newError = ""
      
        const dotCount = (rawValue.match(/\./g) || []).length
        if (/[^0-9.]/.test(rawValue) || dotCount > 1) {
            newError = t("peertopeer_errors.invalid_value")
            setError(newError)
            return  // Prevent invalid characters
        }
      
        // Split the number and check integer part digits only
        const [integerPart] = rawValue.split(".")
        if (integerPart.length > 10) {
            newError = t("peertopeer_errors.too_many_digits")
            setError(newError)
            return  // Prevent more than 10 digits in integer part
        }
      
        if (dotCount === 1) {
            const [_, decPart] = rawValue.split(".")
            if (decPart && decPart.length > maxDecimals) {
                newError = t("peertopeer_errors.too_much_decimals")
                setError(newError)
                return  // Prevent excessive decimals
            }
        }
      
        const numericVal = parseFloat(rawValue || "0")
        const numericMax = parseFloat(maxAmount.toString())
        const numericMin = parseFloat(minAmount.toString())
      
        // Check limits for highlighting
        if (!isNaN(numericVal) && numericVal > numericMax) {
            newError = t("peertopeer_errors.value_higher_than_max")
            setExceedsMax(true)
            setBelowMin(false)
        } 
        else if (!isNaN(numericVal) && numericVal < numericMin && numericVal > 0) {
            newError = t("peertopeer_errors.value_lower_than_min")
            setExceedsMax(false)
            setBelowMin(true)
        } 
        else {
            setExceedsMax(false)
            setBelowMin(false)
        }
    
        let [intPart, fractionPart] = rawValue.split(".")
        intPart = intPart.replace(/^0+/, "")
        if (intPart === "") {
            intPart = "0"
        }
    
        if (numericVal > parseFloat(selectedCoinBalance?.balance?.toString())) {
            newError = t("peertopeer_errors.not_enough_balance")
        }
      
        // Store the clean value (no formatting)
        const cleanValue = (fractionPart !== undefined || dotCount === 1) ? `${intPart}.${fractionPart || ""}` : intPart
        
        // Update the state with clean value and track if cursor was at the end
        const wasAtEnd = cursorPosition === currentInputValue.length
        
        // Store additional info to help with cursor positioning
        const inputData = {
            wasAtEnd,
            cursorPosition,
            inputLength: currentInputValue.length,
            valueChanged: previousValue !== cleanValue
        }
        
        setError(newError)
        handleRecipientDataChange("amount", cleanValue, inputData)  // Update value with cursor info
    }

    // Format amount with placeholders
    const formatAmountWithPlaceholders = (value: string): string => {
        if (!value) return "0"
        
        // Remove commas if present (from previous formatting)
        const cleanValue = value.replace(/,/g, '')
        
        const dotCount = (cleanValue.match(/\./g) || []).length
        if (dotCount !== 1) {
            return cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        const [rawIntPart, decPart = ""] = cleanValue.split(".")
        const intPart = rawIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        
        // Just return formatted string, no React elements
        return `${intPart}.${decPart}`
    }

    const handleCoinChange = (value: string) => {
        setSelectedCoin(value)
    }

    const handleChangeMenu = () => {
        if (changeMenuDisabled) return
        setNumMenu(numMenu === 0 ? 1 : 0)
    }

    const handleSendTransfer = async () => {
        setIsLoading(true)
        try {
            const result = await sendTransfer(recipientData)
            if (result.status === 201) {
                // NOTE this will fail if response payload changes
                const { id, amount, currency, recipient, created } = result.data
                setModalContent(<PeerToPeerSuccessModal
                    id={id}
                    to_user={recipient}
                    date={created}
                    currency={currency}
                    amount={amount}
                    decimals={coinsData?.coins[selectedCoin]?.decimals || 2}
                />)
            }
        } catch (e) {
            setError(t("peertopeer_errors.transfer_error"))
            console.error("Error sending transfer:", e)
        } finally {
            handleRecipientDataChange("username", "")
            handleRecipientDataChange("amount", "0")
            setNumMenu(0)
            setIsLoading(false)
        }

    }

    useEffect(() => {
        if (!selectedCoin) setSelectedCoin("USDT")
    }, [coinsItems])

    // Reset values if coin or balance changes
    useEffect(() => {
        if (selectedCoin) {
            const balance = balanceData
                ? balanceData.balance[selectedCoin]
                    ? balanceData.balance[selectedCoin].actual + balanceData.balance[selectedCoin].orders
                    : "0"
                : "0"
            const decimals = coinsData?.coins[selectedCoin]?.decimals || 2
            setSelectedCoinBalance({ balance: balance, decimals: decimals })
            handleRecipientDataChange("currency", selectedCoin)
            handleQrDataChange("currency", selectedCoin)
        }
        handleRecipientDataChange("amount", "0")
    }, [selectedCoin, balanceData])

    // Open scanner modal
    useEffect(() => {
        if (isScanning) {
            setModalContent(
                <QrCodeScannerModal
                    setDecodedResult={setDecodedResult}
                    handleClose={() => setIsScanning(false)}
                />
            )
        }
    }, [isScanning])

    // Handle code scan
    useEffect(() => {
        toast.dismiss()
        try {
            const parsed = JSON.parse(decodedResult)
            if (parsed && parsed.username && parsed.amount && parsed.currency) {
                if (parsed?.username === profileData?.user?.username) {
                    setError(t("peertopeer_errors.cannot_send_to_self"))
                    return
                }
                setSelectedCoin(parsed?.currency || "USDT")
                handleRecipientDataChange("username", parsed?.username || "")
                handleRecipientDataChange("amount", parsed?.amount || "0")
                setNumMenu(1)
            } else {
                toast.error(t("peertopeer_errors.invalid_qr"))
            }
        } catch (e) {
            return
        }
    }, [decodedResult])

    useEffect(() => {
        if (numMenu === 1) {
            setTimeout(() => {
                if (amountInputRef.current) amountInputRef.current.focus()
            }, 600) // Because transition takes 0.6 seconds
        }
    }, [numMenu])

    return (
        <div className="peertopeer-main-cont">
            <div className="peertopeer-left-cont">
                <div className="peertopeer-title">
                    <h1>{t("peertopeer_header")}</h1>
                </div>
                <div className="peertopeer-box">
                    <div className="peertopeer-config">
                        <div className="peertopeer-config-left">
                            <SelectAdvanced
                                value={selectedCoin}
                                items={coinsItems()}
                                image={true}
                                onChange={handleCoinChange}
                            />
                        </div>
                        <div className="peertopeer-config-right">
                            <HiddenValueToggle
                                value={`${numberWithCommas(selectedCoinBalance?.balance || 0, selectedCoinBalance?.decimals || 2)} ${selectedCoin}`}
                                label={t("show_balance")}
                                isLoading={isLoadingBalance}
                                disabled={!selectedCoin}
                            />
                        </div>
                    </div>
                    <div onClick={numMenu === 0 ? handleChangeMenu : () => { }} className={`peertopeer-publicity ${numMenu === 1 ? "publicity-clicked" : ""}`}>
                        <div className="peertopeer-publicity-title">{t('peertopeer_publicity_title')}</div>
                        <div>{t('peertopeer_publicity_description')}</div>
                    </div>
                    <div className="peertopeer-menus">
                        {isLoading ? <div className="peertopeer-spinner"><Spinner /></div> :
                            <>
                                <div className={`peertopeer-menu-initial ${numMenu === 0 ? "menu-active" : ""}`}>
                                    <div className="peertopeer-actions">
                                        <ClickToCopy value={profileData?.user?.username} />
                                        <div className="peertopeer-qr-code">
                                            <QRCode
                                                value={JSON.stringify(userQrData)}
                                                size={200}
                                                // NOTE This colors are hardcoded
                                                bgColor="#0F131A"
                                                fgColor="#8FFDFC"
                                                logoImage={qrImg}
                                                logoWidth={70}
                                                logoPadding={5}
                                                logoPaddingStyle="circle"
                                                removeQrCodeBehindLogo={true}
                                                ecLevel="Q"
                                            />
                                        </div>
                                        <div className="peertopeer-username-input-box">
                                            <div className="peertopeer-username-label">{t('peertopeer_destination')}</div>
                                            <CheckUsernameInput
                                                placeholder={t("peertopeer_username_placeholder")}
                                                errorMessage={t("peertopeer_errors.invalid_username")}
                                                email={recipientData?.username}
                                                cleanOnEnter={false}
                                                setEmail={(email: string) => handleRecipientDataChange("username", email)}
                                                setData={(data: any) => handleRecipientDataChange("userData", data)}
                                                setValid={setIsRecipientUsernameValid}
                                                onEnter={handleChangeMenu}
                                            />
                                        </div>
                                        <div className="peertopeer-bottom-buttons">
                                            <div
                                                className="peertopeer-scanqr-button"
                                                onClick={() => setIsScanning(!isScanning)}
                                            >
                                                <HiMiniQrCode className="peertopeer-qr-icon" />
                                                <div>{t("scan_qr")}</div>
                                            </div>
                                            <div
                                                className={`peertopeer-send-button ${changeMenuDisabled ? "peertopeer-send-button-disabled" : ""}`}
                                                onClick={handleChangeMenu}
                                            >
                                                <GrLinkNext/>
                                                <div>{t("next")}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className={`peertopeer-menu-final ${numMenu === 1 ? "menu-active" : ""}`}>
                                    <div className="peertopeer-actions-centered">
                                        <div className="peertopeer-userdata-title">
                                            <div className="peertopeer-userdata-label">{t('peertopeer_send_to')}</div>
                                            <div>{recipientDisplayName}</div>
                                        </div>
                                        <div className="peertopeer-userdata">
                                            <div className="peertopeer-amount-input-box">
                                                <div className="peertopeer-amount-input-wrapper" ref={amountWrapperRef}>
                                                    <input
                                                        ref={amountInputRef}
                                                        type="text"
                                                        inputMode="decimal"
                                                        pattern="[0-9]*[.,]?[0-9]*"
                                                        className="peertopeer-amount-input"
                                                        value={formatAmountWithPlaceholders(recipientData?.amount)}
                                                        onChange={handleSelectedAmountChange}
                                                        placeholder="0"
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter" && sendTransferEnabled) handleSendTransfer()
                                                        }}
                                                    />
                                                </div>
                                                <div className="peertopeer-amount-currency">{selectedCoin}</div>
                                                <div className="peertopeer-minmax">
                                                    <div className={`peertopeer-maximum ${belowMin ? 'peertopeer-below-min' : ''}`}>
                                                        {t('peertopeer_minimum_amount')}: {numberWithCommas(minAmount, coinsData?.coins?.[selectedCoin]?.decimals || 2)} {selectedCoin}
                                                    </div>
                                                    <div className={`peertopeer-maximum ${exceedsMax ? 'peertopeer-exceeds-max' : ''}`}>
                                                        {t('peertopeer_maximum_amount')}: {numberWithCommas(maxAmount, coinsData?.coins?.[selectedCoin]?.decimals || 2)} {selectedCoin}
                                                    </div>
                                                </div>
                                                <div className="peertopeer-alert-error-msg">{error}</div>
                                            </div>
                                        </div>
                                        <div className="peertopeer-bottom-buttons">
                                            <div
                                                className="peertopeer-back-button"
                                                onClick={handleChangeMenu}
                                            >
                                                <IoMdArrowRoundBack />
                                                <div>{t("peertopeer_back")}</div>
                                            </div>
                                            <div
                                                className={`peertopeer-send-button ${!sendTransferEnabled ? "peertopeer-send-button-disabled" : ""}`}
                                                onClick={handleSendTransfer}
                                            >
                                                <IoIosSend />
                                                <div>{t("peertopeer_send")}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>
            </div>
            <div className="transaction-history">
                <InOutHistory filterDefault='ALL' />
            </div>
        </div>
    )
}

export default PeerToPeer