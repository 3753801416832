import { useTranslation } from "react-i18next"
// import { usePostCard } from "../../hooks/useCards"
import { CardStatuses } from "../../utils/cards";
import CardRequestModal from "../../modals/CardRequestModal/CardRequestModal";
import useModalStore from "../../stores/ModalStore";
// import toast from "react-hot-toast";
import ligocard from "../../assets/logos/ligocard.svg"
import nemocard from "../../assets/logos/nemocard.svg"
import localConfig from "../../local_config";
import "./NemoCardLanding.css"

interface NemoCardLandingProps {
    status?: CardStatuses;
}

const NemoCardLanding: React.FC<NemoCardLandingProps> = ({ status }) => {

    const { t } = useTranslation("common");

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        }
    });

    return (
        <>
        {!status && <div className="nemo-card-request-main-cont">
            <div className="nemo-card-request-title">{t("nemo_card_request")} </div>

            <div className="nemo-card-request-text">{t("nemo_card_request_info")}</div>

            <div className="nemo-card-question-box">
                <div className="nemo-card-request-question">{t("nemo_card_question_1")}</div>

                <div className="nemo-card-request-answer">• {localConfig.countryCode == "PE" ? t("nemo_card_answer_1_a") : t("nemo_card_answer_1_c")}</div>
                <div className="nemo-card-request-answer">• {localConfig.countryCode == "PE" ? t("nemo_card_answer_1_b") : t("nemo_card_answer_1_d")}</div>

                <div className="nemo-card-request-question">{t("nemo_card_question_2")}</div>

                {/* NOTE In Perú we have Ligo which is Visa in others we will have inswitch which is Mastercard */}
                <div className="nemo-card-request-answer">• {localConfig.countryCode == "PE" ? t("nemo_card_answer_2_a") : t("nemo_card_answer_2_c")}</div>
                <div className="nemo-card-request-answer">• {t("nemo_card_answer_2_b")}</div>
            </div>

            <div className="nemo-card-request-call-to-action">{t("nemo_card_call_to_action")}</div>
    
            <div className="nemo-card-request">
                <button
                    onClick={() => setModalContent(<CardRequestModal />)}
                    className="request-card-submit-button"
                >
                    { t("request_card")}
                </button>
            </div>

            <div className="nemo-card-image">
                {/* NOTE in Perú we have ligo card */}
                <img className={`landing-card-img`} src={localConfig.countryCode == "PE" ? ligocard : nemocard} />
            </div>
        </div>
        }
        {
            status === CardStatuses.BLOCKED && 
                <div className="nemo-card-request">
                    <button
                        onClick={() => setModalContent(<CardRequestModal />)}
                        className="request-card-submit-button-large"
                    >
                    { t("request_card")}
                    </button>
                </div>
        }
        {
            status === CardStatuses.ASSIGNED && 
                <div className="nemo-card-delivery">
                    {/* TODO this will put dinamic delivery info, for now it is only pickup */}
                    <div className="nemo-card-delivery-title">{t("card_delivery")}</div>
                    <div className="nemo-card-delivery-info">
                        <div className="nemo-card-delivery-info-item">
                            <div className="nemo-card-delivery-info-item-key">
                                {t("delivery_method")}
                            </div> 
                            <div className="nemo-card-delivery-info-item-value">
                                {t("pick_up")}
                            </div> 
                        </div>
                        <div className="nemo-card-delivery-info-item">
                            <div className="nemo-card-delivery-info-item-key">
                                {t("delivery_address")}
                            </div> 
                            <div className="nemo-card-delivery-info-item-value">
                                {t("nemo_address")}
                            </div> 
                        </div>  
                    </div> 

                </div> 
        }
        </>
    )

}

export default NemoCardLanding