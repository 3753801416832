import { useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"

import useModalStore from "../../stores/ModalStore"
import Spinner from "../../components/Spinner/Spinner"
import { formatedDateTime } from "../../utils/functions"

import './SendMassTransferModal.css'

interface SendMassTransferModalProps {
    sendTransfer: (name: string) => Promise<void>
}

const SendMassTransferModal = ({ sendTransfer }: SendMassTransferModalProps) => {

    const { t } = useTranslation('common')

    const [name, setName] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const isFormValid = name.length > 0

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal
    }))

    useEffect(() => {
        inputRef.current?.focus()
        setName(`${t('payrolls_transfer_name')} ${formatedDateTime(new Date(), t, false, true)}`)
    }, [])

    const handleSubmit = async () => {
        setIsLoading(true)
        await sendTransfer(name)
        closeModal()
    }

    return (
        <div className="create-user-list-cont">
            <div className="create-user-list-title">{t('send_mass_transfer_modal.title')}</div>
            <div className="create-user-list-main-box">
                <div className="create-user-list-input-box">
                    <div className="create-user-list-input-label">{t('send_mass_transfer_modal.name_label')}</div>
                    <input
                        ref={inputRef}
                        className="create-user-list-input"
                        type="text"
                        placeholder={t('send_mass_transfer_modal.name_placeholder')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </div>
            {isLoading ? (
                <div className="create-user-list-spinner">
                    <Spinner/>
                </div>
            ) : (
                <div className="create-user-list-input-buttons">
                <button 
                    className={`create-user-list-button ${isFormValid ? '' : 'create-user-list-button-disabled'}` }
                    onClick={handleSubmit}
                >
                    {t('send_mass_transfer_modal.submit')}
                </button>
                <button 
                    className="create-user-list-button" 
                    onClick={closeModal}
                >
                    {t('send_mass_transfer_modal.cancel')}
                    </button>
            </div>
            )}
        </div>
    )
}

export default SendMassTransferModal