import { useEffect, useRef, useState } from 'react';
import snsWebSdk from '@sumsub/websdk';
import i18n from '../../i18n';
import './LaunchWebSdk.css';

interface Props {
  accessToken: string;
  getNewAccessToken: () => Promise<string>;
  onStatusCompleted?: (completed: boolean) => void; 
}

const LaunchWebSdk = ({ accessToken, getNewAccessToken, onStatusCompleted }: Props) => {
  const [, setStatusCompleted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, getNewAccessToken)
      .withConf({
        lang: i18n.language,
        uiConf: {
          customCssStr: "span.title {color: var(--text-primary) !important}"
        }
      })
      .withOptions({
        addViewportTag: false,
        adaptIframeHeight: true,
      })
      .on('idCheck.onApplicantStatusChanged', (status: any) => {
        const reviewStatus = status.reviewStatus;
        if (reviewStatus === 'completed') {
          setStatusCompleted(true);
          if (onStatusCompleted) {
            onStatusCompleted(true); 
          }
        }
      })
              .on("idCheck.onError", (error) => {
            console.log("onError", error);
        })
      .build();

    snsWebSdkInstance.launch(containerRef.current);

    return () => {
      snsWebSdkInstance.destroy();
    };
  }, [accessToken, getNewAccessToken, i18n.language, onStatusCompleted]);

  return (
    <div className="kyc-main-cont">
      <div className="sdk-container" ref={containerRef}></div>
    </div>
  );
};

export default LaunchWebSdk;