import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import axiosInstance from "../api/axiosInstance";

export enum MassTransferStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export interface User {
  email: string;
  amount: number;
  currency: string;
}

export interface UserList {
  id: number;
  name: string;
  description?: string;
  owner?: number;
  users?: User[];
  user_count?: number;
  preferred_amount?: number;
  preferred_currency?: string;
  created: string;
  updated: string;
}

export interface MassTransfer {
  id: number;
  user_list_id: number;
  user_list: UserList;
  description?: string;
  status: MassTransferStatus;
  created_at: string;
  created_by: {
    id: number;
    email: string;
  };
  amount?: number;
  currency?: string;
}

export interface CreateUserListRequest {
  name: string;
  description?: string;
  users: User[];
}

export interface CreateMassTransferRequest {
  user_list_id: number;
  description?: string;
  amount?: number;
  currency?: string;
}

interface PaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

interface PaginationParams {
  id?: number;
  limit?: number;
  offset?: number;
  search?: string;
  no_pagination?: boolean;
}


const fetchUserLists = ({ limit, offset }: PaginationParams) => {
  return axiosInstance.get<PaginatedResponse<UserList>>("/user-lists/", {
    params: { limit, offset }
  });
};

const fetchUserListUsers = ({ id, limit, offset, search, no_pagination }: PaginationParams) => {
  return axiosInstance.get<PaginatedResponse<UserList>>(`/user-lists/${id}/users/`, {
    params: { limit, offset, search, no_pagination }
  });
};

const createUserList = (userList: CreateUserListRequest) => {
  return axiosInstance.post<UserList>("/user-lists/", userList);
};

const deleteUserList = (listId: number) => {
  return axiosInstance.delete(`/user-lists/${listId}/`);
};

const fetchMassTransfers = ({ limit, offset }: PaginationParams) => {
  return axiosInstance.get<PaginatedResponse<MassTransfer>>("/mass-transfers/", {
    params: { limit, offset }
  });
};

const createMassTransfer = (transfer: CreateMassTransferRequest) => {
  return axiosInstance.post<MassTransfer>("/mass-transfers/", transfer);
};

export const useUserLists = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['user-lists', params],
    queryFn: () =>
      fetchUserLists(params).then((res) => ({
        ...res.data,
        results: res.data?.results && res.data.results.map((item: any) => ({
          ...item,
          display: true
        }))
      })),
    refetchInterval: 5000,
  });
};

export const useUserListUsers = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['user-list-users', params],
    queryFn: () =>
      fetchUserListUsers(params).then((res) => ({
        ...res.data,
        results: res.data?.results?.map((item: any) => ({ ...item })) ?? []
      })),
      enabled: !!params.id
  });
};

export const useCreateUserList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData: CreateUserListRequest) => createUserList(formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-lists'] });
    }
  });
};

export const useDeleteUserList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (listId: number) => deleteUserList(listId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-lists'] });
    }
  });
};

export const useMassTransfers = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['mass-transfers', params],
    queryFn: () =>
      fetchMassTransfers(params).then((res) => ({
        ...res.data,
        results: res.data?.results && res.data.results.map((item: any) => ({
          ...item,
          display: true
        }))
      })),
    refetchInterval: 5000,
  });
}

export const useCreateMassTransfer = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<MassTransfer>, Error, CreateMassTransferRequest>({
    mutationFn: createMassTransfer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mass-transfers'] });
    }
  });
};

export const usePayrolls = () => {
  return useQuery({
    queryKey: ['payrolls'],
    queryFn: async () => {
      const { data } = await axiosInstance.get('/payrolls/');
      return data;
    }
  });
};

export const useCreatePayroll = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<any>, Error, any>({
    mutationFn: async (newPayroll) => {
      const { data } = await axiosInstance.post('/payrolls/', newPayroll);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['payrolls'] });
    }
  });
};

export const useDeletePayroll = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, number>({
    mutationFn: async (payrollId) => {
      await axiosInstance.delete(`/payrolls/${payrollId}/`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['payrolls'] });
    }
  });
};
