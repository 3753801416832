import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
// import { AxiosResponse } from 'axios';
import axiosInstance from "../api/axiosInstance";

export enum MassTransferStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

// TODO this is wierd
export interface User {
    email: string;

    username?: string;
    first_name?: string;
    last_name?: string;
    user_fee?: any;
    user_exchange_fee?: any;

    amount?: number;
    currency?: string;
}

export interface UserList {
  id: number;
  name: string;
  description?: string;
  owner: User;
  users: User[];
  preferred_amount: string;
  preferred_currency: string;
}

interface CreateUserListRequest {
    name?: string,
    description?: string,
    preferred_currency?: string,
    preferred_amount?: string,
    user_emails?: string[]
}

interface PaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

interface PaginationParams {
  id?: number;
  limit?: number;
  offset?: number;
  search?: string;
  no_pagination?: boolean;
}

const fetchUserList = (id: number) => {
  return axiosInstance.get(`/user-lists/${id}/`)
}

const fetchUserLists = ({ limit, offset }: PaginationParams) => {
  return axiosInstance.get<PaginatedResponse<UserList>>("/user-lists/", {
    params: { limit, offset }
  })
}

const fetchUserListUsers = ({ id, limit, offset, search, no_pagination }: PaginationParams) => {
  return axiosInstance.get<PaginatedResponse<UserList>>(`/user-lists/${id}/users/`, {
    params: { limit, offset, search, no_pagination }
  })
}

const updateUserList = (listId: number, formData: CreateUserListRequest) => {
  return axiosInstance.put<UserList>(`/user-lists/${listId}/`, formData)
}

const createUserList = (formData: CreateUserListRequest) => {
  return axiosInstance.post<UserList>("/user-lists/", formData)
}

const deleteUserList = (listId: number) => {
  return axiosInstance.delete(`/user-lists/${listId}/`)
}

export const useUserList = (id: number) => {
  return useQuery({
    queryKey: ['user-list', id],
    queryFn: () => fetchUserList(id).then((res) => res.data),
  })
}

export const useUserLists = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['user-lists', params],
    queryFn: () =>
      fetchUserLists(params)
      .then((res) => {
        return {
          ...res.data,
          results: res.data?.results?.map((item: any) => ({
            ...item,
            display: true,
          })) ?? [],
        }
      })
      .catch((_) => {
        return { results: [], count: 0, next: '', previous: '' }
      }),
    refetchInterval: 5000,
  })
}

export const useUserListUsers = (params: PaginationParams) => {
  return useQuery({
    queryKey: ['user-list-users', params],
    queryFn: () =>
      fetchUserListUsers(params).then((res) => ({
        ...res.data,
        results: res.data?.results?.map((item: any) => ({ ...item })) ?? []
      })),
      enabled: !!params.id,
  })
}

export const useUpdateUserList = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ listId, formData } : { listId: number, formData: CreateUserListRequest }) => updateUserList(listId, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-lists'] })
    }
  })
}

export const useCreateUserList = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (formData: CreateUserListRequest) => createUserList(formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-lists'] })
    }
  })
}

export const useDeleteUserList = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (listId: number) => deleteUserList(listId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-lists'] })
    }
  })
}
