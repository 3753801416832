import { useState } from "react";
import { useTranslation } from "react-i18next"
import { useDeletePaymentAccount } from "../../hooks/usePaymentAccounts";
import useModalStore from "../../stores/ModalStore"
import "./PaymentAccountInfoModal.css"
import toast from "react-hot-toast";
import Spinner from "../../components/Spinner/Spinner";

type Props = any

const PaymentAccountInfoModal = ({fields}: Props) => {
  const { t } = useTranslation('common')
  const { mutateAsync: deletePaymentAccount } = useDeletePaymentAccount();

  const [ isLoading, setIsLoading] = useState(false)

  const { closeModal } = useModalStore((state) => {
    return {
        closeModal: state.closeModal,
    }
  })

  const handlePaymentAccountDelete = async () => {
    try{
      setIsLoading(true)
      await deletePaymentAccount(fields.id);
      setIsLoading(false)
      closeModal()
      toast.dismiss();
      toast.success("Cuenta de pago eliminada exitosamente")
    }
    catch (e) {
      setIsLoading(false)
      console.log(e)
      toast.error(t("unexpected_error"))

    }
  }
  return (
    <div className="payment-account-info-modal">
      <div className="payment-account-info-title">{t("payment_account_info_modal.title")}</div>
      <div className="payment-account-info-modal-items-cont">
        {Object.entries(fields?.fields?.fields?.data || fields?.fields || {})?.map(([key, value]) => (
          typeof value === 'string' && (
            <div className="payment-account-info-modal-item" key={key}>
              <div className="payment-account-info-modal-item-title">{t(key)}</div>
              <div className="payment-account-info-modal-item-value">{t(value)}</div>
            </div>
          )
        ))}
      </div>
        {
          isLoading ? <Spinner /> :  
          <div className="payment-account-delete-button-div">
            <button className="payment-account-go-back-action-button" onClick={closeModal}>
              {t("back")}
            </button>
            <button className="payment-account-delete-action-button" onClick={handlePaymentAccountDelete}>
              {t("delete")}
            </button>
          </div>
        }
    </div>
  )
}

export default PaymentAccountInfoModal