import { useExchange } from "../../hooks/useExchange"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import { numberWithCommas } from "../../utils/functions"
import { useWindowSize } from "@react-hook/window-size"
import { useCoins } from "../../hooks/useCoins";
import Spinner from "../Spinner/Spinner";
import Paginator from "../Paginator/Paginator"
import "./TradeHistory.css"


const TradeHistory = () => {

    const { t } = useTranslation('common')

    const itemsPerPage = 5
    
    const [page, setPage] = useState(0)

    const [width] = useWindowSize()
    
    const params: any = {
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        ordering: '-updated',
    };
    
    const { data: tradeHistory, isLoading } = useExchange(params)
    const totalPagesNum = Math.ceil(tradeHistory?.count / itemsPerPage) || 1;
    const {data:coinsData} = useCoins()

    useEffect(() => {
        setPage(0)
    }, [])
    
    return (
        <div className="th-cont">
            <h1 className="th-title">{t("trade_history")}</h1>
            <div className="th-body">

                <div className="th-container">
                    <div className="th-titles-row">
                        <div style={{justifyContent: "flex-start"}} className="title-th">{t("date")}</div>
                        <div className="title-th-center">
                            <span className="th-give-coin-amount">{t('gave')}</span>
                            <span className="th-item-divider">|</span>
                            <span className="th-get-coin-amount">{t('got')}</span>
                        </div>
                        {width > 500 && <div style={{justifyContent: "flex-end"}} className="title-th">{t("price")}</div>}
                    </div>
                    <div className="apidata-th-cont">
                        {tradeHistory?.results.length > 0 ? (
                            tradeHistory.results.map((item: any, index: number) => (
                                <div                                
                                    key={item.id}
                                    className={`inlet-apidata-th ${index !== tradeHistory.results.length - 1 ? "inlet-apidata-th-border" : ""}`}>
                                    <div className="th-main-column-date">
                                        <div className="">
                                            {new Date(item.order.updated).toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: false,
                                            })}
                                        </div>
                                        <div className="">
                                            {new Date(item.order.updated).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "2-digit",
                                            })}
                                        </div>
                                    </div>
                                    <div style={{justifyContent: "center", flex: "3"}} className="th-main-column">
                                        <span className="th-give-coin-amount">{`${numberWithCommas(item.quantity)} ${item.base_currency}`}</span> <span className="th-item-divider">|</span> <span className="th-get-coin-amount">{`${numberWithCommas(item.cost,coinsData?.coins[item.quote_currency]?.decimals)} ${item.quote_currency}`}</span>
                                    </div>
                                    {width > 500 && <div style={{justifyContent: "flex-end"}} className="th-main-column">
                                        <span>{numberWithCommas(item.order.price)}</span>
                                    </div>}
                                </div>
                            ))
                        ) : (
                            <div className="th-no-items">
                                { isLoading === true ?   <Spinner /> : <div className="nomovdiv">{t('nomovements')}</div> }
                            </div>
                        )}
                    </div>
                </div>

                <Paginator
                    page={page}
                    setPage={setPage}
                    totalPagesNum={totalPagesNum}
                />
            </div>
        </div>
    )
}

export default TradeHistory
