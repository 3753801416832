import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCoins } from "../../hooks/useCoins"
import { useDetectClickOutside } from 'react-detect-click-outside'
import { FaChevronDown } from "react-icons/fa6"
import coinlogos from "../../utils/coinlogos"
import "./CoinFilterSelector.css"

interface CoinsProp {
    selectedCoin: string,
    handleChangeCoin: (coin: string) => void,
    allLabel?: boolean
}

// TODO use this in Converter

const CoinFilterSelector = ({ 
    selectedCoin, 
    handleChangeCoin,
    allLabel = true
} : CoinsProp) => {

    const { t } = useTranslation('common')

    const { data: coinsData } = useCoins()
    
    const [dropdownOpen, setDropdownOpen] = useState(false)
    
    const closeDropdown = () => {
        setDropdownOpen(false)
    }
    
    const handleToggleSelector = () => {
        setDropdownOpen((prev) => !prev)
    }

    const allcoins = coinsData ? ["", ...Object.keys(coinsData.coins)] : []

    const filteredCoins = coinsData ? allcoins.filter((coin) => {
        return coin !== selectedCoin
    }) : []

    const dropdownref = useDetectClickOutside({ onTriggered: closeDropdown })

    const coinsDropdownElems = filteredCoins.map((coin, i) => {
        return (
            <div 
                key={coin} 
                onClick={() => {handleChangeCoin(coin); closeDropdown()}}
                className={`filter-coin-selector-dropdown-item ${filteredCoins.length - 1 === i ? "" : "filter-coin-selector-dropdown-item-last"}`}
            >
                <img className="filter-coin-selector-logo" src={coin === "" ? coinlogos.ALL : coinlogos[coin as keyof typeof coinlogos]}/>
                {coin !== "" ? coin : allLabel ? "ALL" : t('asset')}   
            </div>
        )
    })

    return (
        <div 
            ref={dropdownref} 
            className={`filter-coin-selector-cont ${dropdownOpen ? "filter-coin-selector-cont-open" : ""}`}
            onClick={handleToggleSelector}
        >
            <img className="filter-coin-selector-logo" src={selectedCoin === "" ? coinlogos.ALL : coinlogos[selectedCoin as keyof typeof coinlogos]} alt={selectedCoin} />
            <span>{selectedCoin !== "" ? selectedCoin : allLabel ? "ALL" : t('asset')}</span>
            <FaChevronDown className="filter-coin-selector-chevron"/>
            {dropdownOpen && <div onClick={(e) => e.stopPropagation()} className="filter-coin-selector-dropdown-cont">
                {coinsDropdownElems}
            </div>}
        </div>
    )

}

export default CoinFilterSelector