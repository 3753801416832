import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdPeople } from "react-icons/io"
import { FaTrash } from "react-icons/fa"
import { FaCirclePlus } from "react-icons/fa6"
import toast from 'react-hot-toast'

import useModalStore from '../../stores/ModalStore'
import SelectAdvanced from '../../components/SelectAdvanced/SelectAdvanced'
import CheckUsernameInput from '../../components/CheckUsernameInput/CheckUsernameInput';
import Spinner from '../../components/Spinner/Spinner'
import { useCoins } from '../../hooks/useCoins'
import { useUserList, useUpdateUserList } from '../../hooks/useUserLists'

import './EditUserListModal.css'

interface EditUserListPayload {
    name: string,
    description: string,
    preferred_currency: string,
    preferred_amount: string,
    user_emails: string[]
}

interface EditUserListModalProps {
    id: number;
}

const EditUserListModal = ({ id }: EditUserListModalProps) => {
    const { t } = useTranslation('common')

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }))

    const { data: coinsData } = useCoins()
    const { data: userList, isLoading: isLoadingUserList } = useUserList(id)
    const { mutateAsync: updateUserList } = useUpdateUserList()

    const [isFormValid, setIsFormValid] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [isNewEmailValid, setIsNewEmailValid] = useState(false)
    const [formData, setFormData] = useState<EditUserListPayload>({
        name: "",
        description: "",
        preferred_currency: "",
        preferred_amount: "",
        user_emails: [],
    })

    const coinsItems = () => {
        let items: {
            [key: string]: {
                name: string,
                logo?: string,
                decimals?: number,
            }
        } = {}
    
        Object.keys(coinsData ? coinsData.coins : {}).forEach(key => {
            items[key] = {
                name: coinsData.coins[key].name,
                logo: coinsData.coins[key].logo,
                decimals: coinsData.coins[key].decimals,
            }
        })
    
        return items
    }

    const handleFormDataChange = (key: string, value: string) => {
        setFormData(prev => ({ ...prev, [key]: value }))
    }

    const handleCoinChange = (value: string) => {
        handleFormDataChange('preferred_currency', value)
    }

    const handleDeleteUser = (index: number) => {
        if (formData.user_emails.length > 1) {
            setFormData(prev => {
                return {
                    ...prev,
                    user_emails: prev.user_emails.filter((_, i) => i !== index)
                }
            })
        }
    }

    const handleAddNewEmail = () => {
        setFormData(prev => {
            return {
                ...prev,
                users: [...prev.user_emails, newEmail]
            }
        })
        setNewEmail("")
        setIsNewEmailValid(false)
    }

    const handleSubmit = async () => {
        setError("")
        setIsLoading(true)
        try {
            const result = await updateUserList({ listId: id, formData })
            if (result.status === 200) {
                toast.success(t("edit_user_list_modal.success"))
                setError("")
                setIsLoading(false)
                closeModal()
            } else {
                setError(t("edit_user_list_modal.error_updating_user_list"))
            }
        } catch (error) {
            setError(t("edit_user_list_modal.error_updating_user_list"))
            console.error("Error creating user list:", error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (userList) {
            setFormData({
                name: userList?.name || "",
                description: userList?.description || "",
                preferred_currency: userList?.preferred_currency || "",
                preferred_amount: userList?.preferred_amount || "",
                user_emails: userList?.users?.length > 0 ? userList.users.map((user: any) => user.email) : [],
            })
        }
    }, [userList])

    useEffect(() => {
        if (formData.name && formData.preferred_amount) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formData])

    useEffect(() => {
        if (error) toast.error(error)
    }, [error])

    return (
        <div className="edit-user-list-cont">
            <div className="edit-user-list-title">{t('edit_user_list_modal.title')}</div>
            {isLoadingUserList ? (
                <div className="edit-user-list-spinner">
                    <Spinner/>
                </div>
            ) : (
            <>
                <div className="edit-user-list-main-box">
                    <div className="edit-user-list-input-box">
                        <div className="edit-user-list-input-label">{t('edit_user_list_modal.name_label')}</div>
                        <input
                            className="edit-user-list-input"
                            type="text"
                            placeholder={t('edit_user_list_modal.name_placeholder')}
                            value={formData.name}
                            onChange={(e) => handleFormDataChange('name', e.target.value)}
                        />
                    </div>
                    <div className="edit-user-list-input-box">
                        <div className="edit-user-list-input-label">{t('edit_user_list_modal.description_label')}</div>
                        <input
                            className="edit-user-list-input"
                            type="text"
                            placeholder={t('edit_user_list_modal.description_placeholder')}
                            value={formData.description}
                            onChange={(e) => handleFormDataChange('description', e.target.value)}
                        />
                    </div>
                    <div className="edit-user-list-input-box">
                        <div className="edit-user-list-input-label">{t('edit_user_list_modal.preferred_amount_label')}</div>
                        <div className="edit-user-list-amount-input-box">
                            <input
                                className="edit-user-list-input"
                                type="text"
                                placeholder={t('edit_user_list_modal.preferred_amount_placeholder')}
                                value={formData.preferred_amount}
                                onChange={(e) => handleFormDataChange('preferred_amount', e.target.value)}
                            />
                            <SelectAdvanced
                                value={formData.preferred_currency}
                                items={coinsItems()}
                                image={true}
                                onChange={handleCoinChange}
                            />
                        </div>
                    </div>
                </div>
                {/* Users */}
                <div className="edit-user-list-users-container">
                    {formData.user_emails.map((user: any, index: number) => (
                        <div key={index} className={`edit-user-list-user-item ${index + 1 >= (userList?.users?.length || 0) ? 'last-username' : ''}`}>
                            <div className="edit-user-list-user-username">
                                <div className="userame-index">{index + 1}</div>
                                <div>{user}</div>
                                {formData.user_emails.length > 1 && <FaTrash 
                                    className="edit-user-list-user-trash"
                                    onClick={() => handleDeleteUser(index)}
                                />}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="edit-user-list-email-input-box">
                    <CheckUsernameInput
                        placeholder={t("edit_user_list_modal.new_email_placeholder")}
                        errorMessage={t("edit_user_list_modal.invalid_username")}
                        allEmails={formData.user_emails}
                        email={newEmail}
                        setEmail={setNewEmail}
                        setValid={setIsNewEmailValid}
                        onEnter={handleAddNewEmail}
                    />
                    <div 
                        className={`edit-user-list-add-user-button ${!isNewEmailValid ? 'edit-user-list-button-disabled' : ''}`}
                        onClick={isNewEmailValid ? handleAddNewEmail : undefined}
                    >
                        <FaCirclePlus 
                            className="edit-user-list-add-user-button-icon"
                            size={30}
                        />
                    </div>
                </div>
                <div className="edit-user-list-n-users">
                    <IoMdPeople />  
                    <span>{formData.user_emails.length}</span>   
                    <span>{t('edit_user_list_modal.num_users')}</span>
                </div>
            </>
            )}

            {isLoading ? (
                <div className="edit-user-list-spinner">
                    <Spinner/>
                </div>
            ) : (
                <div className="edit-user-list-input-buttons">
                    <button 
                        className={`edit-user-list-button ${isFormValid ? '' : 'edit-user-list-button-disabled'}` }
                        onClick={handleSubmit}
                    >
                        {t('edit_user_list_modal.submit')}
                    </button>
                    <button 
                        className="edit-user-list-button" 
                        onClick={closeModal}
                    >
                        {t('edit_user_list_modal.cancel')}
                        </button>
                </div>
            )}
        </div>
    )
}

export default EditUserListModal