import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowNarrowUp, HiOutlineArrowNarrowDown, HiCheckCircle, HiCash, HiQuestionMarkCircle, HiClock, HiMinusCircle } from "react-icons/hi";
import { FaCreditCard } from "react-icons/fa6";
import { RiArrowUpDownLine } from "react-icons/ri";

import { useCoins } from "../../hooks/useCoins";
import { useHistory } from "../../hooks/useWalletHistory";
import { updatePageData, formatedDateTime } from "../../utils/functions";
import { numberWithCommas } from "../../utils/functions";
import coinlogos from "../../utils/coinlogos";
import localConfig from "../../local_config";

import CoinFilterSelector from "../CoinFilterSelector/CoinFilterSelector";
import WalletHistoryModal from "../../modals/WalletHistoryModal/WalletHistoryModal";
import useModalStore from "../../stores/ModalStore";
import Skeleton from "../Skeleton/Skeleton";
import Paginator from "../Paginator/Paginator";

import "./InOutHistory.css";

type FilterOptions = 'ALL' | 'positive' | 'negative' | 'card';

interface InOutHistoryProps {
    filterDefault: FilterOptions;
}

const InOutHistory = ({ filterDefault }: InOutHistoryProps) => {
    const { t } = useTranslation('common');

    const [page, setPage] = useState(0);
    const itemsPerPage = 8;

    const [filter, setFilter] = useState(filterDefault);
    const [selectedCoin, setSelectedCoin] = useState('ALL');

    const { data: coinsData } = useCoins();

    // Status keys can be found in WalletHistoryModal.tsx

    const iconsStatus: Record<number, JSX.Element> = {
        0: <HiClock size={18} className="wallet-history-status-icon" />,
        1: <HiClock size={18} className="wallet-history-status-icon" />,
        2: <HiCheckCircle size={18} className="wallet-history-status-icon" />,
        3: <HiMinusCircle size={18} className="wallet-history-status-icon" />,
        4: <HiMinusCircle size={18} className="wallet-history-status-icon" />,
        5: <HiQuestionMarkCircle size={18} className="wallet-history-status-icon" />,
        6: <HiClock size={18} className="wallet-history-status-icon" />,
        7: <HiCash size={18} className="wallet-history-status-icon" />,
        8: <HiClock size={18} className="wallet-history-status-icon" />,
    };

    const handleChangeCoin = (coin: string) => {
        setSelectedCoin(coin);
    };

    const params: any = {
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        ordering: '-created',
    };

    if (filter === 'positive') {
        params.operation_type = 1;
        params.is_card_tx = false;
    } else if (filter === 'negative') {
        params.operation_type = 2;
        params.is_card_tx = false;
    } else if (filter === 'card') {
        params.blockchain_currency = localConfig.countryCode == "PE" ? 'ligo-card' : 'inswitch-card';
    }

    if (selectedCoin !== 'ALL') {
        params.currency = selectedCoin;
    }

    // const isLoading = true;
    const { data: walletHistory, isLoading } = useHistory(params);
    const [oldWalletHistoryResults, setOldWalletHistoryResults] = useState<any[] | null>([]);
    const [transactionList, setTransactionList] = useState<any[]>(Array.from({ length: 8 }, (_, index) => ({ id: index + 1, display: true })));
    const [totalPagesNum, setTotalPagesNum] = useState(1);

    useEffect(() => {
        if (walletHistory?.count || !isLoading) {
            setTotalPagesNum(walletHistory?.count ? Math.ceil(walletHistory.count / itemsPerPage) : 1)
        }
    }, [walletHistory]);

    useEffect(() => {
        const { filledResults: walletHistoryFilledResults, pageResults: walletHistoryPageResults } = updatePageData(walletHistory, oldWalletHistoryResults, itemsPerPage);
        setTransactionList(walletHistoryFilledResults);
        setOldWalletHistoryResults(walletHistoryPageResults);
    }, [walletHistory]);

    useEffect(() => {
        setPage(0);
    }, [filter, selectedCoin]);

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        };
    });   
    
    return (
        <div className="movements-cont">
            <h1>{t("movements")}</h1>
            <div className="movements-body">
                {/* Filters */}
                <div className="movements-filters">
                    <div className="movements-icons">
                        <div className="movements-icon" onClick={() => setFilter('ALL')}>
                            <RiArrowUpDownLine 
                                size={22}
                                className={`${filter === 'ALL' ? 'movements-icon-active' : 'movements-icon-inactive'}`}
                            />
                        </div>
                        <div className="movements-icon" onClick={() => setFilter('positive')}>
                            <HiOutlineArrowNarrowDown 
                                size={22}
                                className={`${filter === 'positive' ? 'movements-icon-active' : 'movements-icon-inactive'}`}
                            />
                        </div>
                        <div className="movements-icon" onClick={() => setFilter('negative')}>
                            <HiOutlineArrowNarrowUp 
                                size={22} 
                                className={`${filter === 'negative' ? 'movements-icon-active' : 'movements-icon-inactive'}`}
                            />
                        </div>
                        <div className="movements-icon" onClick={() => setFilter('card')}>
                            <FaCreditCard 
                                size={22} 
                                className={`${filter === 'card' ? 'movements-icon-active' : 'movements-icon-inactive'}`}
                            />
                        </div>
                    </div>
                    <div className="">
                        <CoinFilterSelector selectedCoin={selectedCoin} handleChangeCoin={handleChangeCoin} />
                    </div>
                </div>

                <div className="movements-list">
                    {walletHistory?.results?.length === 0 && <div className="overlay-text">{t('nomovements')}</div>}
                    {transactionList.map((item: any, index: number) => (
                        // NOTE: We use the 'display' property to hide the element and 'isLoading' to show a skeleton
                        <div
                            key={index}
                            className={`movement-card ${isLoading ? 'skeleton-card' : ''} ${item?.display ? '' : 'skeleton-card hidden'}`}
                            onClick={() => {if (item.display && !isLoading) setModalContent(<WalletHistoryModal item={item} />)}}
                        >
                            {/* Name and date */}
                            <div className={`movement-info ${isLoading ? 'skeleton-parent' : ''} ${item?.display ? '' : 'skeleton-parent'}`}>
                                <Skeleton className='movement-name' minHeight={'16px'} maxWidth={'70%'} animated={isLoading}>
                                    {!isLoading && (item?.display_name ? item?.display_name : item?.operation_type === "Withdrawal" ? t('withdrawal_noun') : item?.operation_type === "Deposit" ? t('deposit_noun') : t('unknown'))}
                                </Skeleton>
                                <Skeleton className='movement-date' minHeight={'16px'} maxWidth={'40%'} animated={isLoading}>
                                    {!isLoading && item?.created && formatedDateTime(item?.created, t)}
                                </Skeleton>
                            </div>

                            {/* Amount and status */}
                            <div className="movement-right-wrapper">
                                {/* Amount and coin */}
                                <Skeleton className="movement-amount" minHeight={'16px'} minWidth={'40%'} animated={isLoading}>
                                    {!isLoading && <>
                                    {item?.currency && <img className="movement-coin-logo" src={coinlogos[item?.currency as keyof typeof coinlogos]} alt={item?.currency} />}
                                    <div className={`amount-number ${item?.amount > 0 ? "positive" : "negative"}`}>
                                        {item?.amount && numberWithCommas(`${item?.amount}`, coinsData?.coins[item?.currency]?.decimals || 2)}
                                    </div>
                                    </>}
                                </Skeleton>
                                {/* Status */}
                                <Skeleton className={`movement-status status-${item?.state}`} minHeight={'16px'} width={item?.state ? 16 : '20%'} animated={isLoading}>
                                    {!isLoading && typeof item?.state === "number" ? iconsStatus[item?.state as keyof typeof iconsStatus] : null}
                                </Skeleton>
                            </div>
                        </div>
                    ))}
                </div>
                
                {/* Paginator */}
                <Paginator 
                    totalPagesNum={totalPagesNum}
                    page={page} 
                    setPage={setPage} 
                />
            </div>
        </div>
    );
};

export default InOutHistory;