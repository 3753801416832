import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaSearch } from "react-icons/fa"
import { IoMdPeople } from "react-icons/io"

import { useUserListUsers } from "../../hooks/usePayrolls"
import Spinner from "../../components/Spinner/Spinner"
import useModalStore from "../../stores/ModalStore"

import "./UserListModal.css"

interface Owner {
  username: string
  first_name: string
  last_name: string
  user_fee: number | null
  user_exchange_fee: number
}

interface User {
  username: string
  first_name: string
  last_name: string
  user_fee: number | null
  user_exchange_fee: number
}

interface UserList {
  id: number
  name: string
  description: string
  owner: Owner
  preferred_amount: number,
  preferred_currency: string,
  created: string
  updated: string
  user_count: number
}

interface UserListModalProps {
  userList: UserList
  onSelect: (listId: number) => void
}

const PAGE_SIZE = 5

const UserListModal = ({ userList, onSelect }: UserListModalProps) => {

  const { t } = useTranslation()

  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const searchInputRef = useRef<HTMLInputElement>(null)
  const usersContainerRef = useRef<HTMLDivElement>(null)

  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string | undefined>(undefined)

  const [fetchedUsers, setFetchedUsers] = useState<User[]>([])
  const [isScrolledDown, setIsScrolledDown] = useState(false)
  const [offset, setOffset] = useState(0)

  const { data, isLoading } = useUserListUsers({
    id: userList.id,
    limit: PAGE_SIZE,
    offset: offset,
    search: debouncedSearchTerm,
  })

  const { closeModal } = useModalStore((state) => ({
    closeModal: state.closeModal,
  }))

  const handleScroll = () => {
    if (!usersContainerRef.current || isScrolledDown) return
    if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current)

    const scrollTop = usersContainerRef.current?.scrollTop || 0
    const scrollHeight = usersContainerRef.current?.scrollHeight || 160
    const clientHeight = usersContainerRef.current?.clientHeight || 160

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (isLoading) return

      const alreadyFetched = offset + PAGE_SIZE
      const totalCount = data?.count || 0

      if (alreadyFetched < totalCount) {
        setIsScrolledDown(true)
        scrollTimeoutRef.current = setTimeout(() => {
          // This triggers refetch
          setOffset(alreadyFetched)
        }, 200)
      }
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    // This will then trigger useEffect to update debounced search term
  }

  const handleSelectList = () => {
    onSelect(userList.id)
    closeModal()
  }
  
  useEffect(() => {
    if (searchTerm) {
      const handler = setTimeout(() => {
        setFetchedUsers([])
        setIsScrolledDown(true)
        // This triggers refetch
        setOffset(0)
        setDebouncedSearchTerm(searchTerm.trim())
      }, 500)

      return () => clearTimeout(handler)
    } else {
      // When erasing the search
      setDebouncedSearchTerm(undefined)
    }
  }, [searchTerm])

  // Handle data update
  useEffect(() => {
    if (data?.results) {
      if (offset === 0) {
        setFetchedUsers(data.results)
        setIsScrolledDown(false)
      } else {
        setFetchedUsers((prev) => [...prev, ...data.results])
        setIsScrolledDown(false)
      }
    }
  }, [data])

  useEffect(() => {
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }
    }
  }, [])

  return (
    <div className="user-list-modal-cont">
      <div className="user-list-modal-title">{t("user_list_modal.title")}</div>

      <div className="user-list-document-info">
        <div className="label-value-row">
          <div className="label">{t("user_list_modal.name_label")}:</div>
          <div className="value">{userList?.name}</div>
        </div>
        {userList?.description && (
          <div className="label-value-row">
            <div className="label">
              {t("user_list_modal.description_label")}:
            </div>
            <div className="value">{userList?.description}</div>
          </div>
        )}
      </div>

      {/* Search input */}
      <div className="search-bar-field">
        <input
          ref={searchInputRef}
          type="text"
          className="search-bar-field-input"
          placeholder={t("user_list_modal.search_placeholder")}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <FaSearch className="search-bar-icon" />
      </div>
      
      {/* Users list */}
      <div
        className="user-list-users-container"
        ref={usersContainerRef}
        onScroll={handleScroll}
      >
        {fetchedUsers.length > 0 ? (
          fetchedUsers.map((user, index) => (
            <div key={index} className={`user-list-user-item ${index + 1 >= (data?.count || 0) ? 'last-username' : ''}`}>
              <div className="user-list-user-username">
                {debouncedSearchTerm === undefined ? <div className="userame-index">{index + 1}</div> : null}
                <div>{user.username}</div>
              </div>
            </div>
          ))
        ) : !isLoading && !isScrolledDown ? (
          <div className="user-list-no-users">
            {t("user_list_modal.no_users")}
          </div>
        ) : null}
        
        <div className={offset + PAGE_SIZE < (data?.count || 0) ? 'user-list-spinner-container' : ''}>
          {isLoading || isScrolledDown ? <Spinner /> : null}
        </div>
      </div>

      {/* Bottom part */}
      <div className="user-list-modal-bottom">
        <div className="user-list-modal-bottom-part">
          <div className="user-list-bottom-text">
            <div>{t('user_list_modal.total_users')}:</div> 
            <div>{userList?.user_count}</div>
            <IoMdPeople />
          </div>
          <div className="user-list-bottom-text">
            <div>{t('user_list_modal.preferred_amount')}:</div> 
            <div>{userList?.preferred_amount} {userList?.preferred_currency}</div>
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="user-list-modal-action-buttons">
        <div className="user-list-modal-button" onClick={handleSelectList}>
          {t("user_list_modal.select_button")}
        </div>
        <div className="user-list-modal-button" onClick={closeModal}>
          {t("user_list_modal.close_button")}
        </div>
      </div>
    </div>
  )
}

export default UserListModal