import { FaSortUp, FaSortDown } from 'react-icons/fa'
import './SortDropdown.css'

/**
 * Props for the SortDropdown component.
 */
interface SortDropdownProps {
    /** The name of the sorting criteria (e.g., 'price', 'date'). */
    sortName?: string
    /** Function to handle sorting when the dropdown is clicked. */
    handleSort?: (sortType: string) => void
    /** Label to display next to the sorting icons. */
    label: string
    /** Sorting direction: 'up' (ascending), 'down' (descending), or 'none'. */
    direction?: 'up' | 'down' | 'none'
    /** Style variant for the dropdown: 'pill' or 'default'. */
    variant?: 'pill' | 'default'
}

/**
 * A dropdown component that allows users to sort data in ascending or descending order.
 * Displays an active icon based on the current sorting direction.
 */
const SortDropdown = ({ 
    sortName = "", 
    handleSort = () => {}, 
    label,
    direction = 'none',
    variant = 'default'
}: SortDropdownProps) => {
    return (
        <div 
            onClick={() => {handleSort(sortName)}} 
            className={`sort-dropdown-default ${variant === 'pill' ? 'sort-dropdown-pill' : ''}`}
        >
            <label className="sort-dropdown-label">{label}</label>
            <div className="sort-dropdown-icons-cont">
                <FaSortUp className={`sort-dropdown-icon sort-dropdown-topicon ${direction === 'up' ? 'sort-dropdown-icon-active' : ''}`}/>
                <FaSortDown className={`sort-dropdown-icon ${direction === 'down' ? 'sort-dropdown-icon-active' : ''}`}/>
            </div>
        </div>
    )
}

export default SortDropdown
