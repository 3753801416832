import { useProfile } from "../../hooks/useProfile";
import { useAddresses, useDeleteAddress } from "../../hooks/useAddresses";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ProfileItem from "../../components/ProfileItem/ProfileItem";
import useModalStore from "../../stores/ModalStore";
import ChangePasswordModal from "../../modals/ChangePasswordModal/ChangePasswordModal";
import AddAddressModal from "../../modals/AddAddressModal/AddAddressModal";
import ReferralCode from "../../components/ReferralCode/ReferralCode";
import TwoFa from "../../components/TwoFa/TwoFa";
import "./Profile.css";
import Spinner from "../../components/Spinner/Spinner";


const Profile = () => {
    const { t } = useTranslation('common')
    
    const { data: profileData } = useProfile();
    const { data: addresses, isLoading: addressesLoading } = useAddresses();
    const { mutateAsync: deleteAddress } = useDeleteAddress();

    const profileFields = profileData ? [
        {title: "first_name", content: profileData.user.first_name},
        {title: "last_name", content: profileData.user.last_name},
        {title: "email", content: profileData.user.username},
        {title: "country", content: profileData.country.name},
        {title: "birthday", content: new Date(profileData.birthday).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        })},
    ] : []

    const profileFieldsElems = profileFields.map((profile_item) => {
        return <ProfileItem key={profile_item.title} title={profile_item.title} content={profile_item.content} line={true} />
    });

    const { setModalContent } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
        }
    });

    return (
        <>
            <div className="profile-main-cont">
                {/* Left side */}
                <div className="profile-main-sidebox">
                    {/* My profile */}
                    <div className="profile-title">
                        <h1>{t("profile_header")}</h1>
                    </div>
                    <div className="profile-box">
                        <div className="content-profile-cont">
                            <div className="profile-hello-title">{t('hello_nickname')} {profileData.user.first_name}</div>
                            {profileFieldsElems}
                            <div className="profile-refer" onClick={ () => setModalContent(<ReferralCode referralCode={profileData.referral_code}/>)}>{t("refer")}</div>
                        </div>
                    </div>
                </div>
                {/* Right side */}
                <div className="profile-main-sidebox">
                    {/* Security */}
                    <div className="profile-title">
                        <h1>{t("security")}</h1>
                    </div>
                    <div className="profile-box profile-box-security">
                        <div className="content-profile-cont">
                            <div className="profile-hello-title">{t('change_password_label')}</div>
                            <div className='profile-hello-label'>
                                <span>{t('change_password')}</span>
                                <button onClick={() => {setModalContent(<ChangePasswordModal/>)}} className='change-password-button'>{t('change_password_button')}</button>
                            </div>
                            <div className="line"></div>
                            <div className="profile-hello-title">{t('two_fa')}</div>
                            <TwoFa />
                        </div>
                    </div>

                    {/* Addresses */}
                    <div className="profile-title">
                        <h1>{t("addresses")}</h1>
                    </div>
                    <div className="profile-box">
                        <div className="content-profile-cont">
                            {addressesLoading && <Spinner/>}
                            {addresses?.length > 0 && addresses.map((address: any, index: any) => (
                            <div className={`address-row ${index !== addresses.length - 1 && 'address-row-line'}`} key={address.id}>
                                <span className="address-text">
                                    {`${address.street}, ${address.city}, ${address.state || "N/A"}, ${address.zip_code || "N/A"}, ${address.country}`}
                                </span>
                                <div className="delete-button" onClick={() => deleteAddress(address.id)}>
                                    <FaTrash />
                                </div>
                            </div>
                            ))}
                            <div className="add-address-button" onClick={() => {setModalContent(<AddAddressModal/>)}}>
                                {t('add_address_button')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )       
}

export default Profile