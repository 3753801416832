
import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./InputTextAdvanced.css";

interface FieldProps {
    name: string,
    description: string,
    height: string,
    label?: boolean,
}

interface InputTextAdvancedProps {
    field: FieldProps;
    onInputChange: (data: object, value: string) => string;
}

const InputTextAdvanced = ({ field, onInputChange} : InputTextAdvancedProps) => {
    const { t } = useTranslation('common')
    
    const [message, setMessage] = useState("")
    
    return (
        <div className="input-text-advanced-div">
            {field.label && <div className="input-text-advanced-label">{t(field.name) || field.description}</div>}
            <textarea 
                className="input-text-advanced-field"
                name={field.name}
                onChange={(e) => {
                    setMessage(onInputChange(field, e.target.value));
                }}
                style={{height: field.height}}
                placeholder={t("insert") + (t(field.name) || field.description).toLowerCase()}
            />
            <div className="input-text-advanced-field-message-disclaimer">{message}</div>
        </div>
    )
}

export default InputTextAdvanced;
