import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { LuEye, LuEyeOff } from "react-icons/lu"
import "./PasswordEyeInput.css"

interface PasswordCondition {
    label: string,
    regex: RegExp   
}

interface InputFields {
    i_value: string,
    i_placeholder: string,
    i_name: string,
    i_id: string,
    handleChangeForm: (e: React.ChangeEvent<HTMLInputElement>) => void,
    hasHinting?: boolean
    notLogged?: boolean
    hintingConditions?: PasswordCondition [],
    nofloat?: boolean
}

const PasswordEyeInput = ({i_value, i_placeholder, i_name, handleChangeForm, i_id, hasHinting=false, notLogged=false, hintingConditions=[], nofloat}: InputFields) => {

    const { t } = useTranslation('common')

    const [showPass, setShowPass] = useState(false)

    const [showHints, setShowHints] = useState(false);
    
    const handleTogglePass = () => {
        setShowPass((prev) => !prev)
    }

    const handleFocus = () => {
        if(!hasHinting) {
            return
        }
        setShowHints(true)
    }

    const handleBlur = () => {
        if(!hasHinting) {
            return
        }
        setShowHints(false)
    }

    const passwordHintElems = hintingConditions.map((cond: PasswordCondition) => {
        const regexsuccess = cond.regex.test(i_value)
        return (<div key={cond.label} className={regexsuccess ? "fulfilled" : "unfulfilled" }>
            • {cond.label}
        </div>)
    })

    return (
        <div className="password-eye-cont">
            <div className="input-eye-cont">
                <input 
                    id={i_id}
                    value={i_value} 
                    onFocus={handleFocus} 
                    onBlur={handleBlur} 
                    required={true} 
                    type={showPass ? "text" : "password"} 
                    className={`psw-advanced-field ${notLogged ? "psw-advanced-field-notlogged" : "psw-advanced-field-logged"}`}
                    placeholder={(t(i_placeholder))} 
                    name={i_name} 
                    onChange={(e) => {handleChangeForm(e)}} 
                />
                { showPass ? <LuEyeOff className="show-pass-icon" onClick={handleTogglePass} /> : <LuEye className="show-pass-icon" onClick={handleTogglePass} />}
            </div>
            {(hasHinting && showHints) && 
                <div className={`password-hinting-popup ${nofloat ? "password-hinting-popup-nofloat" : ""}`}>
                    {passwordHintElems}
                </div>
            }
        </div>
    )
}

export default PasswordEyeInput