import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { CgAttachment } from "react-icons/cg";
import { RxCross1 } from "react-icons/rx";

import "./UploadFiles.css"

interface UploadFilesProps {
    onFilesChange: (files: File[]) => void;
}

const UploadFiles = ({ onFilesChange }: UploadFilesProps) => {
    const { t } = useTranslation('common')
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [files, setFiles] = useState<File[]>([])


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
    
        const newFiles = Array.from(event.target.files);
        const totalSize = newFiles.reduce((acc, file) => acc + file.size, 0) + 
                          files.reduce((acc, file) => acc + file.size, 0);
    
        if (totalSize > 4194304) {
            toast.error(t("support_ticket_size_error"));
            return;
        }
        setFiles(prevData => ([...prevData, ...newFiles]));
        event.target.value = '';
    };

    const handleAddFiles = () => {
        fileInputRef.current?.click();
    };

    const handleRemoveFile = (index: number) => {
        setFiles(prevData => (prevData.filter((_, i) => i !== index)));
    };

    useEffect(() => {
        if (onFilesChange) {
          onFilesChange(files);
        }
      }, [files]);

    return (
        <div className="upload-files-evidence-section">
                <div className="upload-files-images" onClick={handleAddFiles}>
                    <p>{t("upload_image")} ({t("optional")})</p>  
                    <CgAttachment style={{marginLeft: "5px"}}/> 
                </div>
                <input
                    type="file"
                    accept=".pdf, image/*"
                    multiple
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
                {files.length != 0 && <div className="upload-files-evidence-div" >
                    {files.map((file, index) => (
                        <div className="upload-files-evidence-li" key={index}>
                            {file.name}
                            <div className="upload-files-evidence-cross">
                                <RxCross1 type="button" onClick={() => handleRemoveFile(index)} style={{ marginLeft: "10px", color: "#EE4747", cursor: "pointer" }} />
                            </div>
                        </div>
                    ))}
                </div>
                }
        </div>
    )
}

export default UploadFiles