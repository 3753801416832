import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import useModalStore from "../../stores/ModalStore";
import "./GenericModal.css";

interface GenericModalProps {
  data: {
    title?: string;
    info?: string;
    description?: string;
    link?: string;
    action?: string;
    action_url?: string;
  };
  /**
   * If onConfirm is provided, clicking the action button
   * will trigger onConfirm and show a spinner while it resolves.
   * When done, the modal will close automatically.
   */
  onConfirm?: () => any;
}

const GenericModal: React.FC<GenericModalProps> = ({ data, onConfirm }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }));

    const handleNavigateClick = () => {
        if(data.action_url)
        {
            navigate(data?.action_url)
            return 
        }
        closeModal()
    };

    const handleConfirmClick = async () => {
        setIsLoading(true);
        try {
            if (onConfirm) {
                await onConfirm();
            }
        } finally {
            setIsLoading(false);
            closeModal();
        }
    };

    return (
        <div className="generic-modal-container">
            {/* Title */}
            {data?.title && (
                <h3 className="generic-modal-title">{data.title}</h3>
            )}
            
            {/* Info */}
            {data?.info && <p>{data.info}</p>}

            {/* Description */}
            {data?.description && <p>{data.description}</p>}

            {/* Link */}
            {data?.link && (
                <a
                href={data.link}
                target="_blank"
                rel="noreferrer"
                className="generic-modal-link"
                >
                {t("click_here")}
                </a>
            )}

            {/* Action */}
            {data?.action && (
                <div className="generic-modal-button-section">
                    {onConfirm ? (
                        isLoading ? (
                        <Spinner />
                        ) : (
                        <button
                            type="button"
                            className="generic-modal-button"
                            onClick={handleConfirmClick}
                            disabled={isLoading}
                        >
                            {data.action}
                        </button>
                        )
                    ) : (
                        <button
                        type="button"
                        className="generic-modal-button"
                        onClick={handleNavigateClick}
                        >
                        {data.action}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default GenericModal;
