import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { HiCheckCircle, HiClock, HiMinusCircle, HiUsers } from "react-icons/hi"
import { FaFilter, FaFileExcel } from "react-icons/fa"
import { FaCalendarDays } from "react-icons/fa6"
import * as ExcelJS from 'exceljs'

import { formatedDateTime, numberWithCommas, updatePageData } from "../../utils/functions"
import { useCoins } from "../../hooks/useCoins"
import { useMassTransfers, useExcelDownloader } from "../../hooks/useTransfers"

import coinlogos from "../../utils/coinlogos"
import Spinner from "../../components/Spinner/Spinner"
import CoinFilterSelector from "../../components/CoinFilterSelector/CoinFilterSelector"
import CheckUsernameInput from "../../components/CheckUsernameInput/CheckUsernameInput"
import Skeleton from "../../components/Skeleton/Skeleton"
import Paginator from "../../components/Paginator/Paginator"

import './FilterMassTransfersModal.css'

interface TransferData {
    id: number
    display_name?: string
    currency: string
    amount: number
    status: string
    created: string
    transfers_data: Record<string, string>
    display?: boolean
}

type TransferDataPartial = Partial<TransferData> & { id: number, display?: boolean }

const FilterMassTransfersModal = () => {

    const { t } = useTranslation('common')

    const { data: coinsData } = useCoins()
    const { downloadExcel, isDownloading } = useExcelDownloader()
    const [isDownloadingIndividual, setIsDownloadingIndividual] = useState(false)

    const itemPerMassTransferPage = 5
    const [massTransferPage, setMassTransferPage] = useState(0)
    const [massTransferTotalPages, setMassTransferTotalPages] = useState(1)
    const [oldMassTransfersDataResults, setOldMassTransfersDataResults] = useState<any[] | null>(null)
    const [massTransfers, setMassTransfers] = useState<TransferDataPartial[]>(Array.from({ length: itemPerMassTransferPage }, (_, index) => ({ id: index + 1, display: true })))
    const [selectedTransfer, setSelectedTransfer] = useState<TransferData | null>(null)

    // Filters
    const [sortOption, setSortOption] = useState<string>("-created")
    const [transferSelectedCoin, setTransferSelectedCoin] = useState("")
    const [minAmount, setMinAmount] = useState("")
    const [maxAmount, setMaxAmount] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [searchUser, setSearchUser] = useState("")

    const getOrderingParam = () => {
        if (sortOption) {
            return sortOption
        }
        return "-created"
    }

    const getStatusIcon = (status: string) => {
        switch(status) {
            case 'completed':
                return <HiCheckCircle size={18} className="wallet-history-status-icon" />
            case 'pending':
                return <HiClock size={18} className="wallet-history-status-icon" />
            case 'processing':
                return <HiClock size={18} className="wallet-history-status-icon" />
            case 'failed':
                return <HiMinusCircle size={18} className="wallet-history-status-icon" />
            case 'cancelled':
                return <HiMinusCircle size={18} className="wallet-history-status-icon" />
            default:
                return null
        }
    }

    // Status icons for the details panel
    const detailsStatusIcon = (status: string) => {
        switch(status) {
            case 'pending':
                return <HiClock className="filter-mass-transfers-modal-details-status-icon" />
            case 'processing':
                return <HiClock className="filter-mass-transfers-modal-details-status-icon" />
            case 'completed':
                return <HiCheckCircle className="filter-mass-transfers-modal-details-status-icon" />
            case 'failed':
                return <HiMinusCircle className="filter-mass-transfers-modal-details-status-icon" />
            case 'cancelled':
                return <HiMinusCircle className="filter-mass-transfers-modal-details-status-icon" />
            default:
                return null
        }
    }

    const handleClearFilters = () => {
        setSortOption("-created")
        setTransferSelectedCoin("")
        setMinAmount("")
        setMaxAmount("")
        setStartDate("")
        setEndDate("")
        setSearchUser("")
    }

    const handleDownloadExcel = () => {
        downloadExcel({
            ordering: getOrderingParam(),
            currency: transferSelectedCoin !== "" ? transferSelectedCoin : undefined,
            min_amount: minAmount !== "" ? minAmount : undefined,
            max_amount: maxAmount !== "" ? maxAmount : undefined,
            start_date: startDate !== "" ? startDate : undefined,
            end_date: endDate !== "" ? endDate : undefined,
            recipient_email: searchUser !== "" ? searchUser : undefined
        })
    }

    const handleIndividualTransferExcel = () => {
        if (!selectedTransfer) return        
        setIsDownloadingIndividual(true)
        
        try {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Transfer Details')
            
            // Add headers
            worksheet.columns = [
                { header: 'Emails', key: 'user', width: 30 },
                { header: 'Monto', key: 'amount', width: 15 },
                { header: 'Moneda', key: 'currency', width: 10 }
            ]
            
            // Style the header row
            worksheet.getRow(1).font = { bold: true }            
            if (selectedTransfer.transfers_data) {
                Object.entries(selectedTransfer.transfers_data).forEach(([user, amount]) => {
                    worksheet.addRow({
                        user,
                        amount: numberWithCommas(amount as string, coinsData?.coins[selectedTransfer.currency]?.decimals || 2, false),
                        currency: selectedTransfer.currency,
                    })
                })
            }
            
            // Add a summary row
            // worksheet.addRow({})
            // worksheet.addRow({
            //     user: 'Total',
            //     amount: numberWithCommas(`${selectedTransfer.amount}`, coinsData?.coins[selectedTransfer.currency]?.decimals || 2, false),
            //     currency: selectedTransfer.currency
            // })
            
            // Generate the Excel file
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                const filename = `Transfer_${selectedTransfer.id}_${selectedTransfer.currency}_${new Date().toISOString().split('T')[0]}.xlsx`                
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement('a')
                anchor.href = url
                anchor.download = filename
                anchor.rel = 'noopener'                
                document.body.appendChild(anchor)
                anchor.click()
                
                // Clean up
                setTimeout(() => {
                    document.body.removeChild(anchor)
                    window.URL.revokeObjectURL(url)
                }, 100)
            })
        } catch (error) {
            console.error('Error generating individual transfer Excel file:', error)
        } finally {
            setIsDownloadingIndividual(false)
        }
    }

    const handleSelectTransfer = (transfer: TransferDataPartial) => {
        if (transfer.display && !isLoadingMassTransfers) {
            setSelectedTransfer(transfer as TransferData)
        }
    }

    const { data: massTransfersData, isLoading: isLoadingMassTransfers } = useMassTransfers({
        limit: itemPerMassTransferPage,
        offset: massTransferPage * itemPerMassTransferPage,
        ordering: getOrderingParam(),
        currency: transferSelectedCoin !== "" ? transferSelectedCoin : undefined,
        min_amount: minAmount !== "" ? minAmount : undefined,
        max_amount: maxAmount !== "" ? maxAmount : undefined,
        start_date: startDate !== "" ? startDate : undefined,
        end_date: endDate !== "" ? endDate : undefined,
        recipient_email: searchUser !== "" ? searchUser : undefined
    })

    useEffect(() => {
        if (massTransfersData?.count || !isLoadingMassTransfers) {
            setMassTransferTotalPages(massTransfersData?.count ? Math.ceil(massTransfersData.count / itemPerMassTransferPage) : 1)
        }
    }, [massTransfersData, isLoadingMassTransfers])

    useEffect(() => {
        const { filledResults: massTransfersFilledResults, pageResults: massTransfersPageResults } = updatePageData(massTransfersData, oldMassTransfersDataResults, itemPerMassTransferPage)
        setMassTransfers(massTransfersFilledResults)
        setOldMassTransfersDataResults(massTransfersPageResults)
        
        // If there are results and no selected transfer, select the first one
        if (massTransfersData?.results && massTransfersData.results.length > 0 && !selectedTransfer && !isLoadingMassTransfers) {
            setSelectedTransfer(massTransfersData.results[0])
        } else if (massTransfersData?.results && massTransfersData.results.length === 0) {
            setSelectedTransfer(null)
        }
    }, [massTransfersData, isLoadingMassTransfers, selectedTransfer])

    return (
        <div className="filter-mass-transfers-modal">
            {/* Left side */}
            <div className="filter-mass-transfers-modal-left">
                <div className="filter-mass-transfers-modal-title">{t('mass_transfers_modal.title')}</div>
                <div className="filter-mass-transfers-modal-list-content">
                    <div className="filter-mass-transfers-modal-filters-container">
                        {/* Order and coin */}
                        <div className="filter-mass-transfers-modal-filters-row">
                            {/* Order by dropdown */}
                            <div className="filter-mass-transfers-modal-input-group">
                                <label className="filter-mass-transfers-modal-label">{t('payrolls_form.history_filters.sort_by')}</label>
                                <select 
                                    className="filter-mass-transfers-modal-sort-select"
                                    value={sortOption}
                                    onChange={(e) => setSortOption(e.target.value)}
                                >
                                    <option value="-created">{t('payrolls_form.history_filters.newest_first')}</option>
                                    <option value="created">{t('payrolls_form.history_filters.oldest_first')}</option>
                                    <option value="-amount">{t('payrolls_form.history_filters.amount_high_to_low')}</option>
                                    <option value="amount">{t('payrolls_form.history_filters.amount_low_to_high')}</option>
                                </select>
                            </div>

                            {/* Coin selector */}
                            <div className="filter-mass-transfers-modal-input-group">
                                <label className="filter-mass-transfers-modal-label">{t('payrolls_form.history_filters.currency')}</label>
                                <CoinFilterSelector 
                                    selectedCoin={transferSelectedCoin} 
                                    handleChangeCoin={setTransferSelectedCoin}
                                    allLabel={false}
                                />
                            </div>
                        </div>
                        {/* Amount and date ranges */}
                        <div className="filter-mass-transfers-modal-filters-row">
                            {/* Amount range selector */}
                            <div className="filter-mass-transfers-modal-input-group">
                                <label className="filter-mass-transfers-modal-label">{t('payrolls_form.history_filters.amount')}</label>
                                <div className="filter-mass-transfers-modal-interval-input-box">
                                    <input
                                        className="filter-mass-transfers-modal-interval-input"
                                        value={minAmount}
                                        onChange={(e) => setMinAmount(e.target.value)}
                                        type="number"
                                        placeholder={t('payrolls_form.history_filters.min')}
                                    />
                                    <span className="filter-mass-transfers-modal-interval-separator">-</span>
                                    <input
                                        className="filter-mass-transfers-modal-interval-input"
                                        value={maxAmount}
                                        onChange={(e) => setMaxAmount(e.target.value)}
                                        type="number"
                                        placeholder={t('payrolls_form.history_filters.max')}
                                    />
                                </div>
                            </div>

                            {/* Date range selector */}
                            <div className="filter-mass-transfers-modal-input-group">
                                <label className="filter-mass-transfers-modal-label">{t('payrolls_form.history_filters.date_range')}</label>
                                <div className="filter-mass-transfers-modal-interval-input-box">
                                    <div className="filter-mass-transfers-modal-date-input-wrapper">
                                        <input
                                            className="filter-mass-transfers-modal-interval-input filter-mass-transfers-modal-date-input"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            type="date"
                                            placeholder={t('payrolls_form.history_filters.start_date')}
                                        />
                                        <FaCalendarDays className="filter-mass-transfers-modal-calendar-icon" />
                                    </div>
                                    <span className="filter-mass-transfers-modal-interval-separator">-</span>
                                    <div className="filter-mass-transfers-modal-date-input-wrapper">
                                        <input
                                            className="filter-mass-transfers-modal-interval-input filter-mass-transfers-modal-date-input"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            type="date"
                                            placeholder={t('payrolls_form.history_filters.end_date')}
                                        />
                                        <FaCalendarDays className="filter-mass-transfers-modal-calendar-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-mass-transfers-modal-filters-row">
                            {/* User search and buttons */}
                            <div className="filter-mass-transfers-modal-input-group">
                                <label className="filter-mass-transfers-modal-label">{t('payrolls_form.history_filters.search_user')}</label>
                                <CheckUsernameInput
                                    email={searchUser}
                                    setEmail={setSearchUser}
                                    placeholder={t('payrolls_form.history_filters.user_email')}
                                />
                            </div>
                            <div className="filter-mass-transfers-modal-input-group">
                                <div className="filter-mass-transfers-modal-buttons">
                                    <div
                                        onClick={handleClearFilters}
                                        className="filter-mass-transfers-modal-button dark"
                                    >
                                        <FaFilter/>
                                        <span>{t('payrolls_form.history_filters.clear_filters')}</span>
                                    </div>
                                    <div
                                        onClick={isDownloading ? undefined : handleDownloadExcel}
                                        className="filter-mass-transfers-modal-button"
                                    >
                                        {isDownloading ? (
                                            <Spinner sizeMultiplier={0.5}/>
                                        ) : (<>
                                            <FaFileExcel />
                                            <span>{t('payrolls_form.history_filters.download_excel')}</span>
                                        </>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="filter-mass-transfers-modal-list">
                        {massTransfersData?.results && massTransfersData.results.length === 0 && <div className="filter-mass-transfers-modal-overlay-text">{t('nomasstransfers')}</div>}
                        {massTransfers.map((transfer) => (
                        <div
                            key={transfer.id}
                            className={`filter-mass-transfers-modal-transfer-item with-actions ${isLoadingMassTransfers ? 'skeleton-card' : ''} ${transfer?.display ? '' : 'skeleton-card hidden'} ${selectedTransfer && selectedTransfer.id === transfer.id ? 'selected' : ''}`}
                            onClick={() => handleSelectTransfer(transfer)}
                        >
                            {/* Name and date */}
                            <div className="transfer-content">
                                <div className='transfer-header'>
                                    <Skeleton className='transfer-name' minHeight="18px" minWidth="50%" animated={isLoadingMassTransfers}>
                                        {!isLoadingMassTransfers && (transfer?.display_name || `${t('payrolls_transfer_name')} #${transfer?.id}`)}
                                    </Skeleton>
                                </div>
                                <div className='transfer-footer'>
                                    <Skeleton className='transfer-users' minHeight="18px" minWidth="40%" animated={isLoadingMassTransfers}>
                                        {!isLoadingMassTransfers && transfer?.created && formatedDateTime(transfer.created, t, false, true)}
                                    </Skeleton>
                                </div>
                            </div>
        
                            {/* Amount and status */}
                            <div className="movement-right-wrapper">
                                {/* Amount */}
                                <Skeleton className="movement-amount" minHeight={'18px'} minWidth={'40%'} animated={isLoadingMassTransfers}>
                                    {!isLoadingMassTransfers && transfer?.currency && <>
                                    <img className="movement-coin-logo" src={coinlogos[transfer.currency as keyof typeof coinlogos]} alt={transfer.currency} />
                                    <span className="amount-number">
                                        -{transfer?.amount && numberWithCommas(`${transfer?.amount}`, coinsData?.coins[transfer.currency]?.decimals || 2)}
                                    </span>
                                    </>}
                                </Skeleton>
                                {/* Status */}
                                <Skeleton className={`movement-status status-${transfer?.status}`} minHeight={'18px'} width={transfer?.status ? 16 : '20%'} animated={isLoadingMassTransfers}>
                                    {!isLoadingMassTransfers && transfer?.status && getStatusIcon(transfer.status)}
                                </Skeleton>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
                <div className="filter-mass-transfers-modal-paginator">
                    <Paginator
                        page={massTransferPage}
                        setPage={setMassTransferPage}
                        totalPagesNum={massTransferTotalPages}
                    />
                </div>
            </div>

            {/* Right side */}
            <div className="filter-mass-transfers-modal-right">
                {!selectedTransfer || isLoadingMassTransfers ? (
                    <div className="filter-mass-transfers-modal-no-selection">
                        <div className="filter-mass-transfers-modal-no-selection-text">
                            {isLoadingMassTransfers ? <Spinner/> : t('mass_transfers_modal.select_transfer')}
                        </div>
                    </div>
                ) : (
                    <div className="filter-mass-transfers-modal-details-container">
                        {/* Header */}
                        <div className="filter-mass-transfers-modal-details-header">
                            <div className="filter-mass-transfers-modal-details-name">
                                {selectedTransfer.display_name || `${t('payrolls_transfer_name')} #${selectedTransfer.id}`}
                            </div>
                            <div className="filter-mass-transfers-modal-details-coin">
                                <img
                                    className="filter-mass-transfers-modal-details-coin-logo"
                                    src={coinlogos[selectedTransfer.currency as keyof typeof coinlogos]}
                                    alt={selectedTransfer.currency}
                                />
                                <span className="filter-mass-transfers-modal-details-coin-symbol">
                                    {selectedTransfer.currency}
                                </span>
                            </div>
                        </div>

                        {/* Recipients Info */}
                        <div className="filter-mass-transfers-modal-details-recipients">
                            <div className="filter-mass-transfers-modal-details-recipients-header">
                                <HiUsers className="filter-mass-transfers-modal-details-recipients-icon" />
                                <span className="filter-mass-transfers-modal-details-recipients-count">
                                    {t('mass_transfer_item.recipients_count', { count: Object.keys(selectedTransfer.transfers_data || {}).length || 0 })}
                                </span>
                            </div>
                            <div className="filter-mass-transfers-modal-details-users-container">
                                {selectedTransfer?.transfers_data && Object.entries(selectedTransfer.transfers_data).map(([key, value], index) => (
                                    <div 
                                        key={key} 
                                        className={`filter-mass-transfers-modal-details-user-item ${index + 1 >= (Object.keys(selectedTransfer.transfers_data || {}).length || 0) ? 'last-item' : ''}`}
                                    >
                                        <div className="filter-mass-transfers-modal-details-user-username">
                                            <div className="filter-mass-transfers-modal-details-user-index">{index + 1}</div>
                                            <div className="filter-mass-transfers-modal-details-user-name">{key}</div>
                                        </div>
                                        <div className="filter-mass-transfers-modal-details-user-amount">
                                            {numberWithCommas(value as string, coinsData?.coins[selectedTransfer.currency]?.decimals || 2, false)} {selectedTransfer.currency}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Total Amount */}
                        <div className="filter-mass-transfers-modal-details-amount-block">
                            <span className="filter-mass-transfers-modal-details-amount-label">
                                {t("mass_transfer_item.total_label")}
                            </span>
                            <span className="filter-mass-transfers-modal-details-amount-value">
                                {numberWithCommas(`${selectedTransfer.amount}`, coinsData?.coins[selectedTransfer.currency]?.decimals || 2, false)} {selectedTransfer.currency}
                            </span>
                        </div>
                        
                        <div className="filter-mass-transfers-modal-details-excel-download">
                            <div
                                onClick={isDownloadingIndividual ? undefined : handleIndividualTransferExcel}
                                className="filter-mass-transfers-modal-button"
                            >
                                {isDownloadingIndividual ? (
                                    <Spinner sizeMultiplier={0.5}/>
                                ) : (<>
                                    <FaFileExcel />
                                    <span>{t('payrolls_form.history_filters.download_excel')}</span>
                                </>)}
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="filter-mass-transfers-modal-details-footer">
                            <div className="filter-mass-transfers-modal-details-date-time">
                                {formatedDateTime(selectedTransfer.created, t, false, true)}
                            </div>
                            <div className={`filter-mass-transfers-modal-details-status status-${selectedTransfer.status}`}>
                                {detailsStatusIcon(selectedTransfer.status)}
                                <span className="filter-mass-transfers-modal-details-status-text">
                                    {t(`status_mapping.${selectedTransfer.status}`)}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FilterMassTransfersModal