import { useParams, useNavigate } from "react-router-dom"
import "./Event.css"
import { useEffect, useState } from "react"
import axiosInstance from "../../api/axiosInstance"
import useAuthStore from "../../stores/AuthStore"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import Spinner from "../../components/Spinner/Spinner"
import EventoLogo   from "../../assets/logos/check list (2).png"

const Event = () => {
    const { eventCode } = useParams()
    const navigate = useNavigate()
    const [event, setEvent] = useState<{ name: string, event_start_date: number, event_end_date: number, event_code: string } | null>(null)
    const [isRegistered, setIsRegistered] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [isRegistering, setIsRegistering] = useState(false)
    const [showLoginPrompt, setShowLoginPrompt] = useState(false)

    const { t } = useTranslation('common')
    
    const { isLogged } = useAuthStore((state) => ({
        isLogged: state.isLogged
    }))

    useEffect(() => {
        if (eventCode) {
            localStorage.setItem("eventCode", eventCode)
        }
    }, [eventCode])

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await axiosInstance.get(`/event/${eventCode}/`)
                setEvent(response.data)
                setLoading(false)
            } catch (err) {
                setError("Failed to load event details")
                navigate('/')
                toast.success(t('not_available'))
                setLoading(false)
            }
        }
        fetchEvent()
    }, [eventCode])

    // This function checks if today is before the end date

    const isWithinInterval = (startDate: number, endDate: number): boolean => {
        const currentDate = new Date().getTime(); // Get current timestamp
        return currentDate >= startDate && currentDate <= endDate;
    };

    const handleRegister = async () => {
        setIsRegistering(true)
        try {
            await axiosInstance.post(`/event/${eventCode}/register/`)
            setIsRegistered(true)
            setError(null)
        } catch (err) {
            console.error("Registration failed:", err)
            toast.success(t("success_registration_raffle"))
            setTimeout(() => {
                navigate('/') 
            }, 4000)
            localStorage.removeItem("eventCode") 
        } finally {
            setIsRegistering(false)
        }
    }


    useEffect(() => {
        const attemptAutoRegistration = async () => {
            if (event && isWithinInterval(event.event_start_date,event.event_end_date)) {
                if (isLogged && !isRegistered && !isRegistering) {
                    await handleRegister()
                } else if (!isLogged) {
                    setShowLoginPrompt(true)
                }
            }
        }
        
        attemptAutoRegistration()
    }, [event, isLogged, isRegistered])

    const redirectToLogin = () => navigate('/login')
    const redirectToRegister = () => navigate('/register')


    if (loading) {
        return <div className="event-main-cont"><Spinner /></div>
    }

    return (
        <div className="event-main-cont">
            
            {event && (
                !isWithinInterval(event.event_start_date,event.event_end_date) ? (
                    
                    <h1> La promoción no está disponible! Consulta los horarios </h1>
                ) : (
                    <div className="event-registration-section">
                        {isRegistered ? (
                            toast.success("Ya te registraste en este evento!")
                        ) : (
                            <>
                                {isLogged ? (
                                    <div className="event-registration-status">
                                        {isRegistering ? (
                                            <Spinner />
                                        ) : error && (
                                            toast.success(error)
                                        )}
                                    </div>
                                ) : (
                                    showLoginPrompt && (
                                        <div className="event-auth-prompt">
                                            <img src= {EventoLogo}/>
                                            <h1 className="event-tittle-text">{t('login_or_register_to_enter_raffle')}</h1>
                                            <div className="event-auth-buttons">
                                                <button onClick={redirectToLogin} className= "event-login-button">{t('login')}</button>
                                                <button onClick={redirectToRegister} className= "event-login-button">{t('register')}</button>
                                            </div>
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    </div>
                )
            )}
        </div>
    )
}

export default Event
