import { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"
import CoinSelector from "../../components/CoinSelector/CoinSelector"
import BalanceSummary from "../../components/BalanceSummary/BalanaceSummary"
import TradeHistory from "../../components/TradeHistory/TradeHistory"
import { useLimits } from "../../hooks/useLimits"
import { useBalance } from "../../hooks/useBalance"
import { usePairs } from "../../hooks/usePairs"
import { useCoins } from "../../hooks/useCoins"
import { usePutExchange, usePostExchange } from "../../hooks/useExchange"
import converter_swap from "../../assets/icons/converter_swap.svg"
import { truncateToDecimals } from "../../utils/functions"
import { Range } from "react-range"
import toast from "react-hot-toast"
import "./Converter.css"
import Spinner from "../../components/Spinner/Spinner"

const Converter = () => {

    const handleExchangeError = (error: any) => {
        if (lastModified === "top") {
            setDisableTop(true)
            setBottomAmount("")
        }
        else if (lastModified === "bot") {
            setDisableBottom(true)
            setSelectedPercentage([0])
        }
        console.log(error)
        setDisabledMsg("selected_amount_not_available")
        /*         toast.dismiss()
                toast.error(t("selected_amount_not_available")) */
    }

    const handleSubmitSuccess = (payload: any) => {
        setSelectedPercentage([0])
        setBottomAmount("")
        toast.success(`${t("order_executed")}. ${t('traded')} ${payload.quantity} ${payload.base_currency} ${t('for')} ${payload.cost} ${payload.quote_currency}`)
    }

    const handleSubmitError = (error: any) => {
        setSelectedPercentage([0])
        setBottomAmount("")
        console.log(error)
        toast.dismiss()
        toast.error(t("error"))
    }

    const { t } = useTranslation('common')

    const { coin1, coin2 } = useParams()
    const nav = useNavigate()

    const { data: limitsData } = useLimits()
    const { data: balanceData } = useBalance()
    const { data: pairsData } = usePairs()
    const { data: coinsData } = useCoins()
    const { data: exchangeData, mutate: handleCheckPrice } = usePutExchange(handleExchangeError)
    const { mutate: handleSubmitOrder, isPending: isOrderSubmitPending } = usePostExchange(handleSubmitSuccess, handleSubmitError)

    const [selectedPercentage, setSelectedPercentage] = useState([0])
    const [bottomAmount, setBottomAmount] = useState("")
    const [lastModified, setLastModified] = useState("")
    const [disableTop, setDisableTop] = useState(false)
    const [disableBottom, setDisableBottom] = useState(false)
    const [timer, setTimer] = useState(0)
    const [disabledMsg, setDisabledMsg] = useState("")

    const topinputref = useRef<HTMLInputElement>(null)
    const botinputref = useRef<HTMLInputElement>(null)
    const lastModifiedRef = useRef(lastModified)
    const selectedPercentageRef = useRef(selectedPercentage)
    const bottomAmountRef = useRef(bottomAmount)
    const timerRef = useRef(timer)
    const coin1Ref = useRef(coin1)
    const coin2Ref = useRef(coin2)
    const disableTopRef = useRef(disableTop)
    const disableBottomRef = useRef(disableBottom)

    const coin1SliderMax = coin1 && balanceData && limitsData && coinsData ? Math.min(
        truncateToDecimals(
            balanceData.balance[coin1].actual, 
            coinsData.coins[coin1].decimals) , 
            limitsData?.[coin1]?.order?.max_limit
        ) : 0
    const coin1max = coin1 && limitsData ? limitsData?.[coin1]?.order?.max_limit : 0
    // NOTE if this is 0 the range bar wont show up
    const coin1min = coin1 && balanceData && limitsData ? limitsData[coin1]?.order?.min_limit  : 0
    const coin1balance = coin1 && balanceData && coinsData ? 
        truncateToDecimals(
            balanceData.balance[coin1].actual, 
            coinsData.coins[coin1].decimals
        ) : 0
    const coin2max = coin2 && balanceData && limitsData ? limitsData?.[coin2]?.order?.max_limit : 0
    const coin2min = coin2 && balanceData && limitsData ? limitsData?.[coin2]?.order?.min_limit : 0

    const feecut = coin1 && limitsData ? limitsData?.[coin1]?.order?.maker_fee : 0
    const fee = feecut ? feecut * selectedPercentage[0] : 0

    useEffect(() => {
        lastModifiedRef.current = lastModified
    }, [lastModified])

    useEffect(() => {
        selectedPercentageRef.current = selectedPercentage
    }, [selectedPercentage])

    useEffect(() => {
        bottomAmountRef.current = bottomAmount
    }, [bottomAmount])

    useEffect(() => {
        timerRef.current = timer
    }, [timer])

    useEffect(() => {
        coin1Ref.current = coin1
    }, [coin1])

    useEffect(() => {
        coin2Ref.current = coin2
    }, [coin2])

    useEffect(() => {
        disableTopRef.current = disableTop
    }, [disableTop])

    useEffect(() => {
        disableBottomRef.current = disableBottom
    }, [disableBottom])

    useEffect(() => {
        setSelectedPercentage([0])
        setBottomAmount("")
        setDisableBottom(false)
        setDisableTop(false)
        setDisabledMsg("")
    }, [coin1, coin2])

    useEffect(() => {
        const reducetimerInterval = setInterval(() => {
            setTimer((prev) => {
                if (prev > 0) {
                    return prev - 1
                }
                else {
                    return prev
                }
            })
        }, 100)

        const triggerExchangeInterval = setInterval(() => {
            const lastModifiedValue = lastModifiedRef.current
            const selectedPercentageValue = selectedPercentageRef.current
            const bottomAmountValue = bottomAmountRef.current
            const coin1Value = coin1Ref.current
            const coin2Value = coin2Ref.current
            const timerValue = timerRef.current
            const disableTopValue = disableTopRef.current
            const disableBottomValue = disableBottomRef.current

            if (disableTopValue || disableBottomValue) {
                return
            }

            if (lastModifiedValue && selectedPercentageValue[0] !== 0 && bottomAmountValue && coin1Value && coin2Value && timerValue === 0) {
                if (lastModifiedValue === "top") {
                    handleCheckPrice({ base_currency: coin1Value, quote_currency: coin2Value, operation: 1, quantity: selectedPercentageValue[0].toString() })
                }
                else {
                    handleCheckPrice({ base_currency: coin2Value, quote_currency: coin1Value, operation: 0, quantity: bottomAmountValue })
                }
            }
        }, 5000)

        return () => {
            clearInterval(reducetimerInterval)
            clearInterval(triggerExchangeInterval)
        }
    }, [])

    useEffect(() => {
        if (timer === 0) {
            if (lastModified === "top") {
                modifyInput(topinputref.current?.value)
            }
            else if (lastModified === "bot") {
                modifyInputBottom(botinputref.current?.value)
            }
        }
    }, [timer])

    useEffect(() => {
        if (topinputref.current) {
            if (selectedPercentage[0] === 0) {
                topinputref.current.value = ""
            }
            else {
                const numval = selectedPercentage[0]
                topinputref.current.value = numval.toFixed(coin1 ? coinsData?.coins[coin1]?.decimals : 8)
            }
        }
    }, [selectedPercentage])

    useEffect(() => {
        if (botinputref.current) {
            if (bottomAmount === "") {
                botinputref.current.value = ""
            }
            else {
                const numval = Number(bottomAmount)
                botinputref.current.value = String(numval.toFixed(coin2 ? coinsData?.coins[coin2]?.decimals : 8))
            }
        }
    }, [bottomAmount])

    useEffect(() => {
        if (exchangeData && coin1 && coin2) {
            const cost = exchangeData.data.cost
            if (lastModified === "top") {
                setBottomAmount(String(Number(cost) * (1 - feecut)))
            }
            else {
                const adjustedcost = Number((Number(cost) / (1 - feecut)).toFixed(8))
                if (adjustedcost >= coin1min && adjustedcost <= coin1SliderMax) {
                    setSelectedPercentage([adjustedcost])
                }
                else {
                    toast.dismiss()
                    if (cost > coin1SliderMax) {
                        setSelectedPercentage([adjustedcost])
                        setDisabledMsg(t("exceeds_balance_more_than_max"))
                        /* toast.error(t('exceeds_balance_more_than_max')) */
                    }
                    else {
                        setSelectedPercentage([0])
                        setDisabledMsg("less_than_min")
                        /* toast.error(t('less_than_min')) */
                    }
                    setDisableBottom(true)
                }
            }
        }
    }, [exchangeData])


    const handleSetRange = (position: number) => {
        setSelectedPercentage([position])
    }

    const handleSetTimer = () => {
        setLastModified("top")
        setTimer(10)
    }

    const setTimerBottom = () => {
        setLastModified("bot")
        setTimer(10)
    }

    const modifyInput = (incval: any) => {
        setDisableTop(false)
        setDisableBottom(false)
        setDisabledMsg("")
        if (!coin1 || !coin2) {
            return
        }
        if (incval === "") {
            setBottomAmount("")
            return
        }
        const val = Number(incval)
        if (isNaN(val)) {
            setDisableTop(true)
            setBottomAmount("")
            setDisabledMsg('converter_errors.invalid_value')
            return
        }
        if (val < coin1min) {
            setDisableTop(true)
            setBottomAmount("")
            setDisabledMsg('converter_errors.value_lower_than_min')
            return
        }
        if (val > coin1max) {
            setDisableTop(true)
            setSelectedPercentage([val])
            setBottomAmount("")
            setDisabledMsg('converter_errors.value_higher_than_max')
        }
        if (val > coin1balance) {
            setDisableTop(true)
            setSelectedPercentage([val])
            setBottomAmount("")
            setDisabledMsg('converter_errors.value_higher_than_balance')
            handleCheckPrice({ base_currency: coin1, quote_currency: coin2, operation: 1, quantity: incval })
        }
        else {
            setSelectedPercentage([val])
            handleCheckPrice({ base_currency: coin1, quote_currency: coin2, operation: 1, quantity: incval })
        }
    }

    const modifyInputBottom = (incval: any) => {
        setDisableBottom(false)
        setDisableTop(false)
        setDisabledMsg("")
        if (!coin1 || !coin2) {
            return
        }
        if (incval === "") {
            setSelectedPercentage([0])
            return

        }
        const val = Number(incval)
        if (isNaN(val)) {
            setDisableBottom(true)
            setSelectedPercentage([0])
            setDisabledMsg('converter_errors.invalid_value')
            return
        }
        if (val <= 0) {
            setDisableBottom(true)
            setSelectedPercentage([0])
            setDisabledMsg('converter_errors.value_lower_than_min')
            return
        }
        setBottomAmount(incval)
        handleCheckPrice({ base_currency: coin2, quote_currency: coin1, operation: 0, quantity: incval })
    }

    const handleSwapCoins = () => {
        nav(`/converter/${coin2}/${coin1}`)
    }

    const handleConvert = () => {
        if (coin1 && coin2) {
            handleSubmitOrder({ base_currency: coin1, quote_currency: coin2, operation: 1, quantity: selectedPercentage[0].toString() })
        }
    }

    let filteredCoinsBottom: string[] = []
    pairsData?.forEach((pair: any) => {
        if (coin1 === pair.base.code) {
            if (coin2 !== pair.quote.code) {
                filteredCoinsBottom.push(pair.quote.code)
            }
        }
        else if (coin1 === pair.quote.code) {
            if (coin2 !== pair.base.code) {
                filteredCoinsBottom.push(pair.base.code)
            }
        }
    })

    let filteredCoinsTop: string[] = []
    pairsData?.forEach((pair: any) => {
        if (coin2 === pair.base.code) {
            if (coin1 !== pair.quote.code) {
                filteredCoinsTop.push(pair.quote.code)
            }
        }
        else if (coin2 === pair.quote.code) {
            if (coin1 !== pair.base.code) {
                filteredCoinsTop.push(pair.base.code)
            }
        }
    })

    const trackmarkPositions = balanceData && coinsData && coin1 ? [0, 0.25 * coin1SliderMax, 0.50 * coin1SliderMax, 0.75 * coin1SliderMax, coin1SliderMax] : []

    const trackmarksElems = trackmarkPositions.map((position, i) => {
        return <div key={i} onClick={() => { handleSetRange(position) }} style={{ left: `calc(${i * 25}% - 11px)` }} className="converter-range-track-marks"></div>
    })

    const sliderVal = coinsData && coin1 ? [Math.min(selectedPercentage[0], coin1SliderMax)] : [0]

    const calculateRates = () => {
        if (selectedPercentage[0] && bottomAmount) {
            const topValue = selectedPercentage[0];
            const bottomValue = Number(bottomAmount);

            if (bottomValue === 0) return null;

            return {
                rate1: topValue / bottomValue,  
                rate2: bottomValue / topValue    
            };
        }


        if (coin1 && coin2) {
            const marketRate1 = getMarketRate(coin1, coin2);
            const marketRate2 = getMarketRate(coin2, coin1);

            if (marketRate1 && marketRate2) {
                return {
                    rate1: marketRate2,
                    rate2: marketRate1
                };
            }
        }

        return null;
    };

    const getMarketRate = (baseCoin: string, quoteCoin: string) => {
        if (!pairsData?.pairs) return null;

        const directPair = pairsData.pairs.find(
            (pair: { pair_data: { base: { code: string }; quote: { code: string } } }) => pair.pair_data.base.code === baseCoin &&
                pair.pair_data.quote.code === quoteCoin
        );
        if (directPair) return directPair.price;

        const inversePair = pairsData.pairs.find(
            (pair: { pair_data: { base: { code: string }; quote: { code: string } } }) => pair.pair_data.base.code === quoteCoin &&
                pair.pair_data.quote.code === baseCoin
        );
        if (inversePair) return 1 / inversePair.price;

        return null;
    };

    return (
        <div className="outlet-page-main-cont">
            <div className="converter-page-main-cont">
                <div className="converter-page-converter-cont">
                    <h1 className="converter-title">{t('converter')}</h1>
                    <div className="converter-converter-body">
                        {limitsData && balanceData && coin1 && coin2 ?
                            <>
                                <div className="converter-error-space"><span className="converter-error-msg">{t(disabledMsg)}</span></div>
                                <div className="converter-topcoin-cont">
                                    <p className="converter-box-title">{t('selling')}</p>
                                    <div className="converter-input-coin-cont">
                                        <input ref={topinputref} onChange={handleSetTimer} placeholder={`${coin1min} - ${(coin1max).toFixed(coin1 ? coinsData?.coins[coin1]?.decimals : 8)} ${coin1}`} className={`converter-input ${disableTop ? "converter-invalid-value" : ""}`} />
                                        <CoinSelector filteredCoins={filteredCoinsTop} order={1} selectedCoin={coin1} />
                                    </div>
                                    {<Range disabled={!coin1balance} values={sliderVal} onChange={(values) => { handleSetTimer(); setSelectedPercentage(values) }} label="Select your value" step={coin1min} min={0} max={coin1SliderMax !== 0 ? coin1SliderMax : 10}
                                        renderTrack={({ props, children }) => (
                                            <div
                                                className="converter-range-track"
                                                {...props}
                                            >
                                                {children}
                                                {trackmarksElems}
                                            </div>
                                        )}
                                        renderThumb={({ props }) => (
                                            <div
                                                className="converter-range-thumb"
                                                {...props}
                                                key={props.key}
                                            />
                                        )}
                                    />}
                                </div>
                                <div onClick={handleSwapCoins} className="converter-swap-icon-cont">
                                    <img className="swap-icon" src={converter_swap} />
                                </div>
                                <div className="converter-topcoin-cont">
                                    <p className="converter-box-title">{t('getting')}</p>
                                    <div className="converter-input-coin-cont">
                                        <input /* disabled={!coin1balance} */ ref={botinputref} onChange={setTimerBottom} placeholder={`${coin2min} - ${coin2max.toFixed(coin2 ? coinsData?.coins[coin2]?.decimals : 8)} ${coin2}`} className={`converter-input ${disableBottom ? "converter-invalid-value" : ""}`} />
                                        <CoinSelector filteredCoins={filteredCoinsBottom} order={2} selectedCoin={coin2} />
                                    </div>
                                    <button disabled={isOrderSubmitPending || selectedPercentage[0] === 0 || !bottomAmount || disableBottom || disableTop || Boolean(disabledMsg)} onClick={handleConvert} className="converter-convert-button">{t('convert')}</button>
                                </div>
                                <div className="converter-rates-container">
                                    
                                    <div className="converter-fee-maintext"><span>{t('fee_apply_t')} <span className="converter-fee-text-num">{feecut * 100}%</span> {t('fee_equivalent_t')} <span className="converter-fee-text-num">{fee === 0 ? fee : fee.toFixed(coin1 ? coinsData?.coins[coin1]?.decimals : 2)}</span> {coin1}</span></div>
                                    <div className="converter-error-space"></div>

                                    {coin1 && coin2 && (
                                        <div className="converter-rate-display">
                                            {calculateRates() ? (
                                                <>
                                                    <div className="converter-rate-display-converter-rate-custom">
                                                        <span>{coin1} ≈ {calculateRates()?.rate2.toFixed(coin2 ? coinsData?.coins[coin2]?.decimals : 8)} {coin2}*</span>
                                                    </div>

                                                    <div className="converter-rate-type-text">
                                                        {t('rates_are_referential')}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="converter-rate-type-text">
                                                    {t('input_number_to_see_rates')}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="converter-error-space"></div>
                            </>
                            : <Spinner />
                        }
                    </div>
                </div>
                <div className="converter-page-right-cont">
                    <h1 className="converter-title">{t('balance')}</h1>
                    <div className="converter-balance-body">
                        <BalanceSummary />
                    </div>
                    <TradeHistory />
                </div>
            </div>
        </div>
    )
}

export default Converter