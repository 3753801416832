import { useFAQ } from "../../hooks/useTickets"
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner/Spinner"
import i18n from "../../i18n"
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa6";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useState, useEffect, useRef } from "react";
import InputAdvanced from "../Input/InputAdvanced";
import toast from "react-hot-toast";


import "./SupportFAQ.css"

interface FAQSection {
    id: string,
    title: string
}

interface FAQArticle {
    id: string,
    title: string,
    description: string
}

const SupportFAQ = () => {
    const { t } = useTranslation('common')
    
    const [ searchQuery, setSearchQuery ] = useState('')
    const [ inputSearchQuery, setInputSearchQuery ] = useState('')
    const [ sectionSelected, setsectionSelected ] = useState('')
    const [ articleSelected, setArticleSelected ] = useState<FAQArticle>()
    const { data: faqSectionsData, isLoading: isSectionLoading } = useFAQ('sections', '16515831889940', i18n.language)
    const { data: faqArticlesData, isLoading: isArticlesLoading } = useFAQ('articles', sectionSelected, i18n.language)
    const { data: faqSearchData, isLoading: isSearchLoading } = useFAQ('results', searchQuery, i18n.language)

    const articlesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!articlesRef.current) return;
    
        window.scrollTo({
            top: articlesRef.current.offsetTop - 60,
            behavior: "smooth"
        });
    }, [sectionSelected, faqArticlesData, faqSearchData]);
    

    const handleFAQSectionSelect = (id : string) => {
        setArticleSelected(undefined)
        setSearchQuery('')
        setsectionSelected(sectionSelected == id ? '' : id)
    }

    const handleFAQSArticleSelect = ( article: FAQArticle) => {
        setArticleSelected(article)
    }

    const handleInputChange = (field: any, value: string) => {
        setInputSearchQuery(value)
        return field.name
    }

    const handleFAQSearch = () => {
        if (!inputSearchQuery) {
            toast.error(t("faq_search_error"))
            return
        }
        setsectionSelected('')
        setArticleSelected(undefined)
        setSearchQuery(inputSearchQuery)
    }


    const articleDiv = ( article : FAQArticle) => {
        if(articleSelected?.id == article.id)
            return articleDescription(article)
        return (
            <div className="support-faq-article-item" onClick={() => handleFAQSArticleSelect(article)}>
                <FaChevronRight className="support-faq-article-pointer"/>
                <div className="support-faq-article-title">{article.title}</div>
            </div>
        )

    }

    const articleDescription = (article : FAQArticle) => {
        return (
            <div className="support-faq-article-description">
                <div className="support-faq-article-description-header">
                    <div className="support-faq-article-description-header-close" onClick={() => setArticleSelected(undefined)}>
                        <FaChevronDown className="support-faq-article-pointer"/>
                    </div>
                    <div className="support-faq-article-description-header-title">
                        {article.title}
                    </div> 
                </div>
                <div className="support-faq-article-description-content" dangerouslySetInnerHTML={{ __html: article.description }} />
                
            </div>
        )
    }

    const faqArticles = isArticlesLoading ? <Spinner /> :
        faqArticlesData?.map((faqArticle: { id: string; title: string, description: string }) => (
            articleDiv(faqArticle)
        ))

    const faqSearchArticles = isSearchLoading ? <Spinner /> : 
        (faqSearchData?.length ? faqSearchData.map((faqArticle: { id: string; title: string, description: string }) => (
            articleDiv(faqArticle)
        )) : <div className="support-faq-no-data-search">{t("no_data_search")}</div>);
    
    const sectionDiv = ( section: FAQSection) => {
        return (
            <>
            <div className={`support-faq-section-item ${section.id == sectionSelected ? "support-faq-section-item-selected": ""}`} onClick={() => handleFAQSectionSelect(section.id)}>
                <div className="support-faq-item">
                    <div className="support-faq-section-title">{section.title}</div>
                    <FaChevronRight className={`${section.id == sectionSelected && "support-faq-section-icon-right"}`}/>
                    <FaChevronDown className={`support-faq-section-icon-down ${section.id == sectionSelected && "support-faq-section-icon-down-selected"}`}/>

                </div>
            </div>
            {sectionSelected === section.id && <div className="support-faq-section-articles"> {faqArticles} </div >}
            </>
        )

    }

    const faqSections = faqSectionsData?.map((faqSection: FAQSection) => (
            sectionDiv(faqSection)
        ))

    return (
        <div className="support-faq-main">
            <div className="support-faq-search">
                <InputAdvanced field={{name: 'search', description: t("search"), hide_label: true}} onInputChange={handleInputChange} />
                <div className="support-ticket-search-faq-button" onClick={handleFAQSearch} >
                    <FaMagnifyingGlass />
                </div>
            </div>
            {
                isSectionLoading ? <Spinner />: 
                <div className="support-faq-content">

                    <div className="support-faq-sections">
                        {faqSections}
                    </div>
                    {
                        (sectionSelected || isSearchLoading || faqSearchData) && 
                        <div ref={articlesRef} className={`support-faq-articles ${sectionSelected && "support-faq-articles-section-selected" } ${(isSearchLoading || isArticlesLoading) && "support-faq-articles-loading"}`}>
                            {/* { articleSelected ? articleDescription(articleSelected) : (faqArticles || faqSearchArticles) } */}
                            {faqArticles || faqSearchArticles}
                        </div>
                    }
                </div>
            }
        </div>
    )
}
export default SupportFAQ