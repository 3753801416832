import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import './Paginator.css'

interface PaginatorProps {
    totalPagesNum: number,
    page: number,
    setPage: (page: number) => void,

    /**
     * Must be an odd number greater than 3. 
     * Examples: 5, 7, 9.
     */
    numPagesDisplayed?: number
}

const Paginator = ({ 
        totalPagesNum, 
        page, 
        setPage, 
        numPagesDisplayed = 5
    }: PaginatorProps) => {

    const { t } = useTranslation('common');

    const currentPage = page + 1;

    function handlePageNumber(pageToGo: number) {
        setPage(pageToGo - 1);
    }

    function handleClickForward() {
        setPage(Math.min(page + 1, totalPagesNum - 1))
    }

    function handleClickBackward() {
        setPage(Math.max(page - 1, 0))
    }

    function getDisplayPages(totalPagesNum: number, currentPage: number) {
        const pages = [];

        const addPageRange = (start: number, end: number) => {
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }
        };

        // If there are less pages in total that as much need to be displayed
        if (totalPagesNum <= numPagesDisplayed) {
            addPageRange(1, totalPagesNum);
        } else {
            // If the page should be at the left
            if (currentPage < numPagesDisplayed - 2) {
                addPageRange(1, numPagesDisplayed - 2);
                pages.push('...', totalPagesNum);
            // If the page should be at the right
            } else if (currentPage  > totalPagesNum - (numPagesDisplayed - 2)) {
                pages.push(1, '...');
                addPageRange(totalPagesNum - (numPagesDisplayed - 3), totalPagesNum);
            // If the page should be in the middle
            } else {
                pages.push(1, '...');
                const pageMargin = (numPagesDisplayed - 5) / 2
                addPageRange(currentPage - pageMargin, currentPage + pageMargin);
                pages.push('...', totalPagesNum);
            }
        }

        return pages;
    }

    const displayPages = getDisplayPages(totalPagesNum, currentPage)

    return (
        <div className="paginator-main-cont">
            <div className="pagination-control" onClick={handleClickBackward}>
                <HiChevronLeft size={24} />
                <span className="next-previous-pagination">{t("previous")}</span>
            </div>
            <div className="pagination-pages">
                {displayPages.map((numberi, index) => (
                    typeof numberi === 'number' ? (
                        <div
                            key={index}
                            className={`pagination-page ${numberi === currentPage ? 'active' : ''}`}
                            onClick={() => handlePageNumber(numberi)}
                        >
                            {numberi}
                        </div>
                    ) : (
                        <div key={index} className="pagination-ellipsis">
                            {numberi}
                        </div>
                    )
                ))}
            </div>
            <div className="pagination-control" onClick={handleClickForward}>
                <span className="next-previous-pagination">{t("next")}</span>
                <HiChevronRight size={24} />
            </div>
        </div>
    )
}

export default Paginator