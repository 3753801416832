import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePostCardRequest } from "../../hooks/useCards";
import { useAddresses } from "../../hooks/useAddresses";
import SelectAdvanced from "../../components/SelectAdvanced/SelectAdvanced";
import { FaCirclePlus } from "react-icons/fa6";
import { IS_VIRTUAL_CARDS_ENABLED, IS_PHYSICAL_CARDS_ENABLED, IS_CARD_DELIVERY_ENABLED } from "../../utils/cards";
import AddAddressModal from "../AddAddressModal/AddAddressModal";
import useModalStore from "../../stores/ModalStore";
import toast from "react-hot-toast";
import "./CardRequestModal.css";
import Spinner from "../../components/Spinner/Spinner";


const CardRequestModal = () => {
    const { t } = useTranslation("common");
    const { mutateAsync: postCardRequest } = usePostCardRequest();
    const { data: addresses } = useAddresses();

    const cardTypeOptions: { [key: string]: { name: string; id: string } } =  {
        physical: { name: t("card_request_form.physical"), id: "physical" },
    };
    if (IS_VIRTUAL_CARDS_ENABLED) cardTypeOptions.virtual = { name: t("card_request_form.virtual"), id: "virtual" };

    const requestTypeOptions: { [key: string]: { name: string; id: string } } = {
        pickup: { name: t("card_request_form.pickup"), id: "pickup" },
    };
    if (IS_PHYSICAL_CARDS_ENABLED && IS_CARD_DELIVERY_ENABLED) requestTypeOptions.delivery = { name: t("card_request_form.delivery"), id: "delivery" };
    
    const [formData, setFormData] = useState({
        card_type: cardTypeOptions.physical.id,
        type: requestTypeOptions.pickup.id,
        user_address: ""
    });

    const isFormValid = Object.values(formData).every((val) => val.trim() !== "") || formData.card_type !== cardTypeOptions.physical.id || (formData.card_type === cardTypeOptions.physical.id && formData.type === requestTypeOptions.pickup.id);

    const [isLoading, setIsLoading] = useState(false);

    const { setModalContent, closeModal } = useModalStore((state) => {
        return {
            setModalContent: state.setModalContent,
            closeModal: state.closeModal,
        }
    })
    
    const handleInputChange = (field: string, value: string) => {
        setFormData((prev) => ({
        ...prev,
        [field]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const result = await postCardRequest({ formData });
            setIsLoading(false);

            if (result.status === 201) {
                toast.dismiss();
                toast.success(t("card_request_form.success"));
                closeModal();
            }
        } catch (error) {
            setIsLoading(false);
            toast.dismiss();
            toast.error(t("card_request_form.error"));
        }
    };

    const addressOptions = addresses?.reduce((acc: { [key: string]: any }, address: any) => {
        acc[address.id] = { name: `${address.street}, ${address.city}, ${address.country}`, id: address.id };
        return acc;
    }, {}) || {};

    const officeOptions = { 0: { id: "0", name: t("nemo_office_address") }}
    const notAvailableOptions = { 0: { id: "0", name: t("not_available") }}

    return (
        <div className="card-request-modal">
            <div className="create-card-request-title">{t("card_request_form.title")}</div>
            <div className="card-request-field-container">
                <div className="card-request-info">
                    {t("card_request_form.info")}
                </div>
                <div className="card-request-field">
                    <SelectAdvanced
                        label={t("card_request_form.select_card_type")}
                        value={formData.card_type}
                        items={cardTypeOptions}
                        image={false}
                        onChange={(selectedCardType) => handleInputChange("card_type", selectedCardType)}
                    />
                </div>
                {formData.card_type === cardTypeOptions.physical.id && <div className="card-request-field">
                    <SelectAdvanced
                        label={t("card_request_form.select_type")}
                        value={formData.type}
                        items={requestTypeOptions}
                        image={false}
                        onChange={(selectedType) => handleInputChange("type", selectedType)}
                    />
                </div>}
                {formData.card_type === cardTypeOptions.physical.id && formData.type !== requestTypeOptions.pickup.id ? 
                <div className="card-request-field">
                    <SelectAdvanced
                        label={t("card_request_form.select_address")}
                        value={""}
                        items={addressOptions}
                        image={false}
                        onChange={(selectedAddressId) => handleInputChange("user_address", selectedAddressId)}
                    />
                    <div onClick={() => {setModalContent(<AddAddressModal/>)}} className="create-address-button" >
                        <FaCirclePlus 
                            className="card-request-add-address-icon"
                            size={30}
                        />
                    </div>
                </div> : formData.card_type === cardTypeOptions.physical.id &&
                <div className="card-request-field">
                    <SelectAdvanced
                        label={t("card_request_form.select_address")}
                        value={""}
                        items={IS_PHYSICAL_CARDS_ENABLED ? officeOptions : notAvailableOptions}
                        image={false}
                        onChange={() => handleInputChange("user_address", "")}
                    />
                </div>
                }
            </div>
            <div className="card-request-action-button-div">
                {isLoading ? (
                <Spinner />
                ) : (
                <button className="card-request-submit-button" disabled={!isFormValid || (formData.card_type === cardTypeOptions.physical.id && !IS_PHYSICAL_CARDS_ENABLED)} onClick={handleSubmit}>
                    {t("submit")}
                </button>
                )}
            </div>
        </div>
    );
};

export default CardRequestModal;
