import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { FaLock, FaEdit, FaSearch, FaRegTrashAlt, FaList } from "react-icons/fa"
import { RiFileUploadFill } from "react-icons/ri"
import { IoIosSend, IoMdPeople, IoMdCloseCircle } from "react-icons/io"
import { FaCirclePlus } from "react-icons/fa6"
import { MdArrowDropDownCircle } from "react-icons/md"
import { HiCheckCircle, HiClock, HiMinusCircle } from "react-icons/hi"
import { AiOutlineClear } from "react-icons/ai"
import toast from "react-hot-toast"

import coinlogos from "../../utils/coinlogos"
import CheckUsernameInput from "../../components/CheckUsernameInput/CheckUsernameInput"
import Paginator from "../../components/Paginator/Paginator"
import SelectAdvanced from "../../components/SelectAdvanced/SelectAdvanced"
import SortDropdown from "../../components/SortDropdown/SortDropdown"
import CoinFilterSelector from "../../components/CoinFilterSelector/CoinFilterSelector"
import Skeleton from "../../components/Skeleton/Skeleton"
import Spinner from "../../components/Spinner/Spinner"

import UserListModal from "../../modals/UserListModal/UserListModal"
import EditUserListModal from "../../modals/EditUserListModal/EditUserListModal"
import UserListFileUploadModal from "../../modals/UserListFileUploadModal/UserListFileUploadModal"
import EditInvalidUser from "../../modals/EditInvalidUser/EditInvalidUser"
import SendMassTransferModal from "../../modals/SendMassTransferModal/SendMassTransferModal"
import CreateUserListModal from "../../modals/CreateUserListModal/CreateUserListModal"
import GenericModal from "../../modals/GenericModal/GenericModal"
import MassTransferDetailsModal from "../../modals/MassTransferDetailsModal/MassTransferDetailsModal"
import FilterMassTransfersModal from "../../modals/FilterMassTransfersModal/FilterMassTransfersModal"

import useModalStore from "../../stores/ModalStore"
import useUserListStore from "../../stores/UserListStore"
import { useProfile } from "../../hooks/useProfile"
import { useCoins } from "../../hooks/useCoins"
import { useBalance } from "../../hooks/useBalance"
import { useMassTransfer, useMassTransfers } from "../../hooks/useTransfers"
import { useUserLists, useUserListUsers, useDeleteUserList } from "../../hooks/useUserLists"
import { numberWithCommas, updatePageData, formatedDateTime } from "../../utils/functions"
import localConfig from "../../local_config"

import "./Payrolls.css"

const Payrolls = () => {

  // First of all nav to / if user is not allowed
  const nav = useNavigate()
  const { data: profileData } = useProfile()
  useEffect(() => {
    if (profileData) {
      if (profileData?.mass_transfers_enabled) return
      nav('/')
    }
  }, [profileData])

  const { t } = useTranslation('common')

  const { setModalContent } = useModalStore((state) => ({
        setModalContent: state.setModalContent
  }))

  const { getUserList, updateUserList } = useUserListStore((state) => ({
    getUserList: state.getUserList,
    updateUserList: state.updateUserList
  }))

  const { data: balanceData } = useBalance()
  const { data: coinsData } = useCoins()
  const { mutateAsync: deleteUserList } = useDeleteUserList()
  const { mutateAsync: sendMassTransfer } = useMassTransfer()

  const [selectedListId, setSelectedListId] = useState<number | undefined>(undefined)

  // const defaultCoin = localConfig.countryCode === "CO" ? "COP" : "USD"
  const defaultCoin = localConfig.countryCode === "CO" ? "" : ""

  // Payroll form
  const [payrollAmount, setPayrollAmount] = useState("")
  const [selectedCoin, setSelectedCoin] = useState(defaultCoin)
  const [newEmail, setNewEmail] = useState("")
  const [isNewEmailValid, setIsNewEmailValid] = useState(false)

  const [showFilters, setShowFilters] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [sortInvalid, setSortInvalid] = useState("")
  const [sortAmount, setSortAmount] = useState("")
  const [sortAlpha, setSortAlpha] = useState("")

  // Stats
  const payrollUserList = getUserList()
  const num_users = payrollUserList?.length || 0
  const total_amount = payrollUserList.reduce((acc, user) => acc + parseFloat(user.amount || "0"), 0)
  const amounts = payrollUserList.map(user => parseFloat(user.amount || "0"))
  const min_amount = amounts.length > 0 ? Math.min(...amounts) : 0
  const max_amount = Math.max(...payrollUserList.map(user => parseFloat(user.amount || "0")), 0)
  const average_amount = total_amount / num_users
  const current_balance = balanceData?.balance[selectedCoin]?.actual || 0
  const balance_after_transaction = current_balance - total_amount

  const saveListEnabled = payrollUserList.length > 0 && payrollUserList.every(user => user.isValid)
  const sendTransferEnabled = saveListEnabled && balance_after_transaction >= 0 && total_amount > 0

  // User lists
  const itemsPerUserListPage = 3
  const [userListPage, setUserListPage] = useState(0)
  const [userListTotalPages, setUserListTotalPages] = useState(1)
  const [oldUserListsDataResults, setOldUserListsDataResults] = useState<any[] | null>(null)
  const [userList, setUserList] = useState<any[]>(Array.from({ length: itemsPerUserListPage }, (_, index) => ({ id: index + 1, display: true })))

  // Mass transfers
  const itemPerMassTransferPage = 3
  const [massTransferPage, setMassTransferPage] = useState(0)
  const [massTransferTotalPages, setMassTransferTotalPages] = useState(1)
  const [oldMassTransfersDataResults, setOldMassTransfersDataResults] = useState<any[] | null>(null)
  const [massTransfers, setMassTransfers] = useState<any[]>(Array.from({ length: itemPerMassTransferPage }, (_, index) => ({ id: index + 1, display: true })))

  // Mass transfer filters
  const [transferSelectedCoin, setTransferSelectedCoin] = useState(defaultCoin)

  const { data: userListsData, isLoading: isLoadingUserLists } = useUserLists({
    limit: itemsPerUserListPage,
    offset: userListPage * itemsPerUserListPage
  })

  const { data: massTransfersData, isLoading: isLoadingMassTransfers } = useMassTransfers({
    limit: itemPerMassTransferPage,
    offset: massTransferPage * itemPerMassTransferPage,
    currency: transferSelectedCoin !== "" ? transferSelectedCoin : undefined
  })

  const { data: userListUsers, isLoading: isLoadingUserListUsers } = useUserListUsers({
      id: selectedListId,
      no_pagination: true
  })

  const coinsItems = () => {
    let items: {
        [key: string]: {
            name: string,
            logo?: string,
            decimals?: number,
        }
    } = {}

    Object.keys(coinsData ? coinsData.coins : {}).forEach(key => {
        items[key] = {
            name: coinsData.coins[key].name,
            logo: coinsData.coins[key].logo,
            decimals: coinsData.coins[key].decimals,
        }
    })

    return items
  }

  // Input changes

  const handlePayrollAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value !== "" && (parseFloat(value) <= 0 || isNaN(parseFloat(value)))) return
    setPayrollAmount(value)
  }

  const handleCoinChange = (value: string) => {
    setSelectedCoin(value)
  }

  // User list items changes

  const handleToggleUserLock = (index: number) => {
    updateUserList(prevList => {
        return prevList.map(user => {
            if (user.index === index) {
                return {
                    ...user,
                    locked: !user.locked
                }
            }
            return user
        })
    })
  }

  const handleUserAmountChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target
    if (value !== "" && (parseFloat(value) <= 0 || isNaN(parseFloat(value)))) return
    updateUserList(prevList => {
        return prevList.map(user => {
            if (user.index === index) {
                return {
                    ...user,
                    amount: value,
                    locked: true
                }
            }
            return user
        })
    })
  }

  // Add user

  const handleAddNewEmail = () => {
    if (isNewEmailValid) {
      updateUserList(prevList => {
          return [
              ...prevList,
              {
                  index: prevList.length,
                  email: newEmail,
                  amount: payrollAmount || "0",
                  locked: false,
                  isValid: true,
              }
          ]
      })
      toast.success(t('payrolls_form.added_user', { user: newEmail }))
      setNewEmail("")
    }
  }

  // Delete user
  
  const handleDeleteUser = (index: number) => {
    updateUserList(prevList => {
        const userToRemove = prevList.find(l => l.index === index)
        if (userToRemove) toast.success(t('payrolls_form.deleted_user', { user: userToRemove.email }))
        return prevList.filter(l => l.index !== index).map(l => l.index > index ? { ...l, index: l.index - 1 } : l)
    })
  }
    
  const handleOpenDeleteUserModal = (index: number) => {
    setModalContent(
      <GenericModal
        data={{
          title: t('payrolls_form.delete_user_modal.title'),
          info: t('payrolls_form.delete_user_modal.text'),
          description: payrollUserList.find(l => l.index === index)?.email,
          action: t('payrolls_form.delete_user_modal.confirm')
        }}
        onConfirm={() => handleDeleteUser(index)}
      />
    )
  }

  // Edit user

  const handleEditInvalidUser = (user: string, index: number) => {
    updateUserList(prevList => {
      const newUsers = prevList.map(u => {
        if (u.index === index) {
          return {
            ...u,
            email: user,
            isValid: true
          }
        }
        return u
      })
      toast.success(t('payrolls_form.updated_user', { user }))
      return newUsers
    })
  }

  const handleOpenEditInvalidUserModal = (user: string, index: number) => {
    EditInvalidUser
    setModalContent(
      <EditInvalidUser
        user={user}
        index={index}
        users={payrollUserList.map(user => user?.email || '')}
        onSubmit={handleEditInvalidUser}
        onDelete={handleDeleteUser}
      />
    )
  }

  // Delete all users
  
  const handleDeleteAllUsers = () => {
    updateUserList([])
    toast.success(t('payrolls_form.deleted_all_users'))
  }

  const handleOpenClearUserListModal = () => {
    setModalContent(
      <GenericModal
        data={{
          title: t('payrolls_form.delete_all_users_modal.title'),
          info: t('payrolls_form.delete_all_users_modal.text'),
          action: t('payrolls_form.delete_all_users_modal.confirm')
        }}
        onConfirm={handleDeleteAllUsers}
      />
    )
  }

  // Upload file

  const handleUpdateUserListFromFile = (users: { is_valid: boolean, email: string, amount: string }[]) => {
    if (users.length === 0) return

    updateUserList(prevList => {
        const prevUsersMap = new Map(prevList.map(user => [user.email, user]))
        let repeatedCount = 0
        let newUsersCount = 0
        let invalidUsersCount = 0

        const updatedUsers = users.reduce((map, user) => {
            if (map.has(user.email)) {
                repeatedCount++
            } else {
                if (!user.is_valid) invalidUsersCount++

                map.set(user.email, {
                    index: prevList.length + newUsersCount,
                    email: user.email,
                    amount: user.is_valid 
                        ? user.amount || payrollAmount || "0" 
                        : "0",
                    locked: Boolean(user.amount && user.amount !== "0" && user.is_valid),
                    isValid: user.is_valid,
                })

                newUsersCount++
            }
            return map
        }, new Map(prevUsersMap))

        toast.success(
            repeatedCount > 0 
            ? t('payrolls_form.added_repeated_users', { repeated: repeatedCount, new: newUsersCount })
            : t('payrolls_form.added_users', { new: newUsersCount })
        )

        if (invalidUsersCount > 0) {
          toast.error(t('payrolls_form.added_invalid_users', { invalid: invalidUsersCount }))
        }

        return Array.from(updatedUsers.values())
    })

    setShowFilters(true)
    setSortInvalid("up")
  }

  const handleOpenAddFileModal = () => {
    setModalContent(
      <UserListFileUploadModal
        onUpload={handleUpdateUserListFromFile}
      />
    )
  }

  // Toggle search

  const handleToggleSearch = () => {
    setShowFilters(!showFilters)
  }

  // Search user

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  // Sort handling

  const handleSort = (sort: string) => {
    // For transfer user list
    if (sort == "invalid") {
      if (sortInvalid === "up") setSortInvalid("down")
      if (sortInvalid === "down") setSortInvalid("")
      if (sortInvalid === "") setSortInvalid("up")
    }
    if (sort == "amount") {
      if (sortAmount === "up") setSortAmount("down")
      if (sortAmount === "down") setSortAmount("")
      if (sortAmount === "") setSortAmount("up")
    }
    if (sort == "alpha") {
      if (sortAlpha === "up") setSortAlpha("down")
      if (sortAlpha === "down") setSortAlpha("")
      if (sortAlpha === "") setSortAlpha("up")
    }
  }

  // Create user list

  const handleOpenCreateUserListModal = () => {
    setModalContent(
      <CreateUserListModal
        nUsers={num_users}
        preferredAmount={payrollAmount}
        preferredCurrency={selectedCoin}
        users={payrollUserList.map(user => user.email)}
      />
    )
  }

  // Select user list

  const handleSelectUserList = (listId: number) => {
    setPayrollAmount(userList.find((list: any) => list.id === listId)?.preferred_amount || "")
    setSelectedCoin(userList.find((list: any) => list.id === listId)?.preferred_currency || "USDT")
    // This triggers the query of the users
    setSelectedListId(listId)
  }

  const handleOpenUserListModal = (list: any) => {
    setModalContent(
      <UserListModal
        userList={list}
        onSelect={handleSelectUserList}
      />
    )
  }

  // Edit user list

  const handleOpenEditUserListModal = (listId: number) => {
    setModalContent(
      <EditUserListModal
        id={listId}
      />
    )
  }

  // Delete user list

  const handleDeleteUserList = async (listId: number) => {
      try {
        const result = await deleteUserList(listId)
        if (result.status === 204) {
          toast.success(t("payrolls_form.delete_list_modal.success"))
        } else {
          toast.error(t("payrolls_form.delete_list_modal.error"))
        }
    } catch (error) {
        toast.error(t("payrolls_form.delete_list_modal.error"))
        console.error("Error deleting user list:", error)
    } finally {
        return
    }
  }

  const handleOpenDeleteUserListModal = (listId: number) => {
    setModalContent(
      <GenericModal
        data={{
          title: t('payrolls_form.delete_list_modal.title'),
          info: t('payrolls_form.delete_list_modal.text'),
          action: t('payrolls_form.delete_list_modal.confirm')
        }}
        onConfirm={() => handleDeleteUserList(listId)}
      />
    )
  }

  // Send transfer

  const handleSendMassTransfer = async (name: string) => {
    try {
      const formData = {
        display_name: name,
        currency: selectedCoin,
        transfers: payrollUserList.map(user => ({
          user_email: user.email,
          amount: user.amount
        }))
      }
      const result = await sendMassTransfer(formData)
      if (result.status === 201) {
        toast.success(t("payrolls_transfer_success"))
      } else {
        toast.error(t("payrolls_transfer_error"))
      }
    } catch (error) {
        toast.error(t("payrolls_transfer_error"))
        console.error("Error creating transfer:", error)
    } finally {
        return
    }
  }

  const handleOpenSendMassTransferModal = () => {
    setModalContent(
      <SendMassTransferModal
        sendTransfer={handleSendMassTransfer}
      />
    )
  }

  // Transfer history modal

  const handleOpenTransferHistoryModal = () => {
    setModalContent(
      <FilterMassTransfersModal
      />
    )
  }

  useEffect(() => {
    if (!selectedCoin) setSelectedCoin(defaultCoin)
  }, [coinsItems, coinsData])

  // User lists page data
  useEffect(() => {
    if (userListsData?.count || !isLoadingUserLists) {
      setUserListTotalPages(userListsData?.count ? Math.ceil(userListsData.count / itemsPerUserListPage) : 1)
    }
  }, [userListsData])

  useEffect(() => {
      const { filledResults: userListFilledResults, pageResults: userListsPageResults } = updatePageData(userListsData, oldUserListsDataResults, itemsPerUserListPage)
      setUserList(userListFilledResults)
      setOldUserListsDataResults(userListsPageResults)
  }, [userListsData])
  
  // Mass transfers page data
  useEffect(() => {
    if (massTransfersData?.count || !isLoadingMassTransfers) {
      setMassTransferTotalPages(massTransfersData?.count ? Math.ceil(massTransfersData.count / itemPerMassTransferPage) : 1)
    }
  }, [massTransfersData])

  useEffect(() => {
    const { filledResults: massTransfersFilledResults, pageResults: massTransfersPageResults } = updatePageData(massTransfersData, oldMassTransfersDataResults, itemPerMassTransferPage)
    setMassTransfers(massTransfersFilledResults)
    setOldMassTransfersDataResults(massTransfersPageResults)
  }, [massTransfersData])

  // Populate user list users when query change
  useEffect(() => {
    if (userListUsers?.results) {
        updateUserList(prevList => {
            const newUsersMap = new Map(prevList.map(user => [user.email, user]))
            let repeatedCount = 0
            let newUsersCount = 0

            userListUsers.results.forEach((user: { email: string }) => {
                if (newUsersMap.has(user.email)) {
                    repeatedCount++
                } else {
                    newUsersMap.set(user.email, {
                        index: prevList.length + newUsersCount,
                        email: user.email,
                        amount: payrollAmount || "0",
                        locked: false,
                        isValid: true,
                    })
                    newUsersCount++
                }
            })

            toast.success(
                repeatedCount > 0 
                ? t('payrolls_form.added_repeated_users', { repeated: repeatedCount, new: newUsersCount })
                : t('payrolls_form.added_users', { new: newUsersCount })
            )

            return Array.from(newUsersMap.values())
        })
        // Reset query
        setSelectedListId(undefined)
    }
  }, [userListUsers])

  // Update global amount
  useEffect(() => {
    updateUserList(prevList => {
        return prevList.map(user => ({
            ...user,
            amount: user.locked ? user.amount : payrollAmount ? payrollAmount : "0",
        }))
    })
  }, [payrollAmount])

  const getStatusIcon = (status: string) => {
    switch(status) {
      case 'completed':
        return <HiCheckCircle size={18} className="wallet-history-status-icon" />
      case 'pending':
        return <HiClock size={18} className="wallet-history-status-icon" />
      case 'processing':
        return <HiClock size={18} className="wallet-history-status-icon" />
      case 'failed':
        return <HiMinusCircle size={18} className="wallet-history-status-icon" />
      case 'cancelled':
        return <HiMinusCircle size={18} className="wallet-history-status-icon" />
      default:
        return null
    }
  }

  return (
    <div className="payrolls-main-cont">
      {/* Left side */}
      <div className="payrolls-main-sidebox">
        <h2 className="payrolls-title">{t('payrolls_main_title')}</h2>
        <div className="payrolls-box">
          <div className="content-payrolls-cont">
            {/* Amount and coin input */}
            <div className="payroll-form-row">
              <div className="payroll-form-row-half">
                <input
                  type="number"
                  className="payroll-amount-input"
                  placeholder={t("payrolls_form.amount_placeholder")}
                  value={payrollAmount}
                  onChange={handlePayrollAmountChange}
                />
              </div>
              <div className="payroll-form-row-half">
                <SelectAdvanced
                    value={selectedCoin}
                    items={coinsItems()}
                    image={true}
                    onChange={handleCoinChange}
                />
              </div>
            </div>

            {/* Add user by email */}
            <div className="payroll-form-row">
              <div className="payroll-email-input-box">
                <CheckUsernameInput
                  placeholder={t("payrolls_form.new_email_placeholder")}
                  errorMessage={t("payrolls_errors.invalid_username")}
                  allEmails={payrollUserList.map(user => user.email)}
                  email={newEmail}
                  setEmail={setNewEmail}
                  setValid={setIsNewEmailValid}
                  onEnter={handleAddNewEmail}
                />
                <div 
                  className={`payroll-add-user-button ${!isNewEmailValid ? 'payroll-button-disabled' : ''}`}
                  onClick={isNewEmailValid ? handleAddNewEmail : undefined}
                >
                  <FaCirclePlus 
                      className="payroll-add-user-button-icon"
                      size={30}
                  />
                </div>
                <div 
                  className="payroll-add-user-button"
                  onClick={handleOpenAddFileModal}
                >
                  <RiFileUploadFill 
                      className="payroll-add-user-button-icon"
                      size={30}
                  />
                </div>
                <div 
                  className="payroll-add-user-button"
                  onClick={handleToggleSearch}
                >
                  <MdArrowDropDownCircle 
                      className={`payroll-add-user-button-icon ${showFilters ? 'payroll-add-user-button-icon-rotate' : ''}`}
                      size={30}
                  />
                </div>
              </div>
            </div>

            {/* Hidden filters */}
            <div className={`payroll-hidden-form-row ${showFilters ? 'payroll-hidden-form-row-show' : ''}`}>
              <div className="payroll-hidden-form-row-box">
                <div className="payroll-search-bar-field">
                  <input
                    // ref={searchInputRef}
                    type="text"
                    className="payroll-search-bar-field-input"
                    placeholder={t("payrolls_form.search_user")}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <FaSearch className="payroll-search-bar-icon" />
                </div>
              </div>
              <div className="payroll-hidden-form-row-box">
                <SortDropdown
                  sortName="invalid"
                  handleSort={handleSort}
                  direction={sortInvalid === 'up' ? 'up' : sortInvalid === 'down' ? 'down' : 'none'}
                  label={t('payrolls_form.sort_invalid')}
                  variant="pill"
                />
                <SortDropdown
                  sortName="amount"
                  handleSort={handleSort}
                  direction={sortAmount === 'up' ? 'up' : sortAmount === 'down' ? 'down' : 'none'}
                  label={t('payrolls_form.sort_amount')}
                  variant="pill"
                />
                <SortDropdown
                  sortName="alpha"
                  handleSort={handleSort}
                  direction={sortAlpha === 'up' ? 'up' : sortAlpha === 'down' ? 'down' : 'none'}
                  label={t('payrolls_form.sort_alpha')}
                  variant="pill"
                />
              </div>
            </div>

            {/* User list */}
            <div className="payroll-form-user-list">
              {payrollUserList?.length > 0 ? payrollUserList
              .filter(user => user.email.toLowerCase().includes(searchTerm.toLowerCase()))
              .sort((a: any, b: any) => sortInvalid === "up" ? a.isValid - b.isValid : sortInvalid === "down" ? b.isValid - a.isValid : 0)
              .sort((a: any, b: any) => sortAmount === "up" ? b.amount - a.amount : sortAmount === "down" ? a.amount - b.amount : 0)
              .sort((a: any, b: any) => sortAlpha === "up" ? a.email.localeCompare(b.email) : sortAlpha === "down" ? b.email.localeCompare(a.email) : 0)
              .map(user => (
                <div key={user?.index} className="payroll-user-list-item-container">
                  {/* Delete button */}
                  <div 
                    className={`payroll-remove-user-button ${user?.locked ? 'payroll-button-disabled' : ''}`}
                    onClick={user?.locked ? undefined : () => handleOpenDeleteUserModal(user.index)}
                  >
                    <IoMdCloseCircle 
                      className="payroll-remove-user-button-icon"
                      size={30}
                    />
                  </div>
                  {/* User box */}
                  <div
                    className={`payroll-user-list-item ${user?.isValid ? '' : 'payroll-user-list-item-invalid'}`}
                    onClick={() => handleOpenEditInvalidUserModal(user.email, user.index)}
                  >
                    <div className="payroll-user-list-email-box">
                      <div className="payroll-user-list-index">{user?.index + 1}</div>
                      <div className={user?.isValid ? '' : 'payroll-email-invalid'}>{user?.email}</div>
                    </div>
                    {user?.isValid ? (
                    <div className="payroll-user-list-amount-box">
                      <FaLock
                        className={`payroll-user-list-lock ${user?.locked ? 'payroll-user-locked' : ''}`} 
                        onClick={(e) => {
                          e.stopPropagation()
                          handleToggleUserLock(user.index)
                        }}
                      />
                      <input
                        className={`payroll-user-list-item-input ${user?.locked ? 'payroll-user-list-item-input-locked' : ''}`}
                        value={user?.amount}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleUserAmountChange(e, user.index)}
                      />
                      <div>{selectedCoin}</div>
                    </div>
                    ) : (
                      <FaEdit className="payroll-user-list-edit"/>
                    )}
                  </div>
                </div>
              )) : !isLoadingUserListUsers ? (
                <div className="payroll-list-no-users">
                  {t("payrolls_form.no_users")}
                </div>
              ) : null}
              <div>
                {isLoadingUserListUsers ? <Spinner /> : null}
              </div>
            </div>

            {/* Stats */}
            <div className="payroll-summary">
              {/* Left Column */}
              <div className="summary-left">
                <div className="summary-item">
                  <span className="summary-label">{t('payrolls_summary.num_users')}</span>
                  <span className="summary-value">{num_users}</span>
                </div>
                <div className="summary-item">
                  <span className="summary-label">{t('payrolls_summary.total_amount')}</span>
                  <span className="summary-value">{numberWithCommas(total_amount, 2)} {selectedCoin}</span>
                </div>
                <div className="summary-item-group">
                  <div className="summary-item">
                    <span className="summary-label">{t('payrolls_summary.min_amount')}</span>
                    <span className="summary-value">{numberWithCommas(min_amount, 2)} {selectedCoin}</span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">{t('payrolls_summary.max_amount')}</span>
                    <span className="summary-value">{numberWithCommas(max_amount, 2)} {selectedCoin}</span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">{t('payrolls_summary.average_amount')}</span>
                    <span className="summary-value">{numberWithCommas(average_amount, 2)} {selectedCoin}</span>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="summary-right">
                <div className="summary-item">
                  <span className="summary-label">{t('payrolls_summary.current_balance')}</span>
                  <span className="summary-value">{numberWithCommas(current_balance, 2)} {selectedCoin}</span>
                </div>
                <div className="summary-item">
                  <span className="summary-label">{t('payrolls_summary.balance_after_transaction')}</span>
                  <span className={`summary-value ${balance_after_transaction < 0 ? 'summary-value-red' : ''}`}>{numberWithCommas(balance_after_transaction, 2)} {selectedCoin}</span>
                </div>
              </div>
            </div>

            {/* Main buttons */}
            <div className="payroll-form-row">
                <div 
                  className={`payroll-form-button-dark ${payrollUserList.length === 0 ? 'payroll-button-disabled' : ''}`} 
                  onClick={payrollUserList.length === 0 ? undefined : handleOpenClearUserListModal}
                >
                  <AiOutlineClear/>
                  <div>{t('payrolls_clean_list')}</div>
                </div>
                <div 
                  className={`payroll-form-button ${saveListEnabled ? '' : 'payroll-button-disabled'}`} 
                  onClick={saveListEnabled ? handleOpenCreateUserListModal : undefined}
                >
                  <IoMdPeople/>
                  <div>{t('payrolls_save_list')}</div>
                </div>
                <div 
                  className={`payroll-form-button ${sendTransferEnabled ? '' : 'payroll-button-disabled'}`}
                  onClick={sendTransferEnabled ? handleOpenSendMassTransferModal : undefined}
                >
                  <IoIosSend/> 
                  <div>{t('payrolls_send')}</div>
                </div>
            </div>            
          </div>
        </div>
      </div>

      {/* Right side */}
      <div className="payrolls-main-sidebox">
        {/* User Lists */}
        <h2 className="payrolls-title">{t('payrolls_user_lists')}</h2>
        <div className="payrolls-box-top">
          <div className="content-payrolls-cont">
            <div className="user-lists">
              {userListsData?.results?.length === 0 && <div className="overlay-text">{t('nolists')}</div>}
              {userList.map((list) => (
                <div
                  key={list.id}
                  className={`list-item with-actions ${isLoadingUserLists ? 'skeleton-card' : ''} ${list?.display ? '' : 'skeleton-card hidden'}`}
                  onClick={() => {if (list.display && !isLoadingUserLists) handleOpenUserListModal(list)}}
                >
                  <div className="list-content">
                    <div className="list-header">
                      <Skeleton className="list-name" minHeight="18px" minWidth="50%" animated={isLoadingUserLists}>
                        {!isLoadingUserLists && list?.name && list.name}
                      </Skeleton>
                    </div>
                    <div className="list-footer">
                      <Skeleton className="list-users" minHeight="18px" minWidth="40%" animated={isLoadingUserLists}>
                        {!isLoadingUserLists && <>
                          <IoMdPeople className="list-users-icon" />
                          {t('recipients_count', { count: list?.user_count || 0 })}
                        </>}
                      </Skeleton>
                    </div>
                  </div>
                  <Skeleton className="list-actions" minWidth="70px" minHeight="18px"  animated={isLoadingUserLists}>
                    {!isLoadingUserLists && <>
                      <div 
                        className="list-action-icon"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleOpenEditUserListModal(list.id)
                        }}
                      >
                          <FaEdit/>
                      </div>
                      <div 
                        className="list-action-icon"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleOpenDeleteUserListModal(list.id)
                        }}
                      >
                          <FaRegTrashAlt/>
                      </div>
                    </>}
                  </Skeleton>
                </div>
              ))}
            </div>
            <Paginator
              page={userListPage}
              setPage={setUserListPage}
              totalPagesNum={userListTotalPages}
            />
          </div>
        </div>
        {/* Mass Transfers History */}
        <h2 className="payrolls-title">{t('recent_mass_transfers')}</h2>
        <div className="payrolls-box-bottom">
          <div className="content-payrolls-cont">
            <div className="transfer-filters-sorters">
              <CoinFilterSelector 
                selectedCoin={transferSelectedCoin} 
                handleChangeCoin={setTransferSelectedCoin}
                allLabel={false}
              />
              <div 
                className="transfer-open-modal-button"
                onClick={handleOpenTransferHistoryModal}
              >
                <FaList 
                  className="transfer-open-modal-button-icon"
                  size={30}
                />
              </div>
            </div>
            <div className="transfer-list">
              {massTransfersData?.results?.length === 0 && <div className="overlay-text">{t('nomasstransfers')}</div>}
              {massTransfers.map((transfer) => (
                <div
                  key={transfer.id}
                  className={`transfer-item with-actions ${isLoadingMassTransfers ? 'skeleton-card' : ''} ${transfer?.display ? '' : 'skeleton-card hidden'}`}
                  onClick={() => {if (transfer.display && !isLoadingMassTransfers) setModalContent(<MassTransferDetailsModal item={transfer} />)}}
                >
                  {/* Name and date */}
                  <div className="transfer-content">
                    <div className='transfer-header'>
                      <Skeleton className='transfer-name' minHeight="18px" minWidth="50%" animated={isLoadingMassTransfers}>
                        {!isLoadingMassTransfers && (transfer?.display_name || `${t('payrolls_transfer_name')} #${transfer?.id}`)}
                      </Skeleton>
                    </div>
                    <div className='transfer-footer'>
                      <Skeleton className='transfer-users' minHeight="18px" minWidth="40%" animated={isLoadingMassTransfers}>
                        {!isLoadingMassTransfers && formatedDateTime(transfer?.created, t, false, true)}
                      </Skeleton>
                    </div>
                  </div>

                  {/* Amount and status */}
                  <div className="movement-right-wrapper">
                    {/* Amount */}
                    <Skeleton className="movement-amount" minHeight={'18px'} minWidth={'40%'} animated={isLoadingMassTransfers}>
                      {!isLoadingMassTransfers && <>
                        {transfer?.currency && <img className="movement-coin-logo" src={coinlogos[transfer?.currency as keyof typeof coinlogos]} alt={transfer?.currency} />}
                        <span className="amount-number">
                          -{transfer?.amount && numberWithCommas(`${transfer?.amount}`, coinsData?.coins[transfer?.currency]?.decimals || 2)}
                        </span>
                      </>}
                    </Skeleton>
                    {/* Status */}
                    <Skeleton className={`movement-status status-${transfer?.status}`} minHeight={'18px'} width={transfer?.status ? 16 : '20%'} animated={isLoadingMassTransfers}>
                      {!isLoadingMassTransfers && transfer?.status && getStatusIcon(transfer.status)}
                    </Skeleton>
                  </div>
                </div>
              ))}
            </div>
            <Paginator
              page={massTransferPage}
              setPage={setMassTransferPage}
              totalPagesNum={massTransferTotalPages}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payrolls