import React, { forwardRef, useEffect, useRef, useState } from "react"
import "./Skeleton.css"

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
    width?: string | number
    height?: string | number
    minWidth?: string | number
    minHeight?: string | number
    maxWidth?: string | number
    maxHeight?: string | number
    animated?: boolean // If true, applies loading effect
}

const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
    ({ width, height, minWidth, minHeight, maxWidth, maxHeight, animated = true, className = "", children, ...props }, ref) => {
        const skeletonRef = useRef<HTMLDivElement | null>(null)
        const [computedWidth, setComputedWidth] = useState<number | null>(null)

        useEffect(() => {
            if (skeletonRef.current) {
                const resizeObserver = new ResizeObserver((entries) => {
                    for (let entry of entries) {
                        setComputedWidth(entry.contentRect.width)
                    }
                })

                resizeObserver.observe(skeletonRef.current)

                return () => {
                    resizeObserver.disconnect()
                }
            }
        }, [])

        // Slower for small widths, faster for larger ones
        const shimmerSpeed = computedWidth
            ? Math.min(20, Math.max(2, 300 / computedWidth)) 
            : 4
                    
        return (
            <div
                ref={(el) => {
                    skeletonRef.current = el
                    if (typeof ref === "function") ref(el)
                    else if (ref) ref.current = el
                }}
                className={`skeleton-container ${animated ? "skeleton" : ""} ${className}`}
                style={{
                    width: typeof width === "number" ? `${width}px` : width,
                    height: typeof height === "number" ? `${height}px` : height,
                    minWidth: typeof minWidth === "number" ? `${minWidth}px` : minWidth,
                    minHeight: typeof minHeight === "number" ? `${minHeight}px` : minHeight,
                    maxWidth: typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth,
                    maxHeight: typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight,
                    "--shimmer-speed": `${shimmerSpeed}s`, // Dynamic animation speed
                } as React.CSSProperties}
                {...props}
            >
                {children}
            </div>
        )
    }
)

Skeleton.displayName = "Skeleton"
export default Skeleton
