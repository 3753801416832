import { useProfile } from "../../hooks/useProfile"
import { Link, useLocation } from "react-router-dom"
import brands from "../../utils/brands"
import LanguageSelector from "../LanguageSelector/LanguageSelector"
import useAuthStore from "../../stores/AuthStore"
import usePageLayoutStore from "../../stores/PageLayoutStore"
import { HiOutlineMenuAlt1 } from "react-icons/hi"
import routes from "../../routes/routes"
import localConfig from "../../local_config"
import "./Topbar.css"

const Topbar = () => {

    const { data: profileData } = useProfile()

    const { isLogged } = useAuthStore((state) => ({
        isLogged: state.isLogged,
    }))

    const { toggleSidebar } = usePageLayoutStore((state) => {
        return {
            toggleSidebar: state.toggleSidebar
        }
    })

    const location = useLocation()

    const validRoutes = Object.values(routes).filter((route) => (route !== "*" && route !== "/maintenance"))
    const routeStrings = validRoutes.map((route) => {
        return route.split('/')[1]
    })

    return (
        <div className="topbar-cont">
            <div className="logo-sidebar-toggler-cont">
                {isLogged && routeStrings.includes(location.pathname.split('/')[1]) && <HiOutlineMenuAlt1 id="sidebar-button" onClick={(e) => {e.stopPropagation(); toggleSidebar()}} className="sidebar-toggle-icon" />}
                <Link className="nav-logo-link" to="/"><img className="nav-nemo-logo" src={brands[localConfig.brand as keyof typeof brands]} /></Link>
            </div>
            <div className="email-logout-lang-cont">
                {isLogged &&
                    <div className = "navbar-interactions">
                        <div className="profile-navbar">
                            <div className="username-navbar">
                                {profileData?.user.username}                            
                            </div>
                        </div>
                    </div>
                }
                <LanguageSelector />
            </div>
        </div>
    )
}

export default Topbar