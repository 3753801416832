import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'

import { useProfile } from '../../hooks/useProfile'
import { useCheckSingleUser } from '../../hooks/useCheckUsers'

import './CheckUsernameInput.css'

interface CheckUsernameInputProps {
    /** 
     * Initial email value.
     */
    email?: string;
    /** 
     * An array of existing emails/usernames. Used to prevent duplicate entries.
     */
    allEmails?: string[];
    /** 
     * Custom placeholder.
     */
    placeholder?: string | null;
    /** 
     * Custom error message displayed when the entered username is invalid.
     */
    errorMessage?: string | null;
    /** 
     * If true, the input will be cleared when the user presses Enter.
     */
    cleanOnEnter?: boolean;
    /** 
     * Callback invoked when the email/username changes.
     */
    setEmail?: (email: string) => void;
    /** 
     * Callback invoked when the validation status changes.
     */
    setValid?: (isValid: boolean) => void;
    /** 
     * Callback invoked to set the user data when the username is valid.
     */
    setData?: (data: any) => void;
    /** 
     * Callback invoked when the user presses Enter and the username is valid.
     */
    onEnter?: (email: string) => void;
  }
  
const CheckUsernameInput = ({ 
        email = "", 
        allEmails = [], 
        placeholder = null, 
        errorMessage = null, 
        cleanOnEnter = true,
        setEmail = () => {},
        setValid = () => {}, 
        setData = () => {},
        onEnter = () => {} 
}: CheckUsernameInputProps) => {

    const { t } = useTranslation('common')

    const { data: profileData } = useProfile()
    const { mutateAsync: checkUsername } = useCheckSingleUser()

    const [newEmail, setNewEmail] = useState(email)
    const [isValid, setIsValid] = useState(false)
    const [error, setError] = useState("")

    const handleChangeEmail = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setNewEmail(e.target.value)        
    }

    const handleEnter = () => {
        if (isValid) {
            onEnter(newEmail)
            if (cleanOnEnter) setNewEmail("")
        }
    }

    // Check if new email is a valid user
    useEffect(() => {
        setError("")
        setIsValid(false)
        
        if (newEmail === profileData?.user?.username) {
            setError(t("check_username_input.cannot_send_to_self"))
            return
        }

        if (newEmail !== email && allEmails.includes(newEmail)) {
            setError(t("check_username_input.repeated_username"))
            return
        }

        if (newEmail && newEmail !== email) {
            const debounceTimer = setTimeout(() => {
                const verifyUsername = async () => {
                    try {
                        const result = await checkUsername(newEmail.toLocaleLowerCase())
                        if (result.status === 200 && result?.data?.is_valid) {
                            toast.success(t("check_username_input.valid_username"))
                            setError("")
                            setIsValid(true)
                            setData(result?.data?.user_data)
                        } else {
                            setError(errorMessage ? errorMessage : t("check_username_input.invalid_username"))
                        }
                    } catch (error) {
                        setError(errorMessage ? errorMessage : t("check_username_input.invalid_username"))
                        console.error("Error checking username:", error)
                    }
                }
                verifyUsername()
            }, 1000)

            return () => clearTimeout(debounceTimer)
        }
    }, [newEmail])

    // Watch for external updates of the email
    useEffect(() => {
        setNewEmail(email)
    }, [email])

    // Send callbacks when updates
    useEffect(() => {
        if (isValid || newEmail === "") setEmail(newEmail)
        setValid(isValid)
    }, [newEmail, isValid])

    useEffect(() => {
        if (error) toast.error(error)
    }, [error])
    
    return (
        <div className="check-username-input-cont">
            <input
                className={`check-username-input ${isValid ? "check-username-success" : error !== "" ? "check-username-error" : ""}`}
                placeholder={placeholder ? placeholder : t("check_username_input.placeholder")}
                value={newEmail}
                onChange={handleChangeEmail}
                onKeyDown={(e) => {
                    if (e.key === "Enter") handleEnter()
                }}
            /> 
        </div>
    )
}

export default CheckUsernameInput