import { Routes, Route } from "react-router-dom";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import Topbar from "./components/Topbar/Topbar";
import Main from "./components/Main/Main";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import ResetPasswordRequest from "./pages/ResetPasswordRequest/ResetPasswordRequest";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Deposit from "./pages/Deposit/Deposit";
import Withdraw from "./pages/Withdraw/Withdraw";
import Cards from "./pages/Cards/Cards";
import Wallet from "./pages/Wallet/Wallet";
import ProfilePage from "./pages/Profile/Profile";
import Support from "./pages/Support/Support";
import Converter from "./pages/Converter/Converter";
import PeerToPeer from "./pages/PeerToPeer/PeerToPeer";
import Maintenance from "./pages/Maintenance/Maintenance";
import NotFound from "./pages/NotFound/NotFound";
import Kyc from "./pages/Kyc/Kyc";
import DynamicTitle from "./components/DynamicTitle/DynamicTitle";
import routes from "./routes/routes";
import Payrolls from "./pages/Payrolls/Payrolls";
import Event from "./pages/Event/Event";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 5000,
          success: { className: "toast-success" },
          error: { className: "toast-error" },
          style: {
            zIndex: 20
          }
        }}
        children={(toasts) => (
          <ToastBar toast={toasts}>
            {({ icon, message }) => (
              <div
              onClick={() => toast.dismiss()}
              style={{ cursor: "pointer", display: "flex"}}
              >
                {icon}
                {message}
              </div>
            )}
          </ToastBar>
        )}
      />
      <DynamicTitle />
      <Topbar />
      <Routes>
        <Route path={routes.main} element={<Main />}>
          <Route path={routes.wallet} element={<Wallet />} />
          <Route path={routes.converter} element={<Converter />} />
          <Route path={routes.peertopeer} element={<PeerToPeer />} />
          <Route path={routes.deposit} element={<Deposit />} />
          <Route path={routes.withdraw} element={<Withdraw />} />
          <Route path={routes.cards} element={<Cards />} />
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route path={routes.support} element={<Support />} />
          <Route path={routes.payrolls} element={<Payrolls />} />
        </Route>
        <Route path={routes.kyc} element={<Kyc />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.register} element={<Register />} />
        <Route path={routes.confirmRegister} element={<ConfirmEmail />} />
        <Route path={routes.resetPasswordRequest} element={<ResetPasswordRequest />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route path={routes.maintenance} element={<Maintenance />} />
        <Route path={routes.notFound} element={<NotFound />} />
        <Route path={routes.event} element={<Event />} />
      </Routes>
    </div>
  );
}

export default App;
