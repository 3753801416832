import { Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useProfile } from "../../hooks/useProfile";
import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import useAuthStore from "../../stores/AuthStore";
import LaunchWebSdk from "../../components/LaunchWebSdk/LaunchWebSdk";
import CardStepper from "../../components/CardStepper/CardStepper";
import { useKycAccessToken } from "../../hooks/useKycAccessToken";
import "./Kyc.css";
import Spinner from "../../components/Spinner/Spinner";

const Kyc = () => {
  const { t } = useTranslation('common');
  const { data, refetch: refetchProfile, isLoading: isProfileLoading } = useProfile();
  const { isLogged } = useAuthStore((state) => ({ isLogged: state.isLogged }));
  
  const isKyced = data?.kyc_status === "green";
  const { 
    data: accessToken, 
    refetch: refetchToken, 
    isFetching: isFetchingToken, 
    error: tokenError, 
    isSuccess 
  } = useKycAccessToken();
  
  const [statusCompleted, setStatusCompleted] = useState(false);


  const getNewAccessToken = async (): Promise<string> => {
    const result = await refetchToken();
    if (result.data) {
      return result.data;
    }
    throw new Error('Failed to fetch access token');
  };

  useEffect(() => {
    let mounted = true;

    const initializeKyc = async () => {
      if (isLogged && !isProfileLoading && data && !isKyced && !accessToken && mounted) {
        await refetchToken();
      }
    };

    initializeKyc();

    return () => {
      mounted = false;
    };
  }, [isLogged, isProfileLoading, data, isKyced, accessToken, refetchToken]);


  useEffect(() => {
    if (statusCompleted) {
      refetchProfile();
    }
  }, [statusCompleted, refetchProfile]);


  useEffect(() => {
    let intervalId: any = null; 
    if (tokenError) {
      intervalId = setInterval(() => {
        window.location.reload();
      }, 2000); 
    }

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, [tokenError]);

  const errorMessage = tokenError 
    ? (tokenError.response?.data?.message || 'error: no token')
    : null;

  return (
    <>
      {isLogged ? (
        isProfileLoading ? (
          <Spinner />
        ) : data ? (
          isKyced ? (
            <Navigate to="/" />
          ) : (
            <div className="not-horizontal-divide">
              <div className="kyc-sidebar-cont">
                <Sidebar />
              </div>
              {isSuccess && accessToken ? (
                statusCompleted && !isKyced ? (
                  <CardStepper />
                ) : (
                  <LaunchWebSdk
                    accessToken={accessToken}
                    getNewAccessToken={getNewAccessToken}
                    onStatusCompleted={setStatusCompleted}
                  />
                )
              ) : isFetchingToken ? (
                <Spinner /> 
              ) : errorMessage ? (
                <div className="kyc-error">
                  <Spinner/>
                  {/* <button 
                    className="kyc-error-button" 
                    onClick={() => refetchToken()}
                  >
                    {t('conection_slow')}

                  </button> */}

                </div>
              ) : (
                <div>{t('kyc.initializing')}</div>
              )}
            </div>
          )
        ) : (
          <div>{t('kyc.noProfileData')}</div>
        )
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default Kyc;