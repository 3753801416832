import axiosInstance from "../api/axiosInstance"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

const fetchSupportTickets = async () => {
    return axiosInstance.get("/support_tickets")
}

const postSupportTickets = async (formData: FormData) => {
    return axiosInstance.post("/support_tickets/", formData, {headers: { 'Content-Type': 'multipart/form-data' }});
}

const fetchSupportTicketConversation = async (id: string) => {
    return axiosInstance.get(`/support_ticket_conversation/${id}`)
}

const postSupportTicketConversation = async (id: string, formData: FormData) => {
    return axiosInstance.post(`/support_ticket_conversation/${id}/`, formData, {headers: { 'Content-Type': 'multipart/form-data' }});
}

const fetchFAQ = (search_type: string, search_value: string, language: string) => {
    return axiosInstance.get("/support_faq", {
        params: {
            search_type: search_type,
            search_value: search_value,
            language: language
        }
    })
}

export const useSupportTickets = () => {
    return useQuery({
        queryKey: ['support-tickets'],
        queryFn: () => fetchSupportTickets(),
        select: (data) => {
            return data.data
        }
    })
}

export const usePostSupportTickets = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (formData: FormData) => postSupportTickets(formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['support-tickets'] })
        }
    });
}

export const useSupportTicketConversation = (id: string) => {
    return useQuery({
        queryKey: ["support-ticket-conversation", id],
        queryFn: () => fetchSupportTicketConversation(id),
        select: (data) => data.data
    });
}

export const usePostSupportTicketConversation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({id, formData}: {id: string, formData: FormData}) => postSupportTicketConversation(id, formData),
        onSuccess: (_, {id}) => {
            queryClient.invalidateQueries({ queryKey: ['support-ticket-conversation', id] })
        }
    });
}

export const useFAQ = (search_type: string, search_value: string, language: string) => {
    return useQuery({
        queryKey: ['faq ', search_type, search_value, language],
        queryFn: () => fetchFAQ(search_type, search_value, language),
        select: (data) => {
            return data.data
        },
        enabled: !!search_value 
    });
}
