import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSupportTicketConversation, usePostSupportTicketConversation } from "../../hooks/useTickets";
import { prettyDatetime } from "../../utils/functions";
import { IoReturnUpBack } from "react-icons/io5";
import toast from "react-hot-toast";
import InputTextAdvanced from "../InputText/InputTextAdvanced";
import Spinner from "../Spinner/Spinner";
import UploadFiles from "../UploadFiles/UploadFiles";
import "./SupportTicketConversation.css";

interface SupportMessage {
    body: string;
    created_at: string;
    user_response: boolean;
}

interface Ticket {
    id: string,
    subject: string,
    description: string,
    status: string,
    created_at: string,
    updated_at: string,
}

interface TicketConversation{
  ticket: Ticket,
  closeConversation: () => void;

}


const SupportTicketConversation = ({ ticket, closeConversation }: TicketConversation) => {
    const { t } = useTranslation('common')
    const { data: supportTicketConversation } = useSupportTicketConversation(ticket.id);
    const { mutateAsync: submitSupportTicketConversationMessage, isPending} = usePostSupportTicketConversation()
    const [ message, setMessage ] = useState("")
    const [ uploadedFiles, setUploadedFiles ] = useState<File[]>([]);
    const [ key, setKey ] = useState(0)

    const chatContainerRef = useRef<HTMLDivElement>(null);

    const renderMessage = (message : SupportMessage) => {
        const messageStructure = (
            <div className={message.user_response ? "user-message-section" : "support-message-section"}>
                <div className={message.user_response ? "user-message" : "support-message"}>
                    <div className="message-body">{message.body}</div>
                    <div className="message-datetime">{prettyDatetime(message.created_at)}</div>
                </div>
            </div>
        )
        return messageStructure
    }

    const splitMessage = (message : SupportMessage) => {
        let messages: JSX.Element[] = []
        const bodies = message.body.split("\r\n");
        bodies.forEach((body) => {
            const splitedMessage : SupportMessage = {body: body, created_at: message.created_at, user_response: message.user_response}
            messages.push(
                renderMessage(splitedMessage)
            );
        });
      return messages;
    }

    const handleFilesChange = (files: File[]) => {
      setUploadedFiles(files);
    };

    const handleInputChange = (field: { [key: string]: any }, value: string) => {
      setMessage(value)
      return field.name;
    };

    const handleSubmitTicket = async () => {
      // TODO toast error
      if(!message) return
      
      try { 
        let formDataToSend = new FormData();
        formDataToSend.append("message", message);
        uploadedFiles.forEach((file) => {
            formDataToSend.append("files", file); 
        });
        await submitSupportTicketConversationMessage({id : ticket.id, formData: formDataToSend});
        toast.success("support_ticket_creation_success")
        // setMessage("")
        // setUploadedFiles([])
        setKey(prevKey => prevKey + 1)
      } catch (error: any){
          console.error("Error al hacer la solicitud a la API:", error);
          toast.error("support_ticket_creation_error")
      }
      

    }


    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, [supportTicketConversation, isPending]);

    return (
        <div className="support-ticket-conversation">
          <div className="support-ticket-conversation-header">
            <div className="support-ticket-conversation-close" onClick={() => closeConversation()}>
              <IoReturnUpBack color="#8ffdfc" size={32}/>
            </div>
            <div className="support-ticket-conversation-title">
              {ticket.subject}
            </div>
          </div>
          {supportTicketConversation?.length > 0 ? (
            <div className="support-ticket-conversation-content">
              <div ref={chatContainerRef} className="support-ticket-conversation-chat">
                {supportTicketConversation.map((message: SupportMessage) => (splitMessage(message)))}
                { isPending && 
                    <Spinner sizeMultiplier={0.5} align="end"/>
                }
              </div>
              <InputTextAdvanced key={`message-${key}`} field={{name: "message", description: "message", height: "100px"}} onInputChange={handleInputChange}/>
              <UploadFiles key={`files-${key}`} onFilesChange={handleFilesChange}/>
              <div>
                <button disabled={isPending} className="support-ticket-conversation-button" type="button" onClick={handleSubmitTicket}>
                    {t("sent")}
                </button>
            </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      );    
};

export default SupportTicketConversation;
