import { create } from "zustand"

interface UserItem {
    index: number
    email: string
    amount: string
    locked: boolean
    isValid: boolean
}

interface UserListStore {
    userList: UserItem[]
    updateUserList: (
    valueOrUpdater: UserItem[] | ((prevList: UserItem[]) => UserItem[])
    ) => void
    getUserList: () => UserItem[]
}

const useUserListStore = create<UserListStore>((set, get) => ({
    userList: [],

    updateUserList: (valueOrUpdater) => {
        if (typeof valueOrUpdater === "function") {
            // For allowing (prevState) => prevState.map(...)
            set((state) => ({
                userList: (valueOrUpdater as (prev: UserItem[]) => UserItem[])(state.userList)
            }))
        } else {
            // Otherwise, just set the value
            set({ userList: valueOrUpdater })
        }
    },

    getUserList: () => get().userList,
}))

export default useUserListStore
