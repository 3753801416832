import { useState, useEffect, ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { LiaFileAltSolid, LiaFileUploadSolid } from "react-icons/lia"
import toast from "react-hot-toast"
import Papa from "papaparse"
import ExcelJS from "exceljs"

import { useCheckManyUsers } from "../../hooks/useCheckUsers"
import Spinner from "../../components/Spinner/Spinner"
import useModalStore from "../../stores/ModalStore"
import localConfig from "../../local_config"

import "./UserListFileUploadModal.css"

interface UserListFileUploadModalProps {
    /**
     * Receives the list of users extracted from the file.
     */
    onUpload: (users: { is_valid: boolean, email: string, amount: string }[]) => void
}

const UserListFileUploadModal = ({ onUpload }: UserListFileUploadModalProps) => {
    const { t } = useTranslation()

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }))

    const { mutateAsync: checkUserList } = useCheckManyUsers()

    const [userList, setUserList] = useState<{ email: string; amount: string }[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [isDragging, setIsDragging] = useState(false)

    const handleDownloadTemplate = () => {
        const link = document.createElement("a")
        // NOTE this must be in the backend staticfiles dir
        link.href = `https://${localConfig.domain}/static/NEMO-Plantilla-Usuarios.xlsx`
        link.download = "NEMO-Plantilla-Usuarios.xlsx"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const findUsersRow = (rows: (string | string)[][]): { email: string; amount: string }[] => {
        const EMAIL_HEADER = "users"
        const AMOUNT_HEADER = "montos"
      
        if (!rows || rows.length === 0) return []
      
        // Convert everything to string
        const stringRows = rows.map((r) => r.map((c) => String(c)))
        const headerRow = stringRows[0].map((h) => h.trim().toLowerCase())
      
        // Find the indexes of the "users" and "amounts" columns
        const emailIndex = headerRow.indexOf(EMAIL_HEADER)
        const amountIndex = headerRow.indexOf(AMOUNT_HEADER)
      
        // Fallback to the first column if "users" wasn't found
        const realEmailIndex = emailIndex === -1 ? 0 : emailIndex
      
        const results: { email: string; amount: string }[] = []
      
        // Start from the second row (i=1) to skip headers
        for (let i = 1; i < stringRows.length; i++) {
          const row = stringRows[i]
          const email = row[realEmailIndex] ? row[realEmailIndex].trim() : ""
          if (!email) continue  // skip empty lines
      
          let amount = "0"
          // If there's an "amounts" column, parse it; otherwise default to 0
          if (amountIndex !== -1) {
            const rawAmount = row[amountIndex]?.trim() || ""
            const parsed = rawAmount
            amount = parsed ? parsed : "0"
          }
      
          results.push({ email, amount })
        }
        return results
    }
      

    const handleFile = async (file: File) => {
        setErrorMessage(null)
        if (!file) return

        const fileName = file.name.toLowerCase()

        if (
            !(
            fileName.endsWith(".csv") ||
            fileName.endsWith(".xlsx") ||
            fileName.endsWith(".txt")
            )
        ) {
            setErrorMessage(t('payrolls_file_upload.errors.invalid_file_type'))
            return
        }

        try {
            if (fileName.endsWith(".txt")) {
                // Parse text file
                const text = await file.text()
                const lines = text
                    .split(/\r?\n/)
                    .map((l) => l.trim())
                    .filter((l) => l !== "")

                // Expect either "email" or "email,amount"
                const data = lines.map((line) => {
                    const [emailPart, amountPart] = line.split(",")
                    const email = emailPart?.trim() || ""
                    let amount = "0"

                    if (amountPart) {
                    const parsed = amountPart.trim()
                    amount = parsed ? parsed : "0"
                    }

                    return { email, amount }
                })

                setUserList(data)

            } else if (fileName.endsWith(".csv")) {
                // Parse csv file
                Papa.parse(file, {
                    skipEmptyLines: true,
                    complete: (results: any) => {
                        // results.data should be an array of arrays if "header: false"
                        // By default, Papa Parse might guess. We force it:
                        let rows = results.data as (string)[][]

                        // Ensure rows is truly 2D array
                        if (!Array.isArray(rows[0])) {
                            return
                        }
                        const users = findUsersRow(rows)
                        setUserList(users)
                    },
                    error: () => {
                        setErrorMessage(t('payrolls_file_upload.errors.invalid_file'))
                    },
                })

            } else if (fileName.endsWith(".xlsx")) {
                // Parse excel
                const arrayBuffer = await file.arrayBuffer()
                const workbook = new ExcelJS.Workbook()
                await workbook.xlsx.load(arrayBuffer)

                const worksheet = workbook.worksheets[0]
                if (!worksheet) {
                    setErrorMessage(t('payrolls_file_upload.errors.invalid_file'))
                    return
                }

                // Convert entire worksheet to a 2D array
                const rows: (string)[][] = []
                worksheet.eachRow((row, _) => {
                    const rowValues: (string)[] = []
                    row.eachCell((cell) => rowValues.push(cell.toString()))
                    rows.push(rowValues)
                })
                const users = findUsersRow(rows)
                setUserList(users)

            }
        } catch (error) {
            setErrorMessage(t('payrolls_file_upload.errors.parse_error'))
        }
    }

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            handleFile(e.target.files[0])
        }
    }

    const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault()
        setErrorMessage(null)
    
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFile(e.dataTransfer.files[0])
        }
    }

    useEffect(() => {
        const handleDragEnter = (e: DragEvent) => {
            e.preventDefault()
            setIsDragging(true)
        }

        const handleDragLeave = (e: DragEvent) => {
            if (e.relatedTarget === null) {
                setIsDragging(false)
            }
        }

        const handleDropAnywhere = (e: DragEvent) => {
            e.preventDefault()
            setIsDragging(false)
        }

        window.addEventListener("dragenter", handleDragEnter)
        window.addEventListener("dragleave", handleDragLeave)
        window.addEventListener("drop", handleDropAnywhere)

        return () => {
            window.removeEventListener("dragenter", handleDragEnter)
            window.removeEventListener("dragleave", handleDragLeave)
            window.removeEventListener("drop", handleDropAnywhere)
        }
    }, [])

    // Validate and pass list of users
    useEffect(() => {
        if (userList.length > 0) {
            setIsLoading(true)
            const handle = async () => {
                try {
                    // Extract just the emails to send for validation
                    const emails = userList.map((u) => u.email)
                    const result = await checkUserList({ emails })

                    // Merge the validation results with the original amounts
                    const final = result.results.map((r: { is_valid: boolean; email: string }) => {
                    const found = userList.find((u) => u.email === r.email)
                    return {
                        is_valid: r.is_valid,
                        email: r.email,
                        amount: found?.amount ?? "0",
                    }
                    })
                    onUpload(final)
                    setIsLoading(false)
                    closeModal()
                } catch (error) {
                    setIsLoading(false)
                    setErrorMessage(t('payrolls_file_upload.errors.check_error'))
                }
            }
            handle()
        }
    }, [userList])

    useEffect(() => {
        if (errorMessage) toast.error(errorMessage)
      }, [errorMessage])

    return (<>
        {isDragging && (
            <div className="drag-overlay">
                <p>{t("payrolls_file_upload.drop_here")}</p>
            </div>
        )}
        <div className="user-list-file-upload-cont">
            <div className="user-list-file-upload-title">{t("payrolls_file_upload.title")}</div>

            {isLoading ? (
            <div className="user-list-upload-spinner">
                <Spinner/>
            </div>
            ) : (
            <>
                <div className="user-list-file-upload-cards">
                    {/* Card to download the plantilla */}
                    <div 
                        className="user-list-file-upload-card" 
                        onClick={handleDownloadTemplate}
                    >
                        <div className="user-list-file-upload-card-content">
                            <LiaFileAltSolid size={100}/>
                            <span>{t("payrolls_file_upload.download_template")}</span>
                            <span className="user-list-card-subtitle">{t("payrolls_file_upload.download_template_description")}</span>
                        </div>
                    </div>

                    {/* Card to upload the file */}
                    <label
                        className="user-list-file-upload-card"
                        htmlFor="user-list-file-upload-input"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop} 
                    >
                        <div className="user-list-file-upload-card-content">
                            <LiaFileUploadSolid size={100}/>
                            <span>{t("payrolls_file_upload.upload_file")}</span>
                            <span className="user-list-card-subtitle">{t("payrolls_file_upload.upload_file_description")}</span>
                        </div>
                        <input
                            id="user-list-file-upload-input"
                            type="file"
                            className="user-list-file-hidden-input"
                            accept=".csv,.xlsx,.txt"
                            onChange={handleFileChange}
                        />
                    </label>
                </div>

                <div className="user-list-file-upload-actions">
                    <div className="user-list-file-upload-button" onClick={closeModal}>
                        {t("payrolls_file_upload.close")}
                    </div>
                </div>
            </>
            )}

            
        </div>
    </>)
}

export default UserListFileUploadModal
