import { useState } from 'react'
import { useTranslation } from "react-i18next"

import CheckUsernameInput from '../../components/CheckUsernameInput/CheckUsernameInput'
import useModalStore from '../../stores/ModalStore'

import './EditInvalidUser.css'

interface EditInvalidUserParams {
    user: string,
    index: number,
    users: string[],
    onSubmit: (user: string, index: number) => void,
    onDelete: (index: number) => void,
}

const EditInvalidUser = ({ user, index, users, onSubmit, onDelete }: EditInvalidUserParams) => {
    const { t } = useTranslation('common')

    const { closeModal } = useModalStore((state) => ({
        closeModal: state.closeModal,
    }))

    const [userEmail, setUserEmail] = useState(user)
    const [isEmailValid, setIsEmailValid] = useState(false)

    const handleSubmit = () => {
        onSubmit(userEmail, index)
        closeModal()
    }

    const handleDelete = () => {
        onDelete(index)
        closeModal()
    }

    return (
        <div className="edit-invalid-user-cont">
            <div className="edit-invalid-user-title">{t('payrolls_edit_user_modal.title')}</div>
            <div className="edit-invalid-user-email-input-box">
                <div className="edit-invalid-user-email-input-label">
                    {t('payrolls_edit_user_modal.email_label')}
                </div>
                <CheckUsernameInput
                    placeholder={t("payrolls_edit_user_modal.email_placeholder")}
                    errorMessage={t("payrolls_errors.invalid_username")}
                    email={userEmail}
                    allEmails={users}
                    setEmail={setUserEmail}
                    setValid={setIsEmailValid}
                    onEnter={handleSubmit}
                />
            </div>
            <div className="edit-invalid-user-buttons">
                <div
                    className={`edit-invalid-user-button ${isEmailValid ? '' : 'edit-invalid-user-button-disabled'}`}
                    onClick={isEmailValid ? handleSubmit : undefined}
                >
                    {t('payrolls_edit_user_modal.submit')}
                </div>
                <div
                    className="edit-invalid-user-button"
                    onClick={handleDelete}
                >
                    {t('payrolls_edit_user_modal.delete')}
                </div>
            </div>
        </div>
    )
}

export default EditInvalidUser