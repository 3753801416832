import axiosInstance from "../api/axiosInstance"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

export interface MassTransfer {
  id: number
  user_list_id: number
  user_list: any
  description?: string
  status: string
  created_at: string
  created_by: {
    id: number
    email: string
  }
  amount?: number
  currency?: string
}

interface PaginatedResponse<T> {
    count: number
    next: string | null
    previous: string | null
    results: T[]
}
  
interface PaginationParams {
    id?: number
    limit?: number
    offset?: number
    search?: string
    no_pagination?: boolean
    ordering?: string
    currency?: string
    status?: string

    // Exclusively for mass transfers
    start_date?: string
    end_date?: string
    min_amount?: string
    max_amount?: string
    recipient_email?: string
}

const postTransfer = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/transfer/", formData)
}

const postMassTransfer = async (formData: { [key: string]: any }) => {
    return axiosInstance.post("/mass-transfer-request/", formData)
}

const fetchMassTransfers = ({ 
    limit, 
    offset,
    ordering = '-created', 
    currency,
    status = 'completed',
    min_amount,
    max_amount,
    start_date,
    end_date,
    recipient_email
}: PaginationParams) => {
    return axiosInstance.get<PaginatedResponse<MassTransfer>>("/mass-transfer-request/", {
        params: { 
            limit, 
            offset,
            ordering,
            currency,   
            status,
            min_amount,
            max_amount,
            start_date,
            end_date,
            recipient_email
        }
    })
}


const fetchMassTransfersExcel = ({ 
    limit, 
    offset,
    ordering = '-created', 
    currency,
    status = 'completed',
    min_amount,
    max_amount,
    start_date,
    end_date,
    recipient_email
}: PaginationParams) => {
    return axiosInstance.get<any>("/mass-transfer-request/export/excel", {
        params: { 
            limit, 
            offset,
            ordering,
            currency,   
            status,
            min_amount,
            max_amount,
            start_date,
            end_date,
            recipient_email
        },
        responseType: 'blob'
    })
}

export const useTransfer = () => {
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postTransfer(formData),
    })
}

export const useMassTransfer = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (formData: { [key: string]: any }) => postMassTransfer(formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['mass-transfers'] })
        }
    })
}

export const useMassTransfers = (params: PaginationParams) => {
    return useQuery({
        queryKey: ['mass-transfers', params],
        queryFn: () =>
            fetchMassTransfers(params)
            .then((res) => {
                return {
                    ...res.data,
                    results: res?.data?.results?.map((item: any) => ({
                        ...item,
                        display: true
                    })) ?? []
                }
            })
            .catch((_) => {
                return { results: [], count: 0, next: '', previous: '' }
            }),
        refetchInterval: 5000,
    })
}


export const useExcelDownloader = () => {
    const [isDownloading, setIsDownloading] = useState(false)
    
    const downloadExcel = async (params: any) => {
        try {
            setIsDownloading(true)
            const response = await fetchMassTransfersExcel(params)

            const contentType = response.headers['content-type'] || 
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

            const blob = new Blob([response.data], { type: contentType })

            const contentDisposition = response.headers['content-disposition']
            let filename = 'Transferencias.xlsx'
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?([^"]*)"?/)
                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1]
                }
            }

            const url = window.URL.createObjectURL(blob)
            const anchor = document.createElement('a')
            anchor.href = url
            anchor.download = filename
            anchor.rel = 'noopener'

            document.body.appendChild(anchor)
            anchor.click()

            setTimeout(() => {
                document.body.removeChild(anchor)
                window.URL.revokeObjectURL(url)
            }, 100)

        } catch (error) {
            console.error('Error downloading Excel file:', error)
        } finally {
            setIsDownloading(false)
        }
    }
    return { downloadExcel, isDownloading }
}