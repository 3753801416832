import './Spinner.css';

const Spinner = ({ sizeMultiplier = 1, align = "center" }) => {
  const size = 40 * sizeMultiplier; 
  const margin = 20 * sizeMultiplier
  
  return (
    <div style={{
      display: "flex",
      alignItems: align,
      justifyContent: align,

    }}>
      <div
        className="spinner"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderWidth: `${4 * sizeMultiplier}px`,
          margin: `${margin}px`
        }}
      />
    </div>
  );
};

export default Spinner;
